import { useState, React, useEffect } from "react";
// import { ReactComponent as FezzantLogo } from "../../assets/icons/fezzant-beta-copy.svg";
import { ReactComponent as MailLogo } from "../../assets/icons/mail-icon.svg";
import { useForm } from "react-hook-form";
import { ReactComponent as EyeLogo } from "../../assets/icons/eye-icon.svg";
import { ReactComponent as EyeOffLogo } from "../../assets/icons/eye-off.svg";
import { ReactComponent as LockLogo } from "../../assets/icons/lock-icon.svg";
import { ReactComponent as Arrow } from "../../assets/icons/login-page-arrow.svg";
import { useAuthContext } from "../../contexts/AuthContext";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import axios from "axios";
import { api } from "../..";
import "./login.css";
import {
  storeFacilityWorkerDetails,
  storeUserDetails,
} from "../../redux/currentUser/currentUser.actions";
import { storeFacilityDetails } from "../../redux/currentFacility/currentFacilityActions";
import { storeAgencyDetails } from "../../redux/currentAgency/currentAgencyActions";

const Login = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [type, setType] = useState("password");

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  let userPanelUrl;

  let from;

  const navigator = (user_type_id) => {
    if (user_type_id === "2") {
      userPanelUrl = "/panel/admin-dashboard";
      from = location.state?.from?.pathname || `${userPanelUrl}`;
      navigate(from);
    } else if (user_type_id === "1") {
      userPanelUrl = "/panel/super-admin-dashboard";
      from = location.state?.from?.pathname || `${userPanelUrl}`;
      navigate(from);
    } else if (user_type_id === "4") {
      userPanelUrl = "/panel/immunization-worker-dashboard";
      from = location.state?.from?.pathname || `${userPanelUrl}`;
      navigate(from);
    } else if (user_type_id === "5") {
      userPanelUrl = "/panel/parent-dashboard";
      from = location.state?.from?.pathname || `${userPanelUrl}`;
      navigate(from);
    } else if (user_type_id === "6") {
      userPanelUrl = "/panel/parent-dashboard";
      from = location.state?.from?.pathname || `${userPanelUrl}`;
      navigate(from);
    } else if (user_type_id === "7") {
      userPanelUrl = "/panel/parent-dashboard";
      from = location.state?.from?.pathname || `${userPanelUrl}`;
      navigate(from);
    } else if (user_type_id === "3") {
      userPanelUrl = "/panel/facility-worker-dashboard";
      from = location.state?.from?.pathname || `${userPanelUrl}`;
      navigate(from);
    } else {
      return null;
    }
  };

  const togglePasswordType = () => {
    setShowPassword(!showPassword);
    setType(showPassword ? "text" : "password");
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    setLoading(true);

    api
      .post("/api/login", data)
      .then((res) => {
        localStorage.setItem("user_type_id", res.data.user.user_type_id);
        navigator(res.data.user.user_type_id);
        localStorage.setItem("userDetails", JSON.stringify(res.data.user));

        dispatch(storeUserDetails(res.data.user));
        dispatch(storeFacilityWorkerDetails(res.data.user));
        dispatch(storeFacilityDetails(res.data.facility));
        dispatch(storeAgencyDetails(res.data.agency));
        // setLoggedInUser(res.data.user);

        localStorage.setItem("token", res.data?.token);
        // console.log(res.message);
        setSuccess(true);
        setLoading(false);
        navigator();
        // navigate(from);
      })
      .catch((error) => {
        if (!error?.response) {
          setErrorMessage(
            "Unable to connect. Please Check Your Internet Connection."
          );
        } else if (error.response.data.error) {
          setErrorMessage(error.response.data.error);
        } else {
          setErrorMessage(error.response.data.message);
        }
        setLoading(false);
      });
  };

  return (
    <div className="login__container">
      {/* <div className="login__content--container"> */}
      {/* <div className="login__form--container"> */}
      {/* <span className="navLogo-and-links">
            <img
              src={require("../../assets/images/imtrackerlogo.png")}
              alt="imtracker__logo"
              className="login__imt--logo"
            />
          </span> */}
      {/* <div className="form-and-text__container"> */}
      <Arrow className="login__page-arrow" />
      <span className="login__form--texts">
        {loading ? (
          <div className="loader__container">
            <ClimbingBoxLoader color="#ffffff" loading={loading} size={15} />
          </div>
        ) : null}

        {errorMessage ? (
          <p
            style={{
              color: "#F95959",
              marginBottom: "1rem",
            }}
          >
            {errorMessage}
          </p>
        ) : (
          ""
        )}
        {/* <p className="login__form-sub--text">
                Enter your e-mail address and password to log in
                <br />
                as employee or admin.
              </p> */}
      </span>
      <form action="" className="login__form" onSubmit={handleSubmit(onSubmit)}>
        <span className="vaccine__app-span">
          <p className="vaccine__app-text">
            Vaccination<span className="app__text">App</span>
          </p>
        </span>
        <label htmlFor="Email" className="login__email--label">
          Email or Phone number
        </label>
        <div className="login__email-and-icon">
          <input
            type="text"
            className={errors.data ? "email--input" : "login__email--input"}
            {...register("data", { required: true })}
          />
          {errors.data && (
            <p
              style={{
                color: "#F95959",
                marginTop: "1rem",
              }}
            >
              Email or Phone Number is required
            </p>
          )}
          {/* <MailLogo className="mail__icon" /> */}
        </div>
        <label htmlFor="password" className="login__password--label">
          Password
        </label>
        <div className="login__password-and-icons">
          <input
            type={type}
            className={
              errors.password ? "password--input" : "login__password--input"
            }
            {...register("password", { required: true })}
          />
          {errors.password && (
            <p
              style={{
                color: "#F95959",
                marginTop: "1rem",
              }}
            >
              Password is required
            </p>
          )}
          {/* {type === "password" ? (
              <EyeLogo className="eye__icon" onClick={togglePasswordType} />
            ) : (
              <EyeOffLogo className="eye__icon" onClick={togglePasswordType} />
            )}
            <LockLogo className="lock__icon" /> */}
        </div>
        <div className="remember__me-and-forgot-password--container">
          <span className="remember__me-span">
            <input type="checkbox" name="myCheckbox" value="enabled" />
            <p className="remember__me-text">Remember me</p>{" "}
          </span>
          <p className="forgot__password">Forgot Password?</p>
        </div>
        <span className="login__CTA-span">
          <button className="login__submit--CTA">Sign in</button>
        </span>
        {localStorage.getItem("user_type_id") ? (
          <span className="create__comp--CTA-span">
            <button className="create__comp--acc" onClick={() => navigator()}>
              Offline Access
            </button>
          </span>
        ) : null}
      </form>
      {/* <p className="dont__have-account">
              Don't have an account?{" "}
              <span style={{ color: "#655d8a" }}>
                <a href="https://fezzant.com/booking">Book a Demo</a>
              </span>
            </p> */}

      {/* </div> */}
      {/* </div> */}
      {/* <div className="login__image--container">
          <div className="login__image--box">
            <img
              src={require("../../assets/images/spot-baby-cot.png")}
              alt="login__image"
            />
          </div>
          <h1 className="login__main--text">
     
            <br />
            cyber attacks
          </h1>
          <p className="login__sub--text">
            Login to start protecting your organization
            <br /> from cyberattacks.
          </p>
        </div> */}
      {/* </div> */}
    </div>
  );
};

export default Login;
