import { babiesActionTypes } from "./babiesActionTypes";

export const storeBabyDetails = (data) => ({
  type: babiesActionTypes.STORE_BABY_DETAILS,
  payload: data,
});
export const storeBabiesDetails = (data) => ({
  type: babiesActionTypes.STORE_BABIES_DETAILS,
  payload: data,
});
export const storeSearchedBabies = (data) => ({
  type: babiesActionTypes.SEARCH_FOR_BABIES,
  payload: data,
});
