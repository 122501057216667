import { useState, useEffect, useMemo } from "react";
// import { ReactComponent as SettingsIcon } from "../../assets/icons/settings-icon.svg";
// import { ReactComponent as StarIcon } from "../../assets/icons/profile-star.svg";
// import { ReactComponent as GoldMedalIcon } from "../../assets/icons/gold-medal.svg";
// import { ReactComponent as CrownIcon } from "../../assets/icons/crown.svg";
// import { ReactComponent as ClosedRoadIcon } from "../../assets/icons/closed-road.svg";
// import { ReactComponent as VerifiedIcon } from "../../assets/icons/verified.svg";
import axios from "axios";
// import Leaderboard from "../Leaderboard/Leaderboard.component";
// import ActivityHeatMap from "../ActivityHeatMap/ActivityHeatMap.component";

// import OverlayPrompt from "../OverlayPrompt/OverlayPrompt.component";
import ProfileDataForm from "../ProfileDataForm/ProfileDataForm";
import "./profile.css";

const Profile = () => {
  const [outlet, setOutlet] = useState("subscription");
  const [planDetails, setPlanDetails] = useState();
  const [loading, setLoading] = useState(false);

  const userType = "admin";

  const toggleOutlet = () => {
    if (outlet === "subscription") {
      setOutlet("progress");
    } else {
      setOutlet("subscription");
    }
  };

  // For getting username acronym

  const str = "Ahmad Bello";
  const matches = str.match(/\b(\w)/g);
  const acronym = matches.join("");

  return (
    <div className="profile__container">
      <ProfileDataForm />
    </div>
  );
};

export default Profile;
