import { useState } from "react";
import { toast } from "react-toastify";
import { ReactComponent as XIcon } from "../../assets/icons/close-icon.svg";
import axios from "axios";
import { api } from "../..";

const AddPricePlan = ({ showAddPricePlan, setShowAddPricePlan }) => {
  const [minUsers, setMinUsers] = useState("");
  const [maxUsers, setMaxUsers] = useState("");
  const [monthlyPrice, setMonthlyPrice] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const postPricePlan = () => {
    api
      .post("/api/price_plan", {
        min_users: minUsers,
        max_users: maxUsers,
        monthly_price: monthlyPrice,
      })
      .then((res) => {
        setMaxUsers("");
        setMinUsers("");
        setMonthlyPrice("");
        toast.success("Price Plan Added Successfully!");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error Processing Request, please try again");
      });
  };

  return (
    <div className="addChild__container">
      <div
        className="addUserForm__content--container"
        style={{ height: "100%", width: "100vw" }}
      >
        <div className="addState__form">
          <span className="form__heading-and-close--CTA">
            <p className="addUserForm__formheading">Add Price Plan</p>
            <XIcon
              style={{ fill: "#F95959", cursor: "pointer" }}
              onClick={() => setShowAddPricePlan(!showAddPricePlan)}
            />
          </span>
          <label htmlFor="state" className="addUserForm__password--label">
            Minimum No of Users
          </label>
          <div className="addUserForm__password-and-icons">
            <input
              type="text"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              id="minUsers"
              autoComplete="off"
              onChange={(e) => setMinUsers(e.target.value)}
              value={minUsers}
              style={{ width: "100%", marginTop: "1.5rem" }}
              required
              aria-describedby="uidnote"
            />
            {errorMessage && (
              <p
                style={{
                  color: "#F95959",
                  marginTop: "1rem",
                }}
              >
                Minimum number of users is Required
              </p>
            )}
          </div>
          <label htmlFor="state" className="addUserForm__password--label">
            Maximum No of Users
          </label>
          <div className="addUserForm__password-and-icons">
            <input
              type="text"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              id="maxUsers"
              autoComplete="off"
              onChange={(e) => setMaxUsers(e.target.value)}
              value={maxUsers}
              style={{ width: "100%", marginTop: "1.5rem" }}
              required
              aria-describedby="uidnote"
            />
            {errorMessage && (
              <p
                style={{
                  color: "#F95959",
                  marginTop: "1rem",
                }}
              >
                Maximum number of users is Required
              </p>
            )}
          </div>
          <label htmlFor="state" className="addUserForm__password--label">
            Monthly Price
          </label>
          <div className="addUserForm__password-and-icons">
            <input
              type="text"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              id="monthlyPrice"
              autoComplete="off"
              onChange={(e) => setMonthlyPrice(e.target.value)}
              value={monthlyPrice}
              style={{ width: "100%", marginTop: "1.5rem" }}
              required
              aria-describedby="uidnote"
            />
            {errorMessage && (
              <p
                style={{
                  color: "#F95959",
                  marginTop: "1rem",
                }}
              >
                Monthly price is Required
              </p>
            )}
          </div>
          <button
            className="addUserForm__submit--CTA"
            onClick={() => postPricePlan()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddPricePlan;
