import { useState, useMemo } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { ReactComponent as XIcon } from "../../assets/icons/close-icon.svg";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";

import "./fileUpload.css";
import { api } from "../..";

const FileUpload = ({ showUploadFile, setShowUploadFile, childDetails }) => {
  const [selectedFile, setSelectedFile] = useState();
  const [fileName, setFileName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  const fileSelectedHandler = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const UploadFile = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("child_id", childDetails.id);
    formData.append("name", fileName);
    formData.append("document", selectedFile);

    api
      .post("/api/upload/document", formData, config)
      .then((res) => {
        toast.success("File uploaded successfully");
        setFileName("");
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Request Failed. Please try again");
        setLoading(false);
      });
  };

  return (
    <div className="addChild__container">
      {loading ? (
        <div
          className="loader__container"
          style={{ height: "100vh", position: "fixed" }}
        >
          <ClimbingBoxLoader color="#ffffff" loading={loading} size={15} />
        </div>
      ) : null}
      <div
        className="addUserForm__content--container"
        style={{ height: "100%", width: "100vw" }}
      >
        <div className="addState__form">
          <span className="form__heading-and-close--CTA">
            <p className="addUserForm__formheading">File Upload</p>
            <XIcon
              style={{ fill: "#F95959", cursor: "pointer" }}
              onClick={() => setShowUploadFile(!showUploadFile)}
            />
          </span>
          <label htmlFor="state" className="addUserForm__password--label">
            Select File
          </label>
          <div className="addUserForm__password-and-icons">
            <input
              type="file"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              id="state"
              autoComplete="off"
              onChange={(e) => fileSelectedHandler(e)}
              //   value={selectedFile}
              style={{ width: "100%", marginTop: "1.5rem", border: "none" }}
              required
              aria-describedby="uidnote"
            />
            <input
              type="text"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              id="fileTitle"
              autoComplete="off"
              placeholder="Please Enter File Name"
              onChange={(e) => setFileName(e.target.value)}
              value={fileName}
              style={{ width: "100%", marginTop: "1.5rem" }}
              required
            />
            {errorMessage && (
              <p
                style={{
                  color: "#F95959",
                  marginTop: "1rem",
                }}
              ></p>
            )}
          </div>
          <button
            className="addUserForm__submit--CTA"
            style={{ width: "100%" }}
            onClick={() => UploadFile()}
            disabled={!fileName || !selectedFile ? true : false}
          >
            Upload
          </button>
        </div>
      </div>
    </div>
  );
};

export default FileUpload;
