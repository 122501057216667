import { useState, useRef, useMemo, useEffect } from "react";
import { ReactComponent as XIcon } from "../../assets/icons/close-icon.svg";
import { toast } from "react-toastify";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import { useSelector } from "react-redux";
import { api } from "../..";

const UpdateBiodata = ({ showEditChildBio, setShowEditChildBio }) => {
  const baby = useSelector((state) => state.babies.baby);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailFocus, setEmailFocus] = useState(false);
  const [firstName, setFirstName] = useState(baby.first_name);
  const [lastName, setLastName] = useState(baby.last_name);
  const [errMsg, setErrMsg] = useState("");
  const [birthCertNo, setBirthCertNum] = useState(baby.birth_cert_no);
  const [childVacId, setChildVacId] = useState(baby.child_vac_id);
  const [birthCertNumFocus, setBirthCertNumFocus] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState(baby.date_of_birth);
  const [dateOfBirthFocus, setDateOfBirthFocus] = useState(false);
  const [gender, setGender] = useState(baby.gender);
  const [genotype, setGenotype] = useState(baby.genotype);
  const [bloodGroup, setBloodGroup] = useState(baby.blood_group);
  const [success, setSuccess] = useState(false);

  const emailRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();

    //if button enabled with JS hack
    setLoading(true);
    api
      .post(`api/child/${baby.id}`, {
        first_name: firstName,
        last_name: lastName,
        birth_cert_no: birthCertNo,
        child_vac_id: childVacId,
        dob: dateOfBirth,
        gender: gender,
        genotype,
        blood_group: bloodGroup,
      })
      .then(({ res }) => {
        setSuccess(true);
        toast.success("Biodata Updated Successfully!");
        setFirstName("");
        setLastName("");
        setGender("");
        setBirthCertNum("");
        setDateOfBirth("");
        setGenotype("");
        setBloodGroup("");
        // setChild(res?.data?.child);
        setLoading(false);
      })

      .catch((error) => {
        if (!error?.response) {
          setErrMsg("No Server Response");
          toast.error("No Server Response");
        } else if (error.response?.status === 400) {
          setErrMsg("Child Already Exists");
          toast.error("Child Already Exists");
        } else {
          setErrMsg("Registration Failed");
          toast.error("Registration Failed");
        }

        setLoading(false);
      });
  };

  return (
    <div className="addChild__container">
      {loading ? (
        <div
          className="loader__container"
          style={{ height: "100vh", position: "fixed" }}
        >
          <ClimbingBoxLoader color="#ffffff" loading={loading} size={15} />
        </div>
      ) : null}
      <div className="addUserForm__content--container">
        <div className="addUserForm__form--container">
          <span className="addUser__logo-span">{/* <FezzantLogo /> */}</span>
          <div className="add-user__form-and-text__container">
            <span className="addUser__heading-and-close--CTA">
              <span className="addUserForm__form--texts">
                {/* {loading ? (
                  <Loading type="cylon" color="rgb(101, 93, 138)" />
                ) : null} */}
                {/* {errMsg ? <h2>{errMsg}</h2> : null} */}

                <h2 className="addUserForm__formheading">Edit Child's Bio</h2>

                {errorMessage ? (
                  <p
                    style={{
                      color: "#F95959",
                      marginBottom: "1rem",
                    }}
                  >
                    {errorMessage}
                  </p>
                ) : (
                  ""
                )}
                <p className="addUserForm__form-sub--text">
                  Edit Details and Submit.
                </p>
              </span>
              <XIcon
                style={{ fill: "#F95959", cursor: "pointer" }}
                onClick={() => setShowEditChildBio(!showEditChildBio)}
              />
            </span>
            <form
              action="
                "
              className="addUserForm__form"
              onSubmit={handleSubmit}
            >
              <label
                htmlFor="firstName"
                className="addUserForm__password--label"
              >
                Child's First Name
              </label>
              <div className="addUserForm__password-and-icons">
                <input
                  type="text"
                  className={
                    errorMessage ? "email--input" : "addChild__email--input"
                  }
                  id="firstName"
                  autoComplete="off"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                  aria-describedby="uidnote"
                />
                {errorMessage && (
                  <p
                    style={{
                      color: "#F95959",
                      marginTop: "1rem",
                    }}
                  >
                    Child's First Name is required
                  </p>
                )}
              </div>
              <label
                htmlFor="lasttName"
                className="addUserForm__password--label"
              >
                Child's Last Name
              </label>
              <div className="addUserForm__password-and-icons">
                <input
                  type="text"
                  className={
                    errorMessage ? "email--input" : "addChild__email--input"
                  }
                  id="lastName"
                  autoComplete="off"
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                  // required
                  aria-describedby="uidnote"
                  onFocus={() => setEmailFocus(true)}
                  onBlur={() => setEmailFocus(false)}
                />
                {errorMessage && (
                  <p
                    style={{
                      color: "#F95959",
                      marginTop: "1rem",
                    }}
                  >
                    Child's Last Name is required
                  </p>
                )}
              </div>
              <label
                htmlFor="birthCertNo"
                className="addUserForm__password--label"
              >
                Child's Birth Certificate No
              </label>
              <div className="addUserForm__password-and-icons">
                <input
                  type="text"
                  className={
                    errorMessage ? "email--input" : "addChild__email--input"
                  }
                  id="birthCertNo"
                  autoComplete="off"
                  onChange={(e) => setBirthCertNum(e.target.value)}
                  value={birthCertNo}
                  // required
                  aria-describedby="uidnote"
                  onFocus={() => setBirthCertNumFocus(true)}
                  onBlur={() => setBirthCertNumFocus(false)}
                />
                {errorMessage && (
                  <p
                    style={{
                      color: "#F95959",
                      marginTop: "1rem",
                    }}
                  >
                    Child's Birth Certificate Number is required
                  </p>
                )}
                {/* <NameIcon className="mail__icon" /> */}
              </div>
              <label
                htmlFor="birthCertNo"
                className="addUserForm__password--label"
              >
                Child's VAC ID
              </label>
              <div className="addUserForm__password-and-icons">
                <input
                  type="text"
                  className={
                    errorMessage ? "email--input" : "addChild__email--input"
                  }
                  id="birthCertNo"
                  autoComplete="off"
                  onChange={(e) => setChildVacId(e.target.value)}
                  value={childVacId}
                  // required
                  aria-describedby="uidnote"
                />
                {errorMessage && (
                  <p
                    style={{
                      color: "#F95959",
                      marginTop: "1rem",
                    }}
                  >
                    Child's Birth Certificate Number is required
                  </p>
                )}
                {/* <NameIcon className="mail__icon" /> */}
              </div>
              <label
                htmlFor="genotype"
                className="addUserForm__password--label"
              >
                Child's Genotype
              </label>
              <div className="addUserForm__password-and-icons">
                <select
                  // name="countries"
                  id="gender"
                  className={
                    errorMessage ? "email--input" : "addChild__email--input"
                  }
                  value={genotype}
                  // required
                  onChange={(e) => setGenotype(e.target.value)}
                >
                  <option value=""></option>
                  <option value="AA">AA</option>
                  <option value="AS">AS</option>
                  <option value="AB">AB</option>
                </select>
                {errorMessage && (
                  <p
                    style={{
                      color: "#F95959",
                      marginTop: "1rem",
                    }}
                  >
                    Child's genotype is required
                  </p>
                )}
                {/* <NameIcon className="mail__icon" /> */}
              </div>
              <label
                htmlFor="bloodGroup"
                className="addUserForm__password--label"
              >
                Child's Blood Group
              </label>
              <div className="addUserForm__password-and-icons">
                <select
                  // name="countries"
                  id="bloodGroup"
                  className={
                    errorMessage ? "email--input" : "addChild__email--input"
                  }
                  value={bloodGroup}
                  // required
                  onChange={(e) => setBloodGroup(e.target.value)}
                >
                  <option value=""></option>
                  <option value="O+">O+</option>
                  <option value="O-">O-</option>
                  <option value="A+">A+</option>
                  <option value="A-">A-</option>
                  <option value="B-">B-</option>
                  <option value="AB+">AB+</option>
                  <option value="AB-">AB-</option>
                  <option value="B+">B+</option>
                </select>
                {errorMessage && (
                  <p
                    style={{
                      color: "#F95959",
                      marginTop: "1rem",
                    }}
                  >
                    Child's blood group is required
                  </p>
                )}
                {/* <NameIcon className="mail__icon" /> */}
              </div>
              <label htmlFor="dob" className="addUserForm__password--label">
                Child's Date Of Birth
              </label>
              <div className="addUserForm__password-and-icons">
                <input
                  type="date"
                  className={
                    errorMessage ? "email--input" : "addChild__email--input"
                  }
                  id="dob"
                  autoComplete="off"
                  onChange={(e) =>
                    setDateOfBirth(e.target.value.split("/").join("-"))
                  }
                  value={dateOfBirth}
                  required
                  aria-describedby="uidnote"
                  onFocus={() => setDateOfBirthFocus(true)}
                  onBlur={() => setDateOfBirthFocus(false)}
                />
                {errorMessage && (
                  <p
                    style={{
                      color: "#F95959",
                      marginTop: "1rem",
                    }}
                  >
                    Child's DOB is required
                  </p>
                )}
                {/* <NameIcon className="mail__icon" /> */}
              </div>
              <label htmlFor=" gender" className="addUserForm__password--label">
                Child's Gender
              </label>
              <div className="addUserForm__password-and-icons">
                <select
                  // name="countries"
                  id="gender"
                  className={
                    errorMessage ? "email--input" : "addChild__email--input"
                  }
                  value={gender}
                  required
                  onChange={(e) => setGender(e.target.value)}

                  // {...register("country", { required: true })}
                >
                  <option value=""></option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                {errorMessage && (
                  <p
                    style={{
                      color: "#F95959",
                      marginTop: "1rem",
                    }}
                  >
                    Child's gender is required
                  </p>
                )}
              </div>

              <button className="addUserForm__submit--CTA">Update</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateBiodata;
