import { ReactComponent as MenuIcon } from "../../assets/icons/more-vertical.svg";
import { ReactComponent as AddUserIcon } from "../../assets/icons/add-user.svg";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import "./table.css";
import TableRow from "../TableRow/TableRow";

const Table = ({
  title,
  tableData,
  marginTop,
  buttonTitle,
  buttonUrl,
  buttonFunction,
  buttonFunctionValue,
  handleSubmit,
  setSelectedChildForSync,
  buttonIcon,
}) => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const navigate = useNavigate();

  const navigateToFullData = () => {
    if (title === "Babies Registered") {
      navigate("/panel/full-children-list");
    }
  };
  return (
    <>
      <div className="table__container">
        <div
          className="table__title--container"
          // style={{ marginTop: `${marginTop}` }}
        >
          <p className="table__title">{title}</p>

          {buttonTitle === "Add Immnunization Worker" &&
          userDetails?.user_type !== "user" &&
          userDetails.user_type_id !== "2" ? (
            // <Online>
            <button
              className="addChild__CTA"
              onClick={() => buttonFunction(!buttonFunctionValue)}
            >
              {buttonIcon} {buttonTitle}
            </button>
          ) : // </Online>
          buttonTitle &&
            userDetails?.user_type !== "user" &&
            userDetails.user_type_id !== "2" ? (
            <button
              className="addChild__CTA"
              onClick={() => buttonFunction(!buttonFunctionValue)}
            >
              {buttonIcon} {buttonTitle}
            </button>
          ) : buttonTitle === "Sync All" ? (
            <button
              className="addChild__CTA"
              onClick={() => buttonFunction(!buttonFunctionValue)}
            >
              {buttonTitle}
            </button>
          ) : null}
        </div>

        <table>
          <tr className="table__header--row">
            {tableData?.headings.map((heading) => (
              <th key={heading}>{heading}</th>
            ))}
            {/* <th>Number</th>
            <th>DOB</th>
            <th>Date of Next Vaccination</th>
            <th>Action</th> */}
          </tr>
          {tableData?.rows?.map((row) => {
            return (
              <TableRow
                key={uuidv4()}
                row={row}
                title={title}
                // handleSubmit={handleSubmit}
                setSelectedChildForSync={setSelectedChildForSync}
              />
            );
          })}
        </table>
        {title === "Babies Registered" ? (
          <span className="see__full-table--details">
            <p
              className="see__table-data--text"
              onClick={() => navigateToFullData()}
            >
              See All
            </p>
          </span>
        ) : null}
      </div>
    </>
  );
};

export default Table;
