import { useState, useEffect, useMemo } from "react";
import Table from "../Table/Table";
import { db } from "../../App";
import { useLiveQuery } from "dexie-react-hooks";
import axios from "axios";
import { toast } from "react-toastify";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";

import "./dataSync.css";
import { api } from "../..";

const ADDCHILDURL = "/api/register_child";

const DataSync = () => {
  const [offlineChildrenData, setOfflineChildrenData] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthCertNo, setBirthCertNum] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [firstNames, setFirstNames] = useState([]);
  const [lastNames, setLastNames] = useState([]);
  const [genders, setGenders] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [emails, setEmails] = useState([]);
  const [address, setAddress] = useState([]);
  const [caregivers, setCaregivers] = useState([]);
  const [userTypeIds, setUserTypeIds] = useState([]);
  const [loading, setLoading] = useState(false);

  const { childData } = db;

  const allItems = useLiveQuery(() => childData.toArray(), []);
  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  useEffect(() => {
    setOfflineChildrenData(allItems);

    // allItems?.forEach((child, index) => {
    //   setFirstName(child.firstName);
    //   setLastName(child.lastName);
    //   setBirthCertNum(child.birthCertNo);
    //   setDateOfBirth(child.dateOfBirth);
    //   setGender(child.gender);
    //   setFirstNames(child.firstNames);
    //   setLastNames(child.lastNames);
    //   setGenders(child.genders);
    //   setPhoneNumbers(child.phoneNumbers);
    //   setEmails(child.emails);
    //   setAddress(child.address);
    //   setCaregivers(child.caregivers);
    //   setUserTypeIds(child.userTypeIds);
    // });
  }, [allItems]);

  // console.log({ firstName });

  const deleteChildData = async (id) => childData.delete(id);

  const handleSubmit = () => {
    allItems.forEach((child) => {
      setLoading(true);
      api
        .post(
          ADDCHILDURL,
          {
            first_name: child.firstName,
            last_name: child.lastName,
            birth_cert_no: child.birthCertNo,
            dob: child.dateOfBirth,
            gender: child.gender,
            p_firstname: child.firstNames,
            p_lastname: child.lastNames,
            email: child.emails,
            phone_number: child.phoneNumbers,
            user_type_id: child.userTypeIds,
            p_gender: child.genders,
            address: child.address,
            caregiver: child.caregivers,
            no_parent: child.firstNames.length,
          },
          config
        )
        .then((res) => {
          //   setSuccess(true);
          toast.success("Child's Data Synced Successfully!");
          deleteChildData(child.id);
          setLoading(false);
        })
        .catch((error) => {
          // deleteChildData(child.id);
          if (!error?.response) {
            // setErrMsg("No Server Response");
            toast.error("No Server Response");
          } else if (error.response?.status === 400) {
            // setErrMsg("Child Already Exists");
            toast.error("Child Already Exists");
          } else if (error.response?.status === 500) {
            // setErrMsg("Child Already Exists");
            toast.error("Child Already Exists");
          } else {
            // setErrMsg("Registration Failed");
            toast.error("Registration Failed");
          }
          setLoading(false);
        });
    });
  };

  const tableData = {
    headings: ["Name", "VAC Number", "DOB", "Action"],
    rows: allItems?.map((child) => {
      return {
        dataOne: child.firstName + " " + child.lastName,
        dataTwo: "VAC/01/a20",
        dataThree: child.dateOfBirth,
        dataFour: "/panel/child-details/",
        dataFive: child.id,
        child: child,
      };
    }),
  };

  // console.log({ allItems });
  // console.log({ lastName });

  return (
    <div className="data__sync-container">
      {loading ? (
        <div className="loader__container">
          <ClimbingBoxLoader color="#ffffff" loading={loading} size={15} />
        </div>
      ) : null}
      <p className="data__sync--heading">Data Sync </p>
      <Table
        title="Data To Sync"
        tableData={tableData}
        buttonTitle={"Sync All"}
        buttonUrl="/panel/register-admin"
        buttonFunction={handleSubmit}
        // buttonFunctionValue={showRegAdminForm}
        // handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default DataSync;
