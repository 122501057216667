import { useState, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { ReactComponent as MenuIcon } from "../../assets/icons/more-vertical.svg";
import { ReactComponent as VerticalMenuIcon } from "../../assets/icons/vertical-menu-icon.svg";
import { ReactComponent as UploadIcon } from "../../assets/icons/upload-data.svg";
import { ReactComponent as EyeIcon } from "../../assets/icons/view-iconTwo.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash-iconThree.svg";
import { useLiveQuery } from "dexie-react-hooks";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import { storeBabiesDetails } from "../../redux/babies/babiesActions";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useDispatch } from "react-redux";
import { db } from "../../App";
import { api } from "../..";
import "./tableRow.css";

const ADDCHILDURL = "/api/register_child";

const TableRow = ({ row, title }) => {
  const [viewActions, setViewActions] = useState(false);
  const [deleteChild, setDeleteChild] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthCertNo, setBirthCertNum] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [firstNames, setFirstNames] = useState([]);
  const [lastNames, setLastNames] = useState([]);
  const [genders, setGenders] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [emails, setEmails] = useState([]);
  const [address, setAddress] = useState([]);
  const [caregivers, setCaregivers] = useState([]);
  const [userTypeIds, setUserTypeIds] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [loading, setLoading] = useState(false);

  const userDetails = useSelector((state) => state.currentUser.currentUser);
  const facility = useSelector((state) => state.facility.currentFacility);

  const { childData } = db;

  const location = useLocation();

  const dispatch = useDispatch();

  const allItems = useLiveQuery(() => childData.toArray(), []);

  // const deleteChild = (id)

  const setSelectedChildForSync = (id) => {
    // allItems.forEach((child) => {
    //   if (child.id === id) {
    //     setFirstName(child.firstName);
    //     setLastName(child.lastName);
    //     setBirthCertNum(child.birthCertNo);
    //     setDateOfBirth(child.dateOfBirth);
    //     setGender(child.gender);
    //     setFirstNames(child.firstNames);
    //     setLastNames(child.lastNames);
    //     setGenders(child.genders);
    //     setPhoneNumbers(child.phoneNumbers);
    //     setEmails(child.emails);
    //     setAddress(child.address);
    //     setCaregivers(child.caregivers);
    //     setUserTypeIds(child.userTypeIds);
    //   }
    // });

    const childClicked = allItems.filter((child) => child.id === id);

    setFirstName(childClicked[0].firstName);
  };

  const deleteChildData = async (id) => childData.delete(id);

  const handleSubmit = (id) => {
    setLoading(true);
    const childClicked = allItems.filter((child) => child.id === id);

    if (childClicked) {
      api
        .post(ADDCHILDURL, {
          first_name: childClicked[0].firstName,
          last_name: childClicked[0].lastName,
          birth_cert_no: childClicked[0].birthCertNo,
          dob: childClicked[0].dateOfBirth,
          gender: childClicked[0].gender,
          p_firstname: childClicked[0].firstNames,
          p_lastname: childClicked[0].lastNames,
          email: childClicked[0].emails,
          phone_number: childClicked[0].phoneNumbers,
          user_type_id: childClicked[0].userTypeIds,
          p_gender: childClicked[0].genders,
          address: childClicked[0].address,
          caregiver: childClicked[0].caregivers,
          no_parent: childClicked[0].firstNames.length,
        })
        .then((res) => {
          //   setSuccess(true);
          toast.success("Child's Data Synced Successfully!");
          deleteChildData(childClicked[0].id);
          setLoading(false);
        })
        .catch((error) => {
          if (!error?.response) {
            // setErrMsg("No Server Response");
            toast.error("No Server Response");
          } else if (error.response?.status === 400) {
            // setErrMsg("Child Already Exists");
            toast.error("Child Already Exists");
          } else if (error.response?.status === 500) {
            // setErrMsg("Child Already Exists");
            toast.error("Child Already Exists");
          } else {
            // setErrMsg("Registration Failed");
            toast.error("Registration Failed");
          }
          setLoading(false);
        });
    } else {
      toast.error("An Error occured. Please try again.");
    }

    // console.log({ firstName });
  };

  const syncChildData = (e, id) => {
    setSelectedChildForSync(id);
    setTimeout(() => {
      handleSubmit(e, id);
    }, 2000);
  };

  const getFacilityChildren = () => {
    api
      .get(`api/facility_children?facility_id=${facility.id}`)
      .then((res) => {
        dispatch(storeBabiesDetails(res.data.data.data));
      })
      .catch((err) => console.log(err));
  };

  const deleteChildFromApp = (id) => {
    setLoading(true);
    api
      .delete(`/api/child/${id}`)
      .then((res) => {
        getFacilityChildren();
        setLoading(false);
        toast.success("Child Deleted.");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error("Error Processing Request. Try again.");
      });
  };

  return (
    <>
      {loading ? (
        <div className="loader__container">
          <ClimbingBoxLoader color="#ffffff" loading={loading} size={15} />
        </div>
      ) : null}
      <tr>
        <td className="child__name">{row.dataOne}</td>
        <td>{row.dataTwo}</td>
        <td>{row.dataThree}</td>
        {/* <td>{}</td> */}

        {location.pathname === "/panel/data-sync" ? (
          <td
            className="action__tD"
            style={{
              display: title === "AEFI" ? "none" : "",
              cursor: "pointer",
              width: "20rem",
              // background: "red",
            }}
            onClick={() => {
              handleSubmit(row.dataFive);
            }}
          >
            <UploadIcon style={{ marginRight: "1.5rem" }} />
            Upload
          </td>
        ) : (
          <td
            className="action__tD"
            style={{ display: title === "AEFI" ? "none" : "" }}
          >
            <MenuIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setViewActions(!viewActions);
              }}
            />
            {viewActions && row.dataFive ? (
              <span className="actions__span">
                <span
                  className="view-and-logo"
                  style={{
                    display: "flex",
                    // borderBottom: "1px solid gray",
                    width: "100%",
                    paddingBottom: ".5rem",
                    cursor: "pointer",
                    flexDirection: "column",
                    backgroundImage: "#f0f2f",
                  }}
                >
                  {title === "Immunization Workers" ? null : (
                    <Link to={`${row.dataFour}${row.dataFive}`}>
                      <p
                        className="action__option"
                        style={{ marginBottom: "1.5rem" }}
                      >
                        View
                      </p>
                    </Link>
                  )}
                  {title !== "Babies Registered" ? null : (
                    <div className="delete__child-action--container">
                      <p
                        className="action__option"
                        style={{ color: "#C40F0F" }}
                        onClick={() => {
                          setDeleteChild(true);
                          setViewActions(false);
                        }}
                      >
                        Delete
                      </p>
                    </div>
                  )}
                </span>
              </span>
            ) : null}
            {deleteChild ? (
              <div
                className="remove__prompt--con"
                style={{
                  position: "absolute",
                  top: "-3rem",
                  left: "-5rem",
                }}
              >
                <p className="remove__prompt--text">
                  Are you sure you want to
                  <br />
                  delete this child?
                </p>
                <span className="remove__action--CTAs">
                  <button
                    className="remove__action--CTA"
                    onClick={() => {
                      deleteChildFromApp(row.dataFive);
                      setDeleteChild(!deleteChild);
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="cancel__delete-CTA"
                    onClick={() => setDeleteChild(!deleteChild)}
                  >
                    Cancel
                  </button>
                </span>
              </div>
            ) : null}
          </td>
        )}
      </tr>
    </>
  );
};
export default TableRow;
