import { useState } from "react";
import SnapPhoto from "../SnapPhoto/SnapPhoto";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { storeFacilityDetails } from "../../redux/currentFacility/currentFacilityActions";
import { api } from "../..";
import "./picturePrompt.css";

const PicturePrompt = ({ closeAddChild, child }) => {
  const navigate = useNavigate();

  const [showSnapPhoto, setShowSnapPhoto] = useState(false);

  const dispatch = useDispatch();

  const facility = useSelector((state) => state.facility.currentFacility);

  const getUserFacility = () => {
    // setLoading(true);
    api
      .get(`api/facility/${facility?.id}`)
      .then((res) => {
        dispatch(storeFacilityDetails(res.data.facility));
        // setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        // setLoading(false);
      });
  };

  const navigateToChildAfterReg = () => {
    getUserFacility();
    navigate(`/panel/child-details/${child.id}`);
    // closeAddChild();
  };

  return (
    <>
      {showSnapPhoto ? (
        <SnapPhoto childDetails={child} setShowSnapPhoto={closeAddChild} />
      ) : (
        <div className="picture__prompt-container">
          <div className="picture__prompt-box">
            <p className="snap__picture-promp--text">
              Do you want to snap and upload picture now?
            </p>
            <button
              className="snap__picture-now"
              onClick={() => setShowSnapPhoto(!showSnapPhoto)}
            >
              Snap Picture
            </button>
            <button
              className="upload__picture-later"
              onClick={() => navigateToChildAfterReg()}
            >
              Upload Later
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default PicturePrompt;
