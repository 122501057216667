import { useState, useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
// import { Table } from "@fullcalendar/daygrid";
import { ReactComponent as VerticalMenuIcon } from "../../assets/icons/vertical-menu-icon.svg";
import { ReactComponent as LeftArrow } from "../../assets/icons/left-icon-two.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/right-icon.svg";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter-icon.svg";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";

import axios from "axios";
import TableR from "./TableR";
import "./children.css";
import { api } from "../..";

// import "./employees.css";

const ChildManagement = () => {
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState();
  const [facility, setFacility] = useState();
  const [viewActions, setViewActions] = useState(false);
  const [seletedId, setSelectedId] = useState();
  const [children, setChildren] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const token = localStorage.getItem("token");

  const location = useLocation();

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  useEffect(() => {
    getUserData();
    getChildren();
    // if (userData) {
    //   getUserFacility();
    // }
  }, [pageNumber]);

  useEffect(() => {
    getUserFacility();
  }, [userData]);

  const openAction = (id) => {
    if (id === seletedId) {
      setViewActions(!viewActions);
    }
  };

  const getChildren = () => {
    setLoading(true);
    api
      .get(`/api/facility_children?page=${pageNumber}`, config)
      .then((res) => {
        setChildren(res.data?.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getUserData = () => {
    api
      .get(`/api/user/${userDetails?.id}`, config)
      .then((res) => {
        setUserData(res?.data);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };

  const getUserFacility = () => {
    // setLoading(true);

    api
      .get(`api/facility/${userData?.data?.facility_id}`, config)
      .then((res) => {
        setFacility(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const immunizationWorkers =
    facility &&
    facility.data?.user?.filter((user) => user.user_type_id === "3");

  // const childDataDisplayed = location === ""

  const tableDataOne = {
    headings: ["#", "Name", "VAC Number", "DOB", "Action"],
    rows: children.map((child) => {
      return {
        dataOne: child.first_name + " " + child.last_name,
        dataTwo: child.child_vac_id,
        dataThree: child.date_of_birth,
        dataFour: "/panel/child-details/",
        dataFive: child.id,
      };
    }),
  };

  // const tableDataOne = {
  //   headings: ["#", "Name", "VAC Number", "DOB", "Action"],
  //   rows: facility?.data?.child.map((child) => {
  //     return {
  //       dataOne: child.first_name + " " + child.last_name,
  //       dataTwo: child.child_vac_id,
  //       dataThree: child.date_of_birth,
  //       dataFour: "/panel/child-details/",
  //       dataFive: child.id,
  //     };
  //   }),
  // };
  const dataToExport = {
    rows: facility?.data?.child.map((child, index) => {
      return {
        SN: index + 1,
        NAME: child.first_name + " " + child.last_name,
        VAC_ID: child.child_vac_id,
        DOB: child.date_of_birth,
      };
    }),
  };

  const filterChildrenData = () => {
    setLoading(true);
    api
      .get(
        `/api/filter_children?first_name=${firstName}&last_name=${lastName}&dob=${dateOfBirth}&page=${pageNumber}`,
        config
      )
      .then((res) => {
        toast.success("Filtered Successfully!");
        setChildren(res?.data?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("An Error Occured. Please retry");
      });
  };

  return (
    <div className="employee__management">
      {loading ? (
        <div className="loader__container">
          <ClimbingBoxLoader color="#ffffff" loading={loading} size={15} />
        </div>
      ) : null}
      <p className="employees__heading">Children Data</p>
      <div className="filter__params-container">
        <span className="filter__param-span">
          <label htmlFor="first__name" className="filter__param-label">
            First Name
          </label>
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="filter__param-input"
          />
        </span>
        <span className="filter__param-span">
          <label htmlFor="last__name" className="filter__param-label">
            Last Name
          </label>
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="filter__param-input"
          />
        </span>
        <span className="filter__param-span">
          <label htmlFor="dob" className="filter__param-label">
            Date Of Birth
          </label>
          <input
            type="date"
            value={dateOfBirth}
            onChange={(e) => setDateOfBirth(e.target.value)}
            className="filter__param-input"
          />
        </span>
        <span className="filter__CTA-span">
          <button
            className="filter__CTA"
            onClick={() => filterChildrenData()}
            disabled={!firstName && !lastName && !dateOfBirth ? true : false}
          >
            Filter
            <FilterIcon style={{ marginLeft: "1rem" }} />
          </button>
        </span>
        {/* <span className="filter__param-span">
          <label htmlFor="immunization__worker" className="filter__param-label">
            Immunization Worker
          </label>
          <input type="text" className="filter__param-input" />
        </span> */}
      </div>
      <span className="title-and-export--CTA">
        {children && (
          <CSVLink
            data={children}
            filename="Children Data"
            className="data__export-CTA"
          >
            Export Children Data
          </CSVLink>
        )}
      </span>

      <div className="employees__container">
        <table className="employees__table">
          <tr>
            {tableDataOne?.headings?.map((heading) => {
              return <th>{heading}</th>;
            })}
          </tr>
          {tableDataOne?.rows?.map((row, index) => {
            return <TableR row={row} index={index} />;
          })}
        </table>

        {/* <Table
          title="Employees"
          tableData={tableDataOne}
          buttonTitle={"Add Immunization Worker"}
          buttonUrl="/panel/register-admin"
          marginTop="6rem"
        /> */}

        <div className="pagination__controls-box">
          <span className="pagination__controls">
            <p
              className="pagination__CTA"
              onClick={() => setPageNumber(pageNumber - 1)}
              style={{
                pointerEvents: pageNumber < 2 ? "none" : "auto",
                color: pageNumber < 2 ? "#dedede" : "black",
              }}
            >
              Previous
            </p>
            <LeftArrow
              onClick={() => setPageNumber(pageNumber - 1)}
              style={{ margin: "0 1rem", cursor: "pointer" }}
            />
            <RightArrow
              onClick={() => setPageNumber(pageNumber + 1)}
              style={{ margin: "0 1rem", cursor: "pointer" }}
            />
            <p
              className="pagination__CTA"
              onClick={() => setPageNumber(pageNumber + 1)}
              style={{
                pointerEvents: children.length < 15 ? "none" : "auto",
                color: children.length < 15 ? "#dedede" : "black",
              }}
            >
              Next
            </p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ChildManagement;
