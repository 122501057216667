import React, { useState, useMemo, useEffect } from "react";
import DashboardTab from "../DashboardTab/DashboardTab";
import { ReactComponent as UserIcon } from "../../assets/icons/user-icon2.svg";
import { ReactComponent as BabyIcon } from "../../assets/icons/baby-icon.svg";
import { ReactComponent as PersonnelIcon } from "../../assets/icons/personnel-icon.svg";
import { ReactComponent as HospitalIcon } from "../../assets/icons/hospital-icon.svg";
import { ReactComponent as StateIcon } from "../../assets/icons/state-icon.svg";
import { useAuthContext } from "../../contexts/AuthContext";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import Table from "../Table/Table";
import axios from "axios";
import { api } from "../../";
import AddChild from "../AddChild/AddChild";
import AddAdmin from "../AddAdmin/AddAdmin";
import { useDispatch } from "react-redux";

import { Bar, Doughnut } from "react-chartjs-2";
import {
  Chart,
  ArcElement,
  Tooltip,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import "./superAdminDashboard.css";

Chart.register(ArcElement, Tooltip, BarElement, CategoryScale, LinearScale);

const SuperAdminDashboard = () => {
  const [healthCareWorkers, setHealthCareWorkers] = useState();
  const [children, setChildren] = useState();
  const [showRegChildForm, setShowRegChildForm] = useState(false);
  const [showRegAdminForm, setShowRegAdminForm] = useState(false);
  const [chartData, setChartData] = useState("");
  const [loading, setLoading] = useState(false);
  const [state, setStates] = useState();
  const [noOfAgencies, setNoOfAgencies] = useState(0);

  // console.log({ authDetails });
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const token = localStorage.getItem("token");

  const dispatch = useDispatch();

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  useEffect(() => {
    getHealthPersonnels();
    getChildren();
    getChartData();
    getAgencies();
    getStates();
  }, [showRegChildForm, showRegAdminForm]);

  const getChartData = () => {
    setLoading(true);
    api
      .get("/api/dash1")
      .then((res) => {
        setChartData(res.data[0]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getHealthPersonnels = () => {
    api
      .get("/api/admin")
      .then((res) => {
        setHealthCareWorkers(res.data.admins);
      })
      .catch((error) => console.log(error));
  };

  const getStates = () => {
    api
      .get("/api/states")
      .then((res) => {
        setStates(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getChildren = () => {
    api
      .get("/api/childern")
      .then((res) => {
        setChildren(res.data.data);
      })
      .catch((error) => console.log(error));
  };

  const getAgencies = () => {
    api.get("/api/agency").then((res) => {
      setNoOfAgencies(res.data?.data?.length);
    });
  };

  const tableTitleOne = "Facility Admins";
  const tableTitleTwo = "Babies Registered";

  const tableDataOne = {
    headings: ["Name", "Phone Number", "Email", "Action"],
    rows: healthCareWorkers?.data?.map((worker) => {
      return {
        dataOne: worker.first_name + " " + worker.last_name,
        dataTwo: worker.phone_number,
        dataThree: worker.email,
        dataFour: "/panel/personnel-data/",
        dataFive: worker.id,
      };
    }),
  };
  const tableDataTwo = {
    headings: ["Name", "VAC Number", "DOB", "Action"],
    rows: children?.data?.map((child) => {
      return {
        dataOne: child.first_name + " " + child.last_name,
        dataTwo: "VAC/01/a20",
        dataThree: child.date_of_birth,
        dataFour: "/panel/child-details/",
        dataFive: child.id,
      };
    }),
  };

  // console.log(tableData.headings);

  const adminDashboardTabData = [
    {
      textOne: "Total Users",
      textTwo: healthCareWorkers?.data.length,
      tabIcon: <UserIcon />,
      iconBackground:
        "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
      boxShadow:
        "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(0 187 212 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
    },
    {
      textOne: "Facility Admins",
      textTwo: healthCareWorkers?.data.length,
      tabIcon: <PersonnelIcon />,
      iconBackground:
        "linear-gradient(195deg, rgb(180 186 115), rgb(180 182 87))",
      boxShadow:
        "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(76 175 79 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
    },
    {
      textOne: "Agencies",
      textTwo: noOfAgencies,
      tabIcon: <HospitalIcon />,
      iconBackground:
        "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
      boxShadow:
        "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
    },
    {
      textOne: "States",
      textTwo: state?.length,
      tabIcon: <StateIcon />,
      iconBackground:
        "linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))",
      boxShadow:
        "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(76 175 79 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
    },
    // {
    //   textOne: "Facility",
    //   textTwo: "H-Medix",
    //   tabIcon: <HospitalIcon />,
    //   iconBackground:
    //     "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))",
    //   boxShadow:
    //     "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(233 30 98 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
    // },
  ];

  const buttonData = {
    title: "Add Child",
    url: "/panel/add-child",
  };

  const data = {
    labels: [
      "state",
      "state",
      "state",
      "state",
      "state",
      "state",
      "state",
      "state",
      "state",
      "state",
      "state",
      "state",
      "state",
      "state",
      "state",
      "state",
      "state",
      "state",
    ],
    datasets: [
      {
        label: "Total number of successful Imunizations",
        data: [
          1, 2, 3, 4, 5, 6, 7, 8, 6, 5, 4, 3, 2, 1, 4, 5, 7, 5, 3, 2, 3, 4, 5,
          6, 4, 2, 3, 5, 4, 3, 5, 7, 8, 3,
        ],
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(54, 162, 235)",
          "rgb(255, 205, 86)",
        ],
        title: ["title"],
        borderWidth: 1,
        hoverOffset: 4,
      },
    ],
  };

  const closeForm = () => {
    setShowRegChildForm(!showRegChildForm);
  };

  const closeAdminForm = () => {
    setShowRegAdminForm(!showRegAdminForm);
  };
  // const data = {
  //   labels: ["Red", "Blue", "Yellow"],
  //   datasets: [
  //     {
  //       label: "My First Dataset",
  //       data: [300, 50, 100],
  //       backgroundColor: [
  //         "rgb(255, 99, 132)",
  //         "rgb(54, 162, 235)",
  //         "rgb(255, 205, 86)",
  //       ],
  //       hoverOffset: 4,
  //     },
  //   ],
  // };

  return (
    <>
      {loading ? (
        <div className="loader__container">
          <ClimbingBoxLoader color="#ffffff" loading={loading} size={15} />
        </div>
      ) : (
        <div className="details__components-container">
          {showRegChildForm ? <AddChild closeForm={closeForm} /> : null}
          {showRegAdminForm ? (
            <AddAdmin
              closeAdminForm={closeAdminForm}
              regUrl="/api/admin/register"
            />
          ) : null}
          <p className="facility__name">App Admin</p>

          <div className="tab__container">
            {adminDashboardTabData.map((data) => {
              return (
                <DashboardTab
                  textOne={data.textOne}
                  textTwo={data.textTwo}
                  icon={data.tabIcon}
                  iconBackground={data.iconBackground}
                  boxShadow={data.boxShadow}
                />
              );
            })}
            {/* <ErrandStatus /> */}
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {/* <h1 className="chart__title">Successful Vaccinations</h1>
            <div className="charts__container">
              <Bar data={data} />
            </div> */}
          </div>
          <Table
            title={tableTitleOne}
            tableData={tableDataOne}
            buttonTitle="Add Personnel"
            buttonUrl="/panel/register-admin"
            buttonFunction={setShowRegAdminForm}
            buttonFunctionValue={showRegAdminForm}
            marginTop="6rem"
          />

          <Table
            title={tableTitleTwo}
            tableData={tableDataTwo}
            buttonTitle="Add Child"
            buttonUrl="/panel/add-child"
            marginTop="6rem"
            buttonFunction={setShowRegChildForm}
            buttonFunctionValue={showRegChildForm}
          />
          {/* <TableDetails
        customerTableData={customerTableData}
        customerTableDetails={customerTableDetails}
      /> */}
        </div>
      )}
    </>
  );
};

export default SuperAdminDashboard;
