import React, { useState, useEffect, useMemo } from "react";
import DashboardTab from "../DashboardTab/DashboardTab";
import { ReactComponent as NurseIcon } from "../../assets/icons/nurse-icon.svg";
import { ReactComponent as BabyIcon } from "../../assets/icons/baby-icon.svg";
import { ReactComponent as HospitalIcon } from "../../assets/icons/hospital-icon.svg";
import Table from "../Table/Table";
import { useAuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import AddChild from "../AddChild/AddChild";
import { ReactComponent as CompletedIcon } from "../../assets/icons/complete-vac-icon.svg";
import { ReactComponent as UncompletedIcon } from "../../assets/icons/uncomplete-vac-icon.svg";
import { ReactComponent as AddUserIcon } from "../../assets/icons/add-user.svg";
import { ReactComponent as CareGiverIcon } from "../../assets/icons/care-giver-icon.svg";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import { ReactComponent as CountIcon } from "../../assets/icons/countingbabies.svg";
import { useSelector, useDispatch } from "react-redux";
import { api } from "../..";
import "./immunizationWorker.css";
import { storeUserDetails } from "../../redux/currentUser/currentUser.actions";
import { storeFacilityDetails } from "../../redux/currentFacility/currentFacilityActions";
import Notification from "../Notification/Notification";
import { storeBabiesDetails } from "../../redux/babies/babiesActions";

const ImmunizationWorker = () => {
  const [userData, setUserData] = useState();
  const [showRegChildForm, setShowRegChildForm] = useState(false);
  const [offlineUserData, setOfflineUserData] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [showRegAdminForm, setShowRegAdminForm] = useState(false);
  const [takenChartData, setTakenChartData] = useState();
  const [missedChartData, setMissedChartData] = useState();
  const [genderChartData, setGenderChartData] = useState();
  const [offlineFacilityData, setOfflineFacilityData] = useState();

  // const [facility, setFacility] = useState(false);
  const [loading, setLoading] = useState(false);

  const [children, setChildren] = useState([]);

  const token = localStorage.getItem("token");

  // const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userDetails = useSelector((state) => state.currentUser.currentUser);
  const facility = useSelector((state) => state.facility.currentFacility);
  const babies = useSelector((state) => state.babies.babies);

  const { setAuthDetails } = useAuthContext();

  const dispatch = useDispatch();

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  useEffect(() => {
    getUserData();
    getUserChildren();
  }, [showRegChildForm, showRegChildForm]);

  useEffect(() => {
    getUserFacility();
    setOfflineUserData(JSON.parse(localStorage.getItem("offlineUserData")));
    setAuthDetails(JSON.parse(localStorage.getItem("offlineUserData")));
  }, [userDetails, showRegChildForm]);

  if (userDetails !== undefined) {
    localStorage.setItem("offlineUserData", JSON.stringify(userDetails));
  }

  const getUserData = () => {
    api
      .get(`/api/user/${userDetails?.id}`)
      .then((res) => {
        // setUserData(res.data.data);
        dispatch(storeUserDetails(res?.data?.user));
      })
      .catch((err) => console.log(err));
  };

  // if (userData) {
  const getUserFacility = () => {
    setLoading(true);
    api
      .get(`api/facility/${userDetails?.facility_id}`)
      .then((res) => {
        // dispatch(storeFacilityDetails(res.data));
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getUserChildren = () => {
    api
      .get(`api/user_children`)
      .then((res) => {
        // setFacilityChildren(res.data.data.data);
        dispatch(storeBabiesDetails(res.data.data.data));
      })
      .catch((err) => console.log(err));
  };
  // }

  useEffect(() => {
    setOfflineFacilityData(
      JSON.parse(localStorage.getItem("offlineFacilityData"))
    );
  }, []);

  if (!offlineFacilityData?.data) {
    localStorage.setItem("offlineFacilityData", JSON.stringify(facility));
  }

  const tableTitle = "Babies Registered";

  const childDataDisplayed = navigator.onLine ? babies : offlineUserData?.child;

  const tableData = {
    headings: ["Name", "VAC Number", "DOB", "Action"],
    rows: childDataDisplayed?.map((child) => {
      return {
        dataOne: child.first_name + " " + child.last_name,
        dataTwo: child.child_vac_id,
        dataThree: child.date_of_birth,
        dataFour: "/panel/child-details/",
        dataFive: child.id,
      };
    }),
  };

  const dashboardTabData = navigator.onLine
    ? [
        {
          textOne: "Babies Registered",
          textTwo: babies.length,
          tabIcon: <BabyIcon />,
          iconBackground:
            "linear-gradient(195deg, rgb(180 186 115), rgb(180 182 87))",
          boxShadow:
            "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(76 175 79 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        },
        {
          textOne: "Total No Of Children",
          textTwo: facility?.total_children_in_the_facility,
          tabIcon: <CountIcon />,
          iconBackground:
            "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
          boxShadow:
            "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(0 187 212 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        },

        {
          textOne: "Completed Vaccinations",
          textTwo: facility?.children_with_completed_vaccination,
          tabIcon: <CompletedIcon />,
          iconBackground:
            "linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))",
          boxShadow:
            "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(76 175 79 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        },
        {
          textOne: "Uncompleted Vaccinations",
          textTwo: facility?.children_with_uncompleted_vaccination,
          tabIcon: <UncompletedIcon />,
          iconBackground:
            "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))",
          boxShadow:
            "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(233 30 98 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        },
        // {
        //   textOne: "Caregivers",
        //   textTwo: facility?.total_primary_caregiver,
        //   tabIcon: <CareGiverIcon />,
        //   iconBackground:
        //     "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
        //   boxShadow:
        //     "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        // },
      ]
    : [
        {
          textOne: "Facility",
          textTwo: offlineUserData?.facility?.name,
          tabIcon: <HospitalIcon />,
          iconBackground:
            "linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))",
          boxShadow:
            "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(76 175 79 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        },
        {
          textOne: "Babies Registered",
          textTwo: offlineUserData?.length,
          tabIcon: <BabyIcon />,
          iconBackground:
            "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))",
          boxShadow:
            "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(233 30 98 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        },

        // {
        //   textOne: "Total No Of Children",
        //   textTwo: offlineFacilityData?.total_children_in_the_facility,
        //   tabIcon: <CountIcon />,
        //   iconBackground:
        //     "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
        //   boxShadow:
        //     "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(0 187 212 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        // },
      ];

  const buttonData = {
    text: "Add child",
    url: "/panel/add-child",
  };

  //   const tableTitleOne = "Health Facility Workers";
  const tableTitleTwo = "Babies Registered";

  //   const tableDataOne = {
  //     headings: ["Name", "Phone Number", "Email", "Action"],
  //     rows: facility?.user?.map((user) => {
  //       return {
  //         dataOne: user.first_name + " " + user.last_name,
  //         dataTwo: user.phone_number,
  //         dataThree: user.email,
  //         dataFour: "/panel/personnel-data/",
  //         dataFive: user.id,
  //       };
  //     }),
  //   };

  // const tableDataTwo = {
  //   headings: ["Name", "VAC Number", "DOB", "Action"],
  //   rows: userData?.child.map((child) => {
  //     return {
  //       dataOne: child.first_name + " " + child.last_name,
  //       dataTwo: "VAC/01/a20",
  //       dataThree: child.date_of_birth,
  //       dataFour: "/panel/child-details/",
  //       dataFive: child.id,
  //     };
  //   }),
  // };

  const closeForm = () => {
    setShowRegChildForm(!showRegChildForm);
  };

  return (
    <div className="details__components-container">
      <div
        className="baby__image-and-notification"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          width: "100%",
          marginBottom: "2rem",
        }}
      >
        <p className="tab__userName">{facility?.facility?.name}</p>
        <Notification />
      </div>
      {loading ? (
        <div className="loader__container">
          <ClimbingBoxLoader color="#ffffff" loading={loading} size={15} />
        </div>
      ) : null}
      {showRegChildForm ? <AddChild closeForm={closeForm} /> : null}
      <p className="facility__name">{userData?.facility.name}</p>
      <div className="tab__container">
        {dashboardTabData.map((data) => {
          return (
            <DashboardTab
              textOne={data.textOne}
              textTwo={data.textTwo}
              icon={data.tabIcon}
              iconBackground={data.iconBackground}
              boxShadow={data.boxShadow}
              // tabSize="1"
            />
          );
        })}
        {/* <ErrandStatus /> */}
      </div>
      {/* {navigator.onLine ? (
        <>
          <p className="chart__title">Vaccines Taken</p>
          <div className="charts__container">
            {takenChartData ? <Bar data={takenData} options={options} /> : null}
          </div>
          <p className="chart__title">Vaccines Missed</p>
          <div className="charts__container">
            {missedChartData ? (
              <Bar data={missedData} options={optionsTwo} />
            ) : null}
          </div>
          <p className="chart__title">Gender Distribution</p>
          <div className="charts__container">
            {genderChartData ? (
              <Pie data={genderData} options={optionsThree} />
            ) : null}
          </div>
        </>
      ) : null} */}
      <Table
        title={tableTitle}
        tableData={tableData}
        buttonIcon={<AddUserIcon className="add__user-icon" />}
        buttonTitle="Add Child"
        buttonFunction={setShowRegChildForm}
        buttonFunctionValue={showRegChildForm}
      />

      {/* <Table
        title={tableTitleOne}
        tableData={tableDataOne}
        buttonTitle="Add Personnel"
        buttonUrl="/panel/register-admin"
        buttonFunction={setShowRegAdminForm}
        buttonFunctionValue={showRegAdminForm}
        marginTop="6rem"
      /> */}

      {/* <Table
        title={tableTitleOne}
        tableData={tableDataOne}
        buttonTitle="Add Personnel"
        buttonUrl="/panel/register-admin"
        buttonFunction={setShowRegAdminForm}
        buttonFunctionValue={showRegAdminForm}
        marginTop="6rem"
      /> */}

      {/* <Table
        title={tableTitleTwo}
        tableData={tableData}
        buttonTitle="Add Child"
        buttonUrl="/panel/add-child"
        marginTop="6rem"
        buttonFunction={setShowRegChildForm}
        buttonFunctionValue={showRegChildForm}
      /> */}
      {/* <TableDetails
        customerTableData={customerTableData}
        customerTableDetails={customerTableDetails}
      /> */}
    </div>
  );
};

export default ImmunizationWorker;
