import SideBar from "../SideBar/SideBar";
import { ReactComponent as SeriesIcon } from "../../assets/icons/series-icon.svg";
// import { ReactComponent as SeriesIcon } from "../../assets/icons/series-icon.svg";
import { ReactComponent as SeriesIconTwo } from "../../assets/icons/series-iconTwo.svg";
import { ReactComponent as ProgressIcon } from "../../assets/icons/progress-icon.svg";
import { ReactComponent as ProgressIconTwo } from "../../assets/icons/progress-iconTwo.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/user-iconOne.svg";
import { ReactComponent as AddAdminIcon } from "../../assets/icons/add-admin-icon.svg";
import { ReactComponent as AddAdminIconFilled } from "../../assets/icons/add-admin-filled.svg";
import { ReactComponent as DashboardIconOne } from "../../assets/icons/home-icon.svg";
import { ReactComponent as DashboardIconTwo } from "../../assets/icons/home-icon-two.svg";
import { ReactComponent as BabyIconOne } from "../../assets/icons/babies-icon-one.svg";
import { ReactComponent as BabyIconTwo } from "../../assets/icons/babies-icon-two.svg";
// import { ReactComponent as CalendarIconOne } from "../../assets/icons/calendar-icon-One.svg";
import { ReactComponent as CalendarIconTwo } from "../../assets/icons/calendar-icon-two.svg";
import { ReactComponent as ProfileIconTwo } from "../../assets/icons/profile-iconOne.svg";
import { ReactComponent as VaccineIconOne } from "../../assets/icons/profile-iconOne.svg";
import { ReactComponent as VaccineIconTwo } from "../../assets/icons/vaccine-icon-two.svg";
import { ReactComponent as ProfileIconOne } from "../../assets/icons/profile-iconTwo.svg";
import { ReactComponent as FileUploadIconOne } from "../../assets/icons/file-upload-filled.svg";
import { ReactComponent as FileUploadIconTwo } from "../../assets/icons/file-upload-outlined.svg";

import Dashboard from "./Dashboard/Dashboard";
import "./panel.css";
import "../SideBar/sideBar.css";

const Panel = () => {
  const superAdminSideBarLinks = [
    {
      linkId: Date.now(),
      urlName: "Dashboard",
      urlLink: "/panel/super-admin-dashboard",
      urlIcon: <DashboardIconOne />,
      urlIconTwo: <DashboardIconTwo />,
    },
    {
      linkId: Date.now(),
      urlName: "Add Child",
      urlLink: `/panel/add-child`,
      urlIcon: <BabyIconOne />,
      urlIconTwo: <BabyIconTwo />,
    },

    // {
    //   linkId: Date.now(),
    //   urlName: "Add Facility Admin",
    //   urlLink: `/panel/add-facility-admin`,
    //   urlIcon: <AddAdminIcon />,
    //   urlIconTwo: <AddAdminIcon />,
    // },

    {
      linkId: Date.now(),
      urlName: "Babies",
      urlLink: `/panel/babies`,
      urlIcon: <BabyIconOne className="mobile__icon-one" />,
      urlIconTwo: <BabyIconTwo className="mobile__icon-two" />,
    },
    {
      linkId: Date.now(),
      urlName: "Manage Babies",
      urlLink: `/panel/children-management`,
      urlIcon: <BabyIconOne />,
      urlIconTwo: <BabyIconTwo />,
    },
    {
      linkId: Date.now(),
      urlName: "Manage Workers",
      urlLink: `/panel/employee-management`,
      urlIcon: <AddAdminIconFilled />,
      urlIconTwo: <AddAdminIcon />,
    },
    {
      linkId: Date.now(),
      urlName: "Data Management",
      urlLink: `/panel/data-management`,
      urlIcon: <ProfileIconOne />,
      urlIconTwo: <ProfileIconTwo />,
    },
    {
      linkId: Date.now(),
      urlName: "Data Sync",
      urlLink: `/panel/data-sync`,
      urlIcon: <FileUploadIconOne />,
      urlIconTwo: <FileUploadIconTwo />,
    },
  ];
  const adminSideBarLinks = [
    {
      linkId: Date.now(),
      urlName: "Dashboard",
      urlLink: "/panel/admin-dashboard",
      urlIcon: <DashboardIconOne />,
      urlIconTwo: <DashboardIconTwo />,
    },

    // {
    //   linkId: Date.now(),
    //   urlName: "Add Child",
    //   urlLink: `/panel/add-child`,
    //   urlIcon: <BabyIconOne />,
    //   urlIconTwo: <BabyIconTwo />,
    // },

    {
      linkId: Date.now(),
      urlName: "Add Facility",
      urlLink: `/panel/add-facility`,
      urlIcon: <DashboardIconOne />,
      urlIconTwo: <DashboardIconTwo />,
    },
    // {
    //   linkId: Date.now(),
    //   urlName: "Babies",
    //   urlLink: `/panel/babies`,
    //   urlIcon: <BabyIconOne className="mobile__icon-one" />,
    //   urlIconTwo: <BabyIconTwo className="mobile__icon-two" />,
    // },

    {
      linkId: Date.now(),
      urlName: "Add Facility Worker",
      urlLink: `/panel/add-facility-worker`,
      urlIcon: <AddAdminIcon />,
      urlIconTwo: <AddAdminIcon />,
    },
    // {
    //   linkId: Date.now(),
    //   urlName: "Add Immunization Worker",
    //   urlLink: `/panel/add-immunization-worker`,
    //   urlIcon: <AddAdminIcon />,
    //   urlIconTwo: <AddAdminIcon />,
    // },
    {
      linkId: Date.now(),
      urlName: "Upcoming Vaccines",
      urlLink: `/panel/pending-vacs`,
      urlIcon: <BabyIconOne />,
      urlIconTwo: <BabyIconTwo />,
    },
    {
      linkId: Date.now(),
      urlName: "SMS Notifications",
      urlLink: `/panel/sent-sms-list`,
      urlIcon: <FileUploadIconOne />,
      urlIconTwo: <FileUploadIconTwo />,
    },
    // {
    //   linkId: Date.now(),
    //   urlName: "Manage Workers",
    //   urlLink: `/panel/employee-management`,
    //   urlIcon: <AddAdminIconFilled />,
    //   urlIconTwo: <AddAdminIcon />,
    // },
    {
      linkId: Date.now(),
      urlName: "Subscription",
      urlLink: `/panel/subscription`,
      urlIcon: <AddAdminIconFilled />,
      urlIconTwo: <AddAdminIcon />,
    },

    {
      linkId: Date.now(),
      urlName: "Add Population",
      urlLink: `/panel/population-update`,
      urlIcon: <FileUploadIconOne />,
      urlIconTwo: <FileUploadIconTwo />,
    },
    {
      linkId: Date.now(),
      urlName: "Data Sync",
      urlLink: `/panel/data-sync`,
      urlIcon: <FileUploadIconOne />,
      urlIconTwo: <FileUploadIconTwo />,
    },
  ];
  const facilityHealthWorkerSideBarLinks = [
    {
      linkId: Date.now(),
      urlName: "Dashboard",
      urlLink: "/panel/facility-worker-dashboard",
      urlIcon: <DashboardIconOne />,
      urlIconTwo: <DashboardIconTwo />,
    },

    {
      linkId: Date.now(),
      urlName: "Add Child",
      urlLink: `/panel/add-child`,
      urlIcon: <BabyIconOne />,
      urlIconTwo: <BabyIconTwo />,
    },

    {
      linkId: Date.now(),
      urlName: "Add Personnel",
      urlLink: `/panel/add-immunization-worker`,
      urlIcon: <AddAdminIcon />,
      urlIconTwo: <AddAdminIcon />,
    },

    {
      linkId: Date.now(),
      urlName: "Babies",
      urlLink: `/panel/babies`,
      urlIcon: <BabyIconOne className="mobile__icon-one" />,
      urlIconTwo: <BabyIconTwo className="mobile__icon-two" />,
    },
    {
      linkId: Date.now(),
      urlName: "Manage Babies",
      urlLink: `/panel/children-management`,
      urlIcon: <BabyIconOne />,
      urlIconTwo: <BabyIconTwo />,
    },
    {
      linkId: Date.now(),
      urlName: "SMS Notifications",
      urlLink: `/panel/sent-sms-list`,
      urlIcon: <FileUploadIconOne />,
      urlIconTwo: <FileUploadIconTwo />,
    },
    // {
    //   linkId: Date.now(),
    //   urlName: "Manage Workers",
    //   urlLink: `/panel/employee-management`,
    //   urlIcon: <AddAdminIconFilled />,
    //   urlIconTwo: <AddAdminIcon />,
    // },
    // {
    //   linkId: Date.now(),
    //   urlName: "Subscription",
    //   urlLink: `/panel/subscription`,
    //   urlIcon: <AddAdminIconFilled />,
    //   urlIconTwo: <AddAdminIcon />,
    // },

    {
      linkId: Date.now(),
      urlName: "Data Sync",
      urlLink: `/panel/data-sync`,
      urlIcon: <FileUploadIconOne />,
      urlIconTwo: <FileUploadIconTwo />,
    },
  ];
  const parentSideBarLinks = [
    {
      linkId: Date.now(),
      urlName: "Home",
      urlLink: "/panel/parent-dashboard",
      urlIcon: <DashboardIconOne className="mobile__icon-one" />,
      urlIconTwo: <DashboardIconTwo className="mobile__icon-two" />,
    },

    {
      linkId: Date.now(),
      urlName: "Babies",
      urlLink: `/panel/babies`,
      urlIcon: <BabyIconOne className="mobile__icon-one" />,
      urlIconTwo: <BabyIconTwo className="mobile__icon-two" />,
    },
    {
      linkId: Date.now(),
      urlName: "Calendar",
      urlLink: `/panel/child-details`,
      urlIcon: <CalendarIconTwo className="mobile__icon-one" />,
      urlIconTwo: <CalendarIconTwo className="mobile__icon-two" />,
    },
    {
      linkId: Date.now(),
      urlName: "Vaccine",
      urlLink: `/panel/vacs-tips`,
      urlIcon: <VaccineIconTwo className="mobile__icon-one" />,
      urlIconTwo: <VaccineIconTwo className="mobile__icon-two" />,
    },
    // {
    //   linkId: Date.now(),
    //   urlName: "Profile",
    //   urlLink: `/panel/profile`,
    //   urlIcon: <ProfileIconOne />,
    //   urlIconTwo: <ProfileIconTwo />,
    // },
  ];
  const imWorkerSideBarLinks = [
    {
      linkId: Date.now(),
      urlName: "Dashboard",
      urlLink: "/panel/immunization-worker-dashboard",
      urlIcon: <DashboardIconOne />,
      urlIconTwo: <DashboardIconTwo />,
    },
    {
      linkId: Date.now(),
      urlName: "Babies",
      urlLink: `/panel/babies`,
      urlIcon: <BabyIconOne className="mobile__icon-one" />,
      urlIconTwo: <BabyIconTwo className="mobile__icon-two" />,
    },

    {
      linkId: Date.now(),
      urlName: "Add Baby",
      urlLink: `/panel/add-child`,
      urlIcon: <BabyIconOne />,
      urlIconTwo: <BabyIconTwo />,
    },
    {
      linkId: Date.now(),
      urlName: "Profile",
      urlLink: `/panel/profile`,
      urlIcon: <ProfileIconOne />,
      urlIconTwo: <ProfileIconTwo />,
    },
    {
      linkId: Date.now(),
      urlName: "Manage Children",
      urlLink: `/panel/children-management`,
      urlIcon: <BabyIconOne />,
      urlIconTwo: <BabyIconTwo />,
    },
    {
      linkId: Date.now(),
      urlName: "Data Sync",
      urlLink: `/panel/data-sync`,
      urlIcon: <FileUploadIconOne />,
      urlIconTwo: <FileUploadIconTwo />,
    },
    // {
    //   linkId: Date.now(),
    //   urlName: "View Children",
    //   urlLink: `/panel/child-details`,
    //   urlIcon: <BabyIconOne />,
    //   urlIconTwo: <BabyIconTwo />,
    // },
  ];
  return (
    <div className="panel__container">
      <SideBar
        superAdminSideBarLinks={superAdminSideBarLinks}
        adminSideBarLinks={adminSideBarLinks}
        facilityHealthWorkerSideBarLinks={facilityHealthWorkerSideBarLinks}
        imWorkerSideBarLinks={imWorkerSideBarLinks}
        parentSideBarLinks={parentSideBarLinks}
      />
      <Dashboard
        superAdminSideBarLinks={superAdminSideBarLinks}
        adminSideBarLinks={adminSideBarLinks}
        facilityHealthWorkerSideBarLinks={facilityHealthWorkerSideBarLinks}
        imWorkerSideBarLinks={imWorkerSideBarLinks}
        parentSideBarLinks={parentSideBarLinks}
      />
    </div>
  );
};

export default Panel;
