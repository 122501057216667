import { useState, useEffect, useMemo } from "react";
// import { Table } from "@fullcalendar/daygrid";
import { Link } from "react-router-dom";
import { ReactComponent as VerticalMenuIcon } from "../../assets/icons/vertical-menu-icon.svg";
import axios from "axios";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter-icon.svg";
import { ReactComponent as LeftArrow } from "../../assets/icons/left-icon-two.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/right-icon.svg";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
// import "./employees.css";
import TableR from "../Children/TableR";
import { api } from "../..";
import { useSelector } from "react-redux";
import { setDate } from "date-fns";

const PendingVaccines = () => {
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState();
  // const [facility, setFacility] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState(0);
  const [children, setChildren] = useState([]);
  const [pendingVacs, setPendingVacs] = useState([]);
  const [viewActions, setViewActions] = useState(false);

  // const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const userDetails = useSelector((state) => state.currentUser.currentUser);
  const facility = useSelector(
    (state) => state.facility.currentFacility.facility
  );

  useEffect(() => {
    getPendingVacs();
  }, []);

  const getPendingVacs = () => {
    setLoading(true);

    api
      .get(`/api/pending_vac?start_date=${startDate}&end_date=${endDate}`)
      .then((res) => {
        setPendingVacs(res?.data);
        setTotal(res?.data?.data?.total);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const filterPendingVacs = () => {
    setLoading(true);
    api
      .get(`/api/pending_vac?start_date=${startDate}&end_date=${endDate}`)
      .then((res) => {
        toast.success("Filtered Successfully!");
        // setChildren(res?.data?.data?.data);
        setPendingVacs(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error("An Error Occured. Please retry");
      });
  };

  const tableDataOne = {
    headings: ["#", "Vaccine", "Total Pending"],
    rows: pendingVacs?.map((vaccine) => {
      // if (user.user_type_id === "3") {
      return {
        dataOne: vaccine.vac_name,
        dataTwo: vaccine.count,
        // dataThree: child.date_of_birth,
        // dataFour: "/panel/child-details/",
        // dataFive: child.id,
      };
      // } else {
      //   return "";
      // }
    }),
  };

  return (
    <div className="employee__management">
      {/* {userDetails.user_type_id === "2" ? (
        <div className="active__facility-note">
          {`Currently Viewing ${facility?.name}'s Data`}
        </div>
      ) : null} */}
      {loading ? (
        <div className="loader__container">
          <ClimbingBoxLoader color="#ffffff" loading={loading} size={15} />
        </div>
      ) : null}
      {/* <p className="employees__heading">
        {total + " " + "Pending Vaccinations"}
      </p> */}
      <p className="employees__heading">Pending Vaccinations</p>
      <div className="filter__params-container">
        {/* <span className="filter__param-span">
          <label htmlFor="first__name" className="filter__param-label">
            First Name
          </label>
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="filter__param-input"
          />
        </span> */}
        {/* <span className="filter__param-span">
          <label htmlFor="last__name" className="filter__param-label">
            Last Name
            </label>
            <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="filter__param-input"
            />
          </span> */}
        <span className="filter__param-span">
          <label htmlFor="date" className="filter__param-label">
            Start Date
          </label>
          <input
            type="date"
            id="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="filter__param-input"
          />
          <label htmlFor="date" className="filter__param-label">
            End Date
          </label>
          <input
            type="date"
            id="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="filter__param-input"
          />
        </span>
        <span className="filter__CTA-span">
          <button
            className="filter__CTA"
            onClick={() => filterPendingVacs()}
            disabled={!startDate ? true : false}
          >
            Filter
            <FilterIcon style={{ marginLeft: "1rem" }} />
          </button>
        </span>
        {/* <span className="filter__param-span">
          <label htmlFor="immunization__worker" className="filter__param-label">
            Immunization Worker
          </label>
          <input type="text" className="filter__param-input" />
        </span> */}
      </div>

      {/* <span className="title-and-export--CTA">
        {children && (
          <CSVLink
            data={children}
            filename="Children With Missed Vaccines Data"
            className="data__export-CTA"
            // style={{ background: "red" }}
          >
            Export Data
          </CSVLink>
        )}
      </span> */}

      <div className="employees__container">
        <table className="employees__table">
          <tr>
            {tableDataOne?.headings?.map((heading) => {
              return <th>{heading}</th>;
            })}
          </tr>
          {tableDataOne?.rows?.map((row, index) => {
            return <TableR row={row} index={index} />;
          })}
        </table>

        {/* <Table
          title="Employees"
          tableData={tableDataOne}
          buttonTitle={"Add Immunization Worker"}
          buttonUrl="/panel/register-admin"
          marginTop="6rem"
        /> */}
        {/* <div className="pagination__controls-box">
          <span className="pagination__controls">
            <p
              className="pagination__CTA"
              onClick={() => setPageNumber(pageNumber - 1)}
              style={{
                pointerEvents: pageNumber < 2 ? "none" : "auto",
                color: pageNumber < 2 ? "#dedede" : "black",
              }}
            >
              Previous
            </p>
            <LeftArrow
              onClick={() => setPageNumber(pageNumber - 1)}
              style={{ margin: "0 1rem", cursor: "pointer" }}
            />
            <RightArrow
              onClick={() => setPageNumber(pageNumber + 1)}
              style={{ margin: "0 1rem", cursor: "pointer" }}
            />
            <p
              className="pagination__CTA"
              onClick={() => setPageNumber(pageNumber + 1)}
              style={{
                pointerEvents: children?.length < 15 ? "none" : "auto",
                color: children?.length < 15 ? "#dedede" : "black",
              }}
            >
              Next
            </p>
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default PendingVaccines;
