import vacImg from "../../assets/images/full-vac-detail-img.png";
import Accordion from "../Accordion/Accordion";
import "./fullVacDetail.css";

const FullVacDetail = () => {
  return (
    <div className="full__vac-detail--container">
      <div className="full__detail-main--content">
        <p className="full__vac-heading">BCG [Bacille Calmette - Guerin</p>
        <div className="full__vac-img--container">
          <img src={vacImg} alt="" className="vac__img" />
        </div>
        <p className="full__vac-detail-sub--text">
          The Bacillus Calmette-Guérin (BCG) vaccination is given to new born
          babies at risk of getting tuberculosis (TB). TB is a very serious
          infectious disease that can cause TB meningitis in babies.
        </p>
        <p className="full__vac-heading-two">How BCG vaccine works</p>
        <p className="full__vac-detail-sub--text">
          BCG vaccine has a weakened form of the bacteria that cause
          tuberculosis (TB). The vaccine doesn't cause TB, but helps your baby
          develop protection (immunity) against the disease. The BCG vaccination
          is particularly effective in protecting babies and young children
          against the more rare severe forms of TB such as TB meningitis
          (swelling of the lining of the brain).
        </p>
        <p className="full__vac-heading-two">How The Vaccination is given</p>
        <p className="full__vac-detail-sub--text">
          Your baby will get the BCG vaccination in the upper part of the left
          arm.
        </p>
        <p className="full__vac-heading-two">Possible side effect</p>
        <p className="full__vac-detail-sub--text">
          Immediately after the injection, a raised blister will appear. This
          shows that the injection has been given properly. Within two to six
          weeks of the injection a small spot will appear. This may be quite
          sore for a few days, but it should gradually heal if you don’t cover
          it. It may leave a small scar. Occasionally, your baby may develop a
          shallow sore where they had the injection. If this is oozing fluid and
          needs to be covered, use a dry dressing – never a plaster – until a
          scab forms. This sore may take as long as several months to heal.If
          you are worried or you think the sore has become infected, see your
          doctor.
        </p>
      </div>
      <div className="full__detail-sub--content">
        <div className="full__vac-img--container-mobile">
          <img src={vacImg} alt="" className="vac__img-mobile" />
        </div>
        <p className="full__detail-sub--content--heading">
          Frequently Asked Questions
        </p>
        <Accordion />
        <Accordion />
      </div>
    </div>
  );
};

export default FullVacDetail;
