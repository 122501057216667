import { useState, useMemo, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-dept-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/x-svg.svg";
import { useSelector } from "react-redux";
import axios from "axios";
import { api } from "../..";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import { toast } from "react-toastify";
import "./vacDisplay.css";

const VacDisplay = ({
  showVaccineList,
  setShowVaccineList,
  events,
  child_id,
  childDetails,
  setChildDetails,
  vaccinations,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [clicked, setClicked] = useState("");
  const [vacType, setVacType] = useState("");
  const [vacDate, setVacDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [today, setToday] = useState("");

  const baby = useSelector((state) => state.babies.baby);
  // const [stat, setStat] = useState("");

  useEffect(() => {
    var today = new Date();

    // Format today's date as YYYY-MM-DD
    var todayFormatted = today.toISOString().split("T")[0];

    setToday(todayFormatted);

    // const vacDateToCompare = "2024-02-28";

    // if (todayFormatted < vacDateToCompare) {
    //   console.log("its before today");
    // } else {
    //   console.log("its after today");
    // }
  }, []);

  const updateChildDetails = () => {
    api
      .get(`/api/child/${baby.id}`)
      .then((res) => {
        setChildDetails(res.data?.data);
        toast.success("Vaccine Updated Successfully!");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error("An Error Has Occured. Please Retry.");
      });
  };

  // const updateVacStatus = () => {
  //   setLoading(true);
  //   api
  //     .post("/api/vaccination_status", { child_id: baby.id }, config)
  //     .then((res) => {})
  //     .catch((error) => {
  //       console.log(error);
  //       setLoading(false);
  //       toast.error("Error processing request");
  //     });
  // };

  const updateVacStatus = (vacName, vacDate) => {
    setLoading(true);
    api
      .post("/api/vaccination", {
        vaccination_type: vacName,
        child_id: baby.id,
        date: vacDate,
      })
      .then(() => updateChildDetails())
      .catch((err) => {
        setLoading(false);
        toast.error("An error occured. Please try again.");
      });
  };

  // useEffect(() => {
  //   updateVacStatus();
  // }, [childDetails]);

  // if (!showOptions && vacType !== "") {
  //   api
  //     .post(
  //       "/api/vaccination",
  //       {
  //         vaccination_type: vacType,
  //         child_id: baby.id,
  //         date: vacDate,
  //       },
  //       config
  //     )
  //     .then((res) => {
  //       console.log(res);
  //       setVacType("");
  //       // updateVacStatus();
  //     })
  //     .then(() => updateChildDetails())
  //     .catch((err) => console.log(err));
  // }

  let stat;

  return (
    <div className="showVaccines__container">
      {loading ? (
        <div className="loader__container">
          <ClimbingBoxLoader color="#ffffff" loading={loading} size={15} />
        </div>
      ) : null}
      <div
        className="addUserForm__content--container"
        style={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "2rem 2rem",
        }}
      >
        <div className="listed__vaccines--container">
          <span className="close__icon--span">
            <CloseIcon
              onClick={() => setShowVaccineList(!showVaccineList)}
              style={{ cursor: "pointer" }}
            />
          </span>
          {vaccinations.map((vaccine, index) => (
            <div
              key={vaccine.id}
              id={vaccine.id}
              className="vaccine__tab"
              onClick={(e) => {
                setShowOptions(!showOptions);
                setClicked(index);
              }}
            >
              <span className="vac__name-and-update--CTA">
                <span className="vac__name-and-date">
                  <p className="vac__name--tag">{vaccine.vac_name}</p>
                  <p
                    className="vac__date--tag"
                    style={{
                      fontSize: "1.3rem",
                      fontWeight: "600",
                      color: "rgb(152, 152, 152)",
                    }}
                  >
                    {vaccine.date.substr(0, 10)}
                  </p>
                </span>
                <span className="edit__icon-and-vac__status">
                  <div
                    className="vac__status--tag"
                    style={
                      {
                        // backgroundColor: "#FFF9C6",
                        // backgroundColor: "#CCFFDD",
                        // color: "#EEAD06",
                        // color: "#0B7E09",
                        // padding: ".5rem 1rem",
                      }
                    }
                  >
                    <p
                      style={{
                        fontSize: "1rem",
                        fontWeight: "600",
                        padding: ".5rem 1rem",
                        borderRadius: "2rem",
                        backgroundColor:
                          vaccine.status === "completed"
                            ? "#CCFFDD"
                            : vaccine.status === "missed"
                            ? "#f3c8c8 "
                            : vaccine.status === "pending"
                            ? "#FFF9C6"
                            : null,
                        color:
                          vaccine.status === "completed"
                            ? "#0B7E09"
                            : vaccine.status === "missed"
                            ? "#e03535"
                            : "#EEAD06",
                      }}
                    >
                      {(stat = vaccine.status)}
                    </p>
                  </div>
                  <button
                    style={{
                      display: "flex",
                      alignItems: "center",
                      background: "#ffffff",
                      padding: ".5rem",
                      borderRadius: ".5rem",
                      position: "relative",
                      fontSize: "1.1rem",
                      fontWeight: "600",
                      color: "rgb(26, 115, 232)",
                    }}
                    onClick={() => setShowOptions(!showOptions)}
                    disabled={vaccine.date.substr(0, 10) > today ? true : false}
                    className="vac__update-CTA"
                  >
                    <EditIcon style={{ marginRight: ".5rem" }} />{" "}
                    {/* <p
                      style={{
                     
                      }}
                    > */}
                    Update
                    {/* </p> */}
                    {showOptions && clicked === index ? (
                      <div className="update__options">
                        <p
                          style={{
                            width: "100%",
                            background: "#f0f2f5",
                            marginBottom: ".5rem",
                            padding: ".5rem",
                            fontSize: "1.2rem",
                            color: "black",
                          }}
                          onClick={() => {
                            updateVacStatus(vaccine.vac_name, vaccine.date);
                          }}
                        >
                          Mark as Taken
                        </p>
                      </div>
                    ) : null}
                  </button>
                </span>
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VacDisplay;
