import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-dept-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/x-svg.svg";
import { toast } from "react-toastify";
import axios from "axios";

import { useEffect } from "react";
import "./complaintUpdate.css";
import { api } from "../..";
import { useSelector } from "react-redux";

const ComplaintUpdate = ({ setShowComplaintUpdate, child_id }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [complaint, setComplaint] = useState("");
  const [dateOfOnset, setDateOfOnSet] = useState("");
  const [vaccine, setVaccine] = useState("");
  const [status, setStatus] = useState("");

  const baby = useSelector((state) => state.babies.baby);

  const updateAefi = () => {
    api
      .post("/api/complaint", {
        child_id: child_id,
        complaint: complaint,
        vac_name: vaccine,
        date_of_onset: dateOfOnset,
        status: status,
      })
      .then((res) => {
        toast.success("Added Successfully");
        setComplaint("");
        setDateOfOnSet("");
        setVaccine("");
        setStatus("");
      })
      .catch((error) => {
        toast.error("Error processing request, please try again.");
        console.log(error);
      });
  };

  return (
    <div className="showVaccines__container">
      <div
        className="addUserForm__content--container"
        style={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "2rem 2rem",
        }}
      >
        <div
          className="listed__vaccines--container"
          style={{
            width: "40%",
            // height: "40rem",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: "column",
            padding: "2rem 3rem",
          }}
        >
          <span
            className="title-and-close--icon"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              marginBottom: "3rem",
            }}
          >
            <p
              className="title"
              style={{ fontWeight: "600", fontSize: "2rem" }}
            >
              Update AEFI
            </p>
            <CloseIcon
              onClick={() => setShowComplaintUpdate(false)}
              style={{ cursor: "pointer" }}
            />
          </span>
          <label className="aefi-label">Complaint</label>
          <input
            type="text"
            value={complaint}
            onChange={(e) => setComplaint(e.target.value)}
            className="aefi-input"
            required
          />
          <label className="aefi-label">Vaccine</label>

          <select
            // name="countries"
            id="aefi"
            className="aefi-input"
            value={vaccine}
            // required
            onChange={(e) => setVaccine(e.target.value)}
          >
            <option value=""></option>
            {baby.vac_date.map((vaccine) => (
              <option value={vaccine.vac_name}>{vaccine.vac_name}</option>
            ))}
          </select>
          <label className="aefi-label">Date Of Onset</label>
          <input
            type="date"
            value={dateOfOnset}
            onChange={(e) =>
              setDateOfOnSet(e.target.value.split("/").join("-"))
            }
            required
            className="aefi-input"
          />
          <label className="aefi-label">Status</label>

          <select
            // name="countries"
            id="aefi"
            className="aefi-input"
            value={status}
            required
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value=""></option>
            <option value="mild">Mild</option>
            <option value="moderate">Moderate</option>
            <option value="severe">Severe</option>
            <option value="critical">Critical</option>
          </select>

          <button className="submit-aefi--CTA" onClick={() => updateAefi()}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ComplaintUpdate;
