import { useState, useEffect, useMemo } from "react";
import { ReactComponent as RemoveIcon } from "../../assets/icons/remove-icon.svg";
import { ReactComponent as LeftIcon } from "../../assets/icons/left-icon.svg";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
// import Loading from "../Loading/Loading.component";
import { toast } from "react-toastify";
import "./personnelData.css";
import Table from "../Table/Table";
import { api } from "../..";

const PersonnelData = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [removeUser, setRemoveUser] = useState(false);

  const tableTitle = "Enrolled Children";

  const tableData = {
    headings: ["Name", "VAC Number", "DOB", "Action"],
    rows:
      user.child &&
      user?.child.map((child) => {
        return {
          dataOne: child.first_name + " " + child.last_name,
          dataTwo: child.child_vac_id,
          dataThree: child.date_of_birth,
          dataFour: "/panel/child-details/",
          dataFive: child.id,
        };
      }),
  };

  const toggleRemoveUser = () => {
    setRemoveUser(!removeUser);
  };

  const navigate = useNavigate();

  const deleteUser = () => {
    setLoading(true);
    api
      .delete(`/api/user/${user.id}`, config)
      .then((res) => {
        toast("User Removed");
        setLoading(false);
        setTimeout(() => {
          navigate(-1);
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toast.error("An error occured. Please retry.");
      });
  };

  const param = useParams();

  const userId = param.id;

  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  useEffect(() => {
    setLoading(true);
    api
      .get(`/api/user/${userId}`, config)
      .then((res) => {
        setUser(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const name = user.first_name + " " + user.last_name;

  const str = `${name?.toUpperCase()}`;
  const matches = str.match(/\b(\w)/g);
  const acronym = matches.join("");

  return (
    <div className="user__page--container">
      {/* {loading ? <Loading /> : null} */}

      <h1 className="user__page--heading hide__title">
        Healthcare Personnel's Details
      </h1>
      <LeftIcon className="show__arrow" onClick={() => navigate(-1)} />
      <div className="user__deatails--container">
        <div className="user__details--content">
          <div className="user__details--box">
            <span className="image__and__text--details-span">
              <span className="user__picture--container">
                <span className="profile__image--span">
                  {/* <img
                    src="https://upload.wikimedia.org/wikipedia/en/d/dc/MichaelScott.png"
                    alt=""
                    className="user__profile--picture"
                  /> */}
                  <span className="user__profile--image">{acronym}</span>
                </span>
              </span>
              <span className="user__text--details">
                {/* <h2 className="user__profile--name">{user.name}</h2> */}
                <h2 className="user__profile--name">
                  {user?.first_name} {user?.last_name}
                </h2>
                {/* <p className="email__label">Email</p> */}
                <p className="user__email--add">{user?.email}</p>
                {/* <p className="invitation__date">Invitation Date</p> */}
                <p className="invitation__date--num">{user?.createdAt}</p>
              </span>
            </span>
            <span
              className={
                user?.status === "pending"
                  ? "user__profile--status-pending"
                  : "user__profile--status-joined"
              }
            >
              {/* {user.status === "pending" ? "Invited" : "Joined"} */}
              {"Active"}
            </span>
          </div>
          <span className="remove__user--span">
            {removeUser ? (
              <div className="remove__prompt--con">
                <p className="remove__prompt--text">
                  Are you sure you want to
                  <br />
                  remove this user?
                </p>
                <span className="remove__action--CTAs">
                  <button
                    className="remove__action--CTA"
                    onClick={() => deleteUser()}
                  >
                    Yes
                  </button>
                  <button
                    className="cancel__delete-CTA"
                    onClick={() => setRemoveUser(!removeUser)}
                  >
                    Cancel
                  </button>
                </span>
              </div>
            ) : null}
            <button className="remove__user--CTA" onClick={toggleRemoveUser}>
              Remove Personnel{" "}
              <RemoveIcon
                style={{
                  marginLeft: "1rem",
                }}
              />
            </button>
          </span>
        </div>
      </div>
      <Table title={tableTitle} tableData={tableData} />
    </div>
  );
};

export default PersonnelData;
