import Highcharts from "highcharts";
import { api } from "../..";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter-icon.svg";
import { useEffect, useState } from "react";

const ZeroDoseGraph = () => {
  const [year, setYear] = useState("2024");
  const [yearlyData, setYearlyData] = useState();
  const [yearEntry, setYearEntry] = useState("");

  useEffect(() => {
    const getGraphData = () => {
      api
        .get(`/api/graph_zerodose?year=${year}`)
        .then((res) => {
          setYearlyData(res?.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getGraphData();
  }, [year]);

  const getFilteredData = (e) => {
    e.preventDefault();
    setYear(yearEntry);
  };

  useEffect(() => {
    if (yearlyData) {
      const options = {
        chart: {
          type: "column",
        },
        title: {
          text: yearlyData.title?.text,
          align: "center",
        },
        // subtitle: {
        //   text:
        //     'Source: <a target="_blank" ' +
        //     'href="https://www.indexmundi.com/agriculture/?commodity=corn">indexmundi</a>',
        //   align: "left",
        // },
        xAxis: {
          categories: [...yearlyData.xAxis?.categories],
          crosshair: true,
          accessibility: {
            description: "Facilities",
          },
        },
        yAxis: {
          min: 1,
          title: {
            text: "Population",
          },
        },
        tooltip: {
          // valueSuffix: " (Kg)",
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        series: yearlyData?.series?.map((serie, index) => ({
          name: serie.name,
          data: serie.data,
          color: index === 0 ? "#36a2eb" : "rgb(255, 205, 86)",
        })),
      };

      // Create the chart
      Highcharts.chart("chart-container-4", options);
    }
  }, [yearlyData]);

  return (
    yearlyData && (
      <>
        <form
          className="filter__param-span filter__param-span-2"
          //   style={{ zIndex: "30" }}
          onSubmit={getFilteredData}
        >
          <div className="input__span-onn">
            <label htmlFor="date" className="filter__param-label">
              Year
            </label>
            <input
              type="number"
              id="date"
              value={yearEntry}
              onChange={(e) => setYearEntry(e.target.value)}
              className="filter__param-input filter__param-input-2"
              placeholder={year}
            />
          </div>

          <button
            className="filter__CTA filter__CTA-2"
            type="submit"
            disabled={!yearEntry}
          >
            Filter
            <FilterIcon style={{ marginLeft: "1rem" }} />
          </button>
        </form>
        <div
          id="chart-container-4"
          style={{
            height: "400px",
            width: "100%",
            marginTop: "2rem",
            marginBottom: "8rem",
          }}
        ></div>
      </>
    )
  );
};

export default ZeroDoseGraph;
