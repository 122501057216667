import { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as VerticalMenuIcon } from "../../assets/icons/vertical-menu-icon.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/more-vertical.svg";
import { useDispatch, useSelector } from "react-redux";
import { storeBabiesDetails } from "../../redux/babies/babiesActions";
import { toast } from "react-toastify";
import { api } from "../..";

import "./children.css";

const TableR = ({ row, index }) => {
  const [viewActions, setViewActions] = useState(false);
  const [deleteChild, setDeleteChild] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const facility = useSelector((state) => state.facility.currentFacility);

  const getFacilityChildren = () => {
    api
      .get(`api/facility_children?facility_id=${facility.id}`)
      .then((res) => {
        dispatch(storeBabiesDetails(res.data.data.data));
      })
      .catch((err) => console.log(err));
  };

  const deleteChildFromApp = (id) => {
    setLoading(true);
    api
      .delete(`/api/child/${id}`)
      .then((res) => {
        getFacilityChildren();
        setLoading(false);
        toast.success("Child Deleted.");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error("Error Processing Request. Try again.");
      });
  };

  return (
    <tr className="children__table--row">
      <td>{index + 1}</td>
      <td>{row.dataOne}</td>
      <td>{row.dataTwo}</td>
      <td>{row.dataThree}</td>
      {row.dataSix ? <td>{row.dataSix}</td> : null}

      {row.dataFour ? (
        <td
          className="action__tD"
          onClick={() => {
            setViewActions(!viewActions);
          }}
        >
          {deleteChild ? (
            <div
              className="remove__prompt--con"
              style={{
                position: "absolute",
                top: "-3rem",
                left: "-5rem",
              }}
            >
              <p className="remove__prompt--text">
                Are you sure you want to
                <br />
                delete this child?
              </p>
              <span className="remove__action--CTAs">
                <button
                  className="remove__action--CTA"
                  onClick={() => {
                    deleteChildFromApp(row.dataFive);
                    setDeleteChild(!deleteChild);
                  }}
                >
                  Yes
                </button>
                <button
                  className="cancel__delete-CTA"
                  onClick={() => {
                    setDeleteChild(!deleteChild);
                    setViewActions(false);
                  }}
                >
                  Cancel
                </button>
              </span>
            </div>
          ) : null}
          <MenuIcon style={{ fill: "rgb(123, 128, 154)", cursor: "pointer" }} />
          {/* <Link to={`${row.dataFour}${row.dataFive}`}> */}
          {viewActions ? (
            <span className="actions__span">
              <span
                className="view-and-logo"
                style={{
                  display: "flex",
                  // borderBottom: "1px solid gray",
                  width: "100%",
                  paddingBottom: ".5rem",
                  cursor: "pointer",
                  flexDirection: "column",
                  position: "relative",
                  backgroundImage: "#f0f2f",
                }}
              >
                {/* <EyeIcon style={{ width: "2.5rem", height: "2.5rem" }} />{" "} */}
                <Link to={`${row.dataFour}${row.dataFive}`}>
                  <p
                    className="action__option"
                    // style={{ marginBottom: "1.5rem" }}
                  >
                    View
                  </p>
                </Link>
              </span>
              <span
                className="view-and-logo"
                style={{
                  display: "flex",
                  paddingTop: ".5rem",
                  width: "100%",
                  cursor: "pointer",
                  backgroundImage: "red",
                }}
                onClick={() => {
                  setDeleteChild(true);
                  setViewActions(false);
                }}
              >
                {/* <TrashIcon style={{ width: "2.5rem", height: "2.5rem" }} />{" "} */}
                <p>Delete</p>
              </span>
            </span>
          ) : null}
          {/* </Link>{" "} */}
        </td>
      ) : null}
    </tr>
  );
};

export default TableR;
