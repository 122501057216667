import { useState } from "react";
import HealthTips from "../HealthTips/HealthTips";
import VaccinationTips from "../VaccinationTips/VaccinationTips";
import "./vacsAndHealthTips.css";
import "../HealthTips/healthTips.css";

const Vacandhealthtips = () => {
  const [switchTips, setSwitchTips] = useState(true);

  return (
    <div className="vacs-and-healthtips__container">
      <p className="vacs-and-healthtips__headinng">
        Vaccination and Health tips
      </p>

      <div className="tip__content-switch--container">
        <button
          className={
            switchTips
              ? "tip__switch-CTA--inactive tip__switch-CTA--active"
              : "tip__switch-CTA--inactive"
          }
          onClick={() => setSwitchTips(!switchTips)}
        >
          Vaccination
        </button>
        <button
          className={
            !switchTips
              ? "tip__switch-CTA--inactive tip__switch-CTA--active"
              : "tip__switch-CTA--inactive"
          }
          onClick={() => setSwitchTips(!switchTips)}
        >
          Health tips
        </button>
      </div>
      {switchTips ? <VaccinationTips /> : <HealthTips />}
    </div>
  );
};

export default Vacandhealthtips;
