const VaccinationTips = () => {
  return (
    <div className="vaccination__tips-container">
      <div className="vac__defination-boxes--container">
        <div className="vaccine__defination-box">
          <p className="vaccination__defination-heading--text">
            What is a Vaccine?
          </p>
          <p className="vaccination__defination-body--text">
            Vaccination is a treatment with a vaccine to produce immunity to a
            particular infectious disease or pathogen.
          </p>
        </div>
        <div className="vaccination__defination-box">
          <p className="vaccination__defination-heading--text">
            What is a Vaccine?
          </p>
          <p className="vaccination__defination-body--text">
            Vaccination is a treatment with a vaccine to produce immunity to a
            particular infectious disease or pathogen.
          </p>
        </div>
      </div>
      <div className="common__vacs-container">
        <p className="common__vacs-heading">Most Common Vaccines</p>
        <div className="common__vacs-line" />
        <div className="common__vacs-boxes">
          <div className="common__vaccine-box">
            <p className="common__vaccine-name">
              BCG [Bacille Calmetter Guerin]
            </p>
            <p className="common__vaccine-desc">
              This vaccine can prevent most cases of cervical cancer. This
              vaccine can also prevent vaginal and vulvar cancer
            </p>
          </div>
          <div className="common__vaccine-box">
            <p className="common__vaccine-name">
              BCG [Bacille Calmetter Guerin]
            </p>
            <p className="common__vaccine-desc">
              It is given to newborn babies at risk of getting tuberculosis
            </p>
          </div>
          <div className="common__vaccine-box">
            <p className="common__vaccine-name">
              BCG [Bacille Calmetter Guerin]
            </p>
            <p className="common__vaccine-desc">
              It is given to newborn babies at risk of getting tuberculosis
            </p>
          </div>
          <div className="common__vaccine-box">
            <p className="common__vaccine-name">
              BCG [Bacille Calmetter Guerin]
            </p>
            <p className="common__vaccine-desc">
              It is given to newborn babies at risk of getting tuberculosis
            </p>
          </div>
          <div className="common__vaccine-box">
            <p className="common__vaccine-name">
              BCG [Bacille Calmetter Guerin]
            </p>
            <p className="common__vaccine-desc">
              It is given to newborn babies at risk of getting tuberculosis
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VaccinationTips;
