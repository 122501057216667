import { useState } from "react";
import { toast } from "react-toastify";
import { ReactComponent as XIcon } from "../../assets/icons/close-icon.svg";
import axios from "axios";
import { useEffect } from "react";
import { api } from "../..";

const UpdateRate = ({
  showUpdateExchageRate,
  setShowUpdateExchageRate,
  oldRate,
}) => {
  const [rate, setRate] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  const UpdateRate = () => {
    api
      .post("/api/exchange_rate/1", { rate })
      .then((res) => {
        // setRate("");
        toast.success("Rate Updated Successfully!");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error Processing Request, please try again");
      });
  };

  useEffect(() => {
    setRate(oldRate);
  }, []);

  return (
    <div className="addChild__container">
      <div
        className="addUserForm__content--container"
        style={{ height: "100%", width: "100vw" }}
      >
        <div className="addState__form">
          <span className="form__heading-and-close--CTA">
            <p className="addUserForm__formheading">Update Rate</p>
            <XIcon
              style={{ fill: "#F95959", cursor: "pointer" }}
              onClick={() => setShowUpdateExchageRate(!showUpdateExchageRate)}
            />
          </span>
          <label htmlFor="state" className="addUserForm__password--label">
            New Rate
          </label>
          <div className="addUserForm__password-and-icons">
            <input
              type="text"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              id="state"
              autoComplete="off"
              onChange={(e) => setRate(e.target.value)}
              value={rate}
              style={{
                width: "100%",
                marginTop: "1.5rem",
                marginBottom: "1.5rem",
              }}
              required
              aria-describedby="uidnote"
            />
            {errorMessage && (
              <p
                style={{
                  color: "#F95959",
                  marginTop: "1rem",
                }}
              >
                Rate is Required
              </p>
            )}
          </div>
          <button
            className="addUserForm__submit--CTA"
            onClick={() => UpdateRate()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateRate;
