import { currentUserActionTypes } from "./currentUser.actionTypes";

export const storeUserDetails = (data) => ({
  type: currentUserActionTypes.STORE_USER_DETAILS,
  payload: data,
});

export const storeFacilityWorkerDetails = (data) => ({
  type: currentUserActionTypes.STORE_FACILITY_WORKER_DETAILS,
  payload: data,
});
