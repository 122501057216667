import { useEffect } from "react";
import Highcharts, { color } from "highcharts";
import drilldown from "highcharts/modules/drilldown";

const DrilldownGraph = ({ drillDownData, title }) => {
  drilldown(Highcharts);

  useEffect(() => {
    if (drillDownData) {
      const seriesData = drillDownData?.series.map((item) => ({
        name: item.name,
        y: item.y,
        drilldown: item.drilldown,
        color: "rgb(102, 187, 106)",
      }));

      const drilldownSeries = drillDownData?.drilldown?.series.map((item) => ({
        name: item.name,
        id: item.id,
        data: item.data,
        color: "rgb(255, 99, 132)",
      }));

      const options = {
        chart: {
          type: "column",
        },
        // colors: ["#99CC33", "#66CCFF", "#99CC33"],
        title: {
          text: drillDownData.title.text,
        },
        xAxis: {
          type: "category",
        },

        legend: {
          enabled: false,
        },

        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
            },
          },
        },

        series: [
          {
            name: "Facilities",
            colorByPoint: true,
            data: seriesData,
          },
        ],
        drilldown: {
          series: drilldownSeries,
        },
      };

      // Create the chart
      Highcharts.chart("chart-container", options);
    }
  }, [drillDownData]); // Empty dependency array ensures this effect runs once on mount
  return (
    <div
      id="chart-container"
      style={{ height: "400px", width: "100%", marginBottom: "10rem" }}
    ></div>
  );
};

export default DrilldownGraph;
