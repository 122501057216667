import Highcharts from "highcharts";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const BabyHeightChart = () => {
  const baby = useSelector((state) => state.babies.baby);

  const [heightData, setHeightData] = useState([]);

  useEffect(() => {
    const babyHeightData = baby?.child_height.map((height) => height.height);

    setHeightData(babyHeightData);
  }, [baby]);

  useEffect(() => {
    if (heightData) {
      const options = {
        chart: {
          type: "column",
        },
        title: {
          text: "Child's Height vs Ideal Height Per Age",
          align: "center",
        },
        // subtitle: {
        //   text:
        //     'Source: <a target="_blank" ' +
        //     'href="https://www.indexmundi.com/agriculture/?commodity=corn">indexmundi</a>',
        //   align: "left",
        // },
        xAxis: {
          categories: [
            "Birth",
            "2w",
            "6w",
            "1m",
            "2m",
            "5m",
            "1yr",
            "1yr+",
            "2yr",
            "3yr",
            "5yr ",
          ],
          crosshair: true,
          accessibility: {
            description: "Stages",
          },
        },
        yAxis: {
          min: 1,
          title: {
            text: "Height In Feet",
          },
        },
        tooltip: {
          valueSuffix: " (ft)",
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        series: [
          {
            name: "Ideal Height",
            data: [2, 2.3, 3.2, 4, 6, 7, 9, 12, 14, 17, 20],
          },
          {
            name: "Baby's Height",
            data: [...heightData],
            color: "rgb(255 205 86)",
          },
        ],
      };

      Highcharts.chart("height-chart-container", options);
    }
  }, [baby, heightData]);

  return (
    heightData && (
      <div id="height-chart-container" style={{ height: "400px" }}></div>
    )
  );
};

export default BabyHeightChart;
