import { useState, useEffect, useMemo } from "react";
// import { Table } from "@fullcalendar/daygrid";
import { Link } from "react-router-dom";
import { ReactComponent as VerticalMenuIcon } from "../../assets/icons/vertical-menu-icon.svg";
import axios from "axios";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter-icon.svg";
import { ReactComponent as LeftArrow } from "../../assets/icons/left-icon-two.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/right-icon.svg";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import "./employees.css";
import TableR from "../Children/TableR";
import { api } from "../..";

const Employees = () => {
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState();
  const [facility, setFacility] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [workers, setWorkers] = useState([]);
  const [viewActions, setViewActions] = useState(false);

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  useEffect(() => {
    getUserData();
    // if (userData) {
    //   getUserFacility();
    // }
  }, []);

  useEffect(() => {
    getWorkers();
  }, []);

  const getUserData = () => {
    api
      .get(`/api/user/${userDetails?.id}`, config)
      .then((res) => {
        setUserData(res?.data);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };

  const getWorkers = () => {
    setLoading(true);

    api
      .get(
        `http://localhost:8000/api/facility_imworkers?page=${pageNumber}`,
        config
      )
      .then((res) => {
        setWorkers(res?.data?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const immunizationWorkers =
    facility &&
    facility.data?.user?.filter((user) => user.user_type_id === "3");

  const tableDataOne = {
    headings: ["#", "Name", "Phone Number", "Email", "Action"],
    rows: workers?.map((user) => {
      // if (user.user_type_id === "3") {
      return {
        dataOne: user.first_name + " " + user.last_name,
        dataTwo: user.phone_number,
        dataThree: user.email,
        dataFour: "/panel/personnel-data/",
        dataFive: user.id,
      };
      // } else {
      //   return "";
      // }
    }),
  };
  const dataToExport = {
    // headings: ["Name", "Phone Number", "Email", "Action"],
    rows: immunizationWorkers?.map((user, index) => {
      // if (user.user_type_id === "3") {
      return {
        SN: index + 1,
        Name: user.first_name + " " + user.last_name,
        Phone: user.phone_number,
        Email: user.email,
      };
      // } else {
      //   return "";
      // }
    }),
  };

  const filterChildrenData = () => {
    setLoading(true);
    api
      .get(
        `/api/filter_imworkers?data=${phoneNumber}&page=${pageNumber}`,
        config
      )
      .then((res) => {
        toast.success("Filtered Successfully!");
        setWorkers(res?.data?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("An Error Occured. Please retry");
      });
  };

  return (
    <div className="employee__management">
      {loading ? (
        <div className="loader__container">
          <ClimbingBoxLoader color="#ffffff" loading={loading} size={15} />
        </div>
      ) : null}
      <p className="employees__heading">Employees</p>
      <div className="filter__params-container">
        {/* <span className="filter__param-span">
          <label htmlFor="first__name" className="filter__param-label">
            First Name
          </label>
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="filter__param-input"
          />
        </span> */}
        {/* <span className="filter__param-span">
          <label htmlFor="last__name" className="filter__param-label">
            Last Name
            </label>
            <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="filter__param-input"
            />
          </span> */}
        <span className="filter__param-span">
          <label htmlFor="phoneNumber" className="filter__param-label">
            Phone Number
          </label>
          <input
            type="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="filter__param-input"
          />
        </span>
        <span className="filter__CTA-span">
          <button
            className="filter__CTA"
            onClick={() => filterChildrenData()}
            disabled={!phoneNumber ? true : false}
          >
            Filter
            <FilterIcon style={{ marginLeft: "1rem" }} />
          </button>
        </span>
        {/* <span className="filter__param-span">
          <label htmlFor="immunization__worker" className="filter__param-label">
            Immunization Worker
          </label>
          <input type="text" className="filter__param-input" />
        </span> */}
      </div>

      <span className="title-and-export--CTA">
        {workers && (
          <CSVLink
            data={workers}
            filename="Employee Data"
            className="data__export-CTA"
            // style={{ background: "red" }}
          >
            Export Employee Data
          </CSVLink>
        )}
      </span>

      <div className="employees__container">
        <table className="employees__table">
          <tr>
            {tableDataOne?.headings?.map((heading) => {
              return <th>{heading}</th>;
            })}
          </tr>
          {tableDataOne?.rows?.map((row, index) => {
            return <TableR row={row} index={index} />;
          })}
        </table>

        {/* <Table
          title="Employees"
          tableData={tableDataOne}
          buttonTitle={"Add Immunization Worker"}
          buttonUrl="/panel/register-admin"
          marginTop="6rem"
        /> */}
        <div className="pagination__controls-box">
          <span className="pagination__controls">
            <p
              className="pagination__CTA"
              onClick={() => setPageNumber(pageNumber - 1)}
              style={{
                pointerEvents: pageNumber < 2 ? "none" : "auto",
                color: pageNumber < 2 ? "#dedede" : "black",
              }}
            >
              Previous
            </p>
            <LeftArrow
              onClick={() => setPageNumber(pageNumber - 1)}
              style={{ margin: "0 1rem", cursor: "pointer" }}
            />
            <RightArrow
              onClick={() => setPageNumber(pageNumber + 1)}
              style={{ margin: "0 1rem", cursor: "pointer" }}
            />
            <p
              className="pagination__CTA"
              onClick={() => setPageNumber(pageNumber + 1)}
              style={{
                pointerEvents: workers.length < 15 ? "none" : "auto",
                color: workers.length < 15 ? "#dedede" : "black",
              }}
            >
              Next
            </p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Employees;
