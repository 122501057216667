import { useState, useMemo } from "react";
import axios from "axios";
import { api } from "../..";
import { toast } from "react-toastify";
import { ReactComponent as XIcon } from "../../assets/icons/close-icon.svg";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";

const AddWard = ({ showAddWard, setShowAddWard }) => {
  const [wardName, setWardName] = useState("");
  const [states, setStates] = useState([]);
  const [lgas, setLgas] = useState();
  const [stateId, setStateId] = useState("");
  const [lgaId, setLgaId] = useState("");
  const [agencyId, setAgencyId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    api.get("/api/states").then((res) => {
      setStates(res.data);
    });
  }, []);

  useEffect(() => {
    api.get(`/api/lga/${stateId}`).then((res) => {
      setLgas(res.data.data);
    });
  }, [stateId]);

  const handleSubmit = () => {
    api
      .post("/api/ward", {
        lga_id: lgaId,
        name: wardName,
      })
      .then((res) => {
        toast.success("Ward Added Successfully");
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };

  return (
    <div className="addChild__container">
      {loading ? (
        <div className="loader__container">
          <ClimbingBoxLoader color="#ffffff" loading={loading} size={15} />
        </div>
      ) : null}
      <div
        className="addUserForm__content--container"
        style={{ height: "100%", width: "100vw" }}
      >
        <div className="addState__form">
          <span className="form__heading-and-close--CTA">
            <p className="addUserForm__formheading">Add Ward</p>
            <XIcon
              style={{ fill: "#F95959", cursor: "pointer" }}
              onClick={() => setShowAddWard(false)}
            />
          </span>
          <label
            htmlFor="facilityName"
            className="addUserForm__password--label"
          >
            Ward Name
          </label>
          <div className="addUserForm__password-and-icons">
            <input
              type="text"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              id="state"
              autoComplete="off"
              onChange={(e) => setWardName(e.target.value)}
              value={wardName}
              style={{
                width: "100%",
                marginTop: "1.5rem",
                marginBottom: "1.5rem",
              }}
              required
              aria-describedby="uidnote"
            />
            {errorMessage && (
              <p
                style={{
                  color: "#F95959",
                  marginTop: "1rem",
                }}
              >
                Facility's Name is Required
              </p>
            )}
          </div>
          <label htmlFor=" gender" className="addUserForm__password--label">
            State
          </label>
          <div className="addUserForm__password-and-icons">
            <select
              // name="countries"
              id="state"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              value={stateId}
              required
              onChange={(e) => setStateId(e.target.value)}
              style={{
                width: "100%",
                marginTop: "1.5rem",
                marginBottom: "1.5rem",
              }}

              // {...register("country", { required: true })}
            >
              <option value=""></option>
              {states?.map((state) => {
                return <option value={state.id}>{state.name} </option>;
              })}
            </select>
            {errorMessage && (
              <p
                style={{
                  color: "#F95959",
                  marginTop: "1rem",
                }}
              >
                State is required
              </p>
            )}
            {/* <NameIcon className="mail__icon" /> */}
          </div>
          <label htmlFor=" gender" className="addUserForm__password--label">
            LGA
          </label>
          <div className="addUserForm__password-and-icons">
            <select
              // name="countries"
              id="Lga"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              value={lgaId}
              required
              onChange={(e) => setLgaId(e.target.value)}
              style={{
                width: "100%",
                marginTop: "1.5rem",
                marginBottom: "1.5rem",
              }}
              //   onFocus={() => setGenderFocus(true)}
            >
              <option value=""></option>
              {lgas?.map((lga) => {
                return <option value={lga.id}>{lga.name}</option>;
              })}
            </select>
            {errorMessage && (
              <p
                style={{
                  color: "#F95959",
                  marginTop: "1rem",
                }}
              >
                LGA is required
              </p>
            )}
            {/* <NameIcon className="mail__icon" /> */}
          </div>
          {/* <label htmlFor=" gender" className="addUserForm__password--label">
            Agency
          </label>
          <div className="addUserForm__password-and-icons">
            <select
              // name="countries"
              id="agency"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              value={agencyId}
              required
              onChange={(e) => setAgencyId(e.target.value)}
              style={{
                width: "100%",
                marginTop: "1.5rem",
                marginBottom: "1.5rem",
              }}
          
            >
              <option value=""></option>
              {agencies?.map((agency) => {
                return <option value={agency.id}>{agency.name}</option>;
              })}
            </select>
            {errorMessage && (
              <p
                style={{
                  color: "#F95959",
                  marginTop: "1rem",
                }}
              >
                Agency is required
              </p>
            )}
          
          </div> */}

          <button
            className="addUserForm__submit--CTA"
            onClick={() => handleSubmit()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddWard;
