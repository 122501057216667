import { useState, useMemo } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { ReactComponent as XIcon } from "../../assets/icons/close-icon.svg";
import { api } from "../..";

const AddState = ({ showAddState, setShowAddState }) => {
  const [stateName, setStateName] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  const addState = () => {
    api
      .post("/api/states", { name: stateName }, config)
      .then((res) => {
        setStateName("");
        toast.success("State Added Successfully!");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error Processing Request, please try again");
      });
  };

  return (
    <div className="addChild__container">
      <div
        className="addUserForm__content--container"
        style={{ height: "100%", width: "100vw" }}
      >
        <div className="addState__form">
          <span className="form__heading-and-close--CTA">
            <p className="addUserForm__formheading">Add State</p>
            <XIcon
              style={{ fill: "#F95959", cursor: "pointer" }}
              onClick={() => setShowAddState(!showAddState)}
            />
          </span>
          <label htmlFor="state" className="addUserForm__password--label">
            State Name
          </label>
          <div className="addUserForm__password-and-icons">
            <input
              type="text"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              id="state"
              autoComplete="off"
              onChange={(e) => setStateName(e.target.value)}
              value={stateName}
              style={{ width: "100%", marginTop: "1.5rem" }}
              required
              aria-describedby="uidnote"
            />
            {errorMessage && (
              <p
                style={{
                  color: "#F95959",
                  marginTop: "1rem",
                }}
              >
                State's Name is Required
              </p>
            )}
          </div>
          <button
            className="addUserForm__submit--CTA"
            onClick={() => addState()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddState;
