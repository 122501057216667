import { combineReducers } from "redux";
import babiesReducer from "./babies/babiesReducer";
import currentUserReducer from "./currentUser/currentUser.reducer";
import facilityReducer from "./currentFacility/currentFacilityReducer";
import agencyReducer from "./currentAgency/currentAgencyReducer";

export default combineReducers({
  babies: babiesReducer,
  currentUser: currentUserReducer,
  facility: facilityReducer,
  agency: agencyReducer,
});
