import { useState } from "react";
import { ReactComponent as BellIcon } from "../../assets/icons/notification-bell-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import "./notification.css";
import { useSelector } from "react-redux";

const Notification = () => {
  const [showNotifications, setShowNotifications] = useState(false);

  const userNotifications = useSelector(
    (state) => state.currentUser.currentUser.notification
  );

  return (
    <div className="notification__container">
      {/* {showNotifications ? null : ( */}
      <BellIcon
        onClick={() => setShowNotifications(!showNotifications)}
        className="notification__bell-icon"
      />
      {/* )} */}

      {showNotifications ? (
        <div className="notification__content">
          <div className="notification-and-markall--CTA">
            <p className="notifications__heading-text">Notifications</p>
            {/* <p className="mark__as-read--text">Mark as read</p> */}
            <CloseIcon
              onClick={() => setShowNotifications(!showNotifications)}
              style={{ cursor: "pointer" }}
            />
          </div>
          <p className="notification__descptn-text"></p>
          {userNotifications ? (
            <div className="notification__tabs">
              {userNotifications.map((notification) => (
                <div className="notification__tab">
                  <div className="notification__check-box"></div>
                  <div className="notification__main-texts">
                    <p className="notification__main-text">
                      Vaccination Notification
                    </p>
                    <p className="notification__sub-text">{notification}</p>
                  </div>
                  <p className="notification__status-text">New</p>
                </div>
              ))}
            </div>
          ) : (
            <div className="notification__tab">
              <div className="notification__main-texts">
                <p className="notification__main-text">No Notifications</p>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default Notification;
