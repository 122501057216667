import { Link, NavLink, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContext";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout-red-icon.svg";
import { v4 as uuidv4 } from "uuid";

import "./sideBar.css";

const SideBar = ({
  superAdminSideBarLinks,
  adminSideBarLinks,
  facilityHealthWorkerSideBarLinks,
  imWorkerSideBarLinks,
  parentSideBarLinks,
}) => {
  const { authDetails } = useAuthContext();
  const navigate = useNavigate();

  let sideBarLinks;

  // switch (authDetails?.user.user_type) {
  //   case "admin":
  //     sideBarLinks = adminSideBarLinks;
  //     break;
  //   case "parent":
  //     sideBarLinks = parentSideBarLinks;
  //     break;
  //   case "user":
  //     sideBarLinks = personnelSideBarLinks;
  //     break;
  //   default:
  //     return;
  // }

  if (localStorage.getItem("user_type_id") === "1") {
    sideBarLinks = superAdminSideBarLinks;
  } else if (localStorage.getItem("user_type_id") === "2") {
    sideBarLinks = adminSideBarLinks;
  } else if (localStorage.getItem("user_type_id") === "3") {
    sideBarLinks = facilityHealthWorkerSideBarLinks;
  } else if (localStorage.getItem("user_type_id") === "4") {
    sideBarLinks = imWorkerSideBarLinks;
  } else {
    sideBarLinks = parentSideBarLinks;
  }

  return (
    <div className="sidebar__container">
      {/* <span className="dashboard__fezzant--logo-span hidden">
        <img
          src={require("../../assets/images/imtrackerlogo.png")}
          alt="imtracker__logo"
          className="login__imt--logo"
        />
      </span> */}

      <div className="sidebar__content--container hidden">
        {/* <div className="sidebar__inner--content"> */}
        <ul className="side__nav--links">
          {sideBarLinks.map((link) => {
            return (
              <NavLink
                key={link.urlLink}
                to={`${link.urlLink}`}
                className={({ isActive }) =>
                  isActive ? "active" : "link__and__icon"
                }
                // key={id++}
              >
                <span className="colored">
                  {link.urlIcon} <li className="link">{link.urlName}</li>
                </span>

                <span className="notColored">
                  {link.urlIconTwo} <li className="link">{link.urlName}</li>{" "}
                </span>
              </NavLink>
            );
          })}
        </ul>
      </div>
      {/* <Link to="/"> */}
      <span
        className="link__and__icon logout__CTA"
        onClick={() => {
          localStorage.clear();
          navigate("/");
        }}
      >
        <LogoutIcon />
        <p
          style={{
            marginLeft: "1rem",
            color: "#ffffff",
            fontSize: "1.2rem",
          }}
        >
          Log out
        </p>
      </span>
      {/* </Link> */}
      {/* </div> */}
      {/* <div className="mobile__nav-menu">
        <ul className="mobile__menu-nav--links">
          {sideBarLinks.map((link) => {
            return (
              <NavLink
                to={`${link.urlLink}`}
                className={({ isActive }) =>
                  isActive ? "active" : "link__and__icon"
                }
                // key={id++}
              >
                <span className="colored">
                  {link.urlIcon} <li className="link">{link.urlName}</li>
                </span>

                <span className="notColored">
                  {link.urlIconTwo} <li className="link">{link.urlName}</li>{" "}
                </span>
              </NavLink>
            );
          })}
        </ul>
      </div> */}
      <div className="mobile__nav">
        <span className="nav-links">
          <ul className="links">
            {sideBarLinks.map((link) => {
              return (
                <NavLink
                  key={link.urlLink}
                  to={`${link.urlLink}`}
                  className={({ isActive }) =>
                    isActive ? "active" : "link__and__icon"
                  }
                  // key={id++}
                >
                  <span className="colored">
                    <span>{link.urlIcon}</span>{" "}
                    <li className="link">{link.urlName}</li>
                  </span>

                  <span className="notColored">
                    {link.urlIconTwo} <li className="link">{link.urlName}</li>{" "}
                  </span>
                </NavLink>
              );
            })}
          </ul>
        </span>
      </div>
    </div>
  );
};

export default SideBar;
