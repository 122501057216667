import { useState, useMemo, useCallback, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { ReactComponent as XIcon } from "../../assets/icons/close-icon.svg";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import "./snapPhoto.css";
import { api } from "../..";
import { useDispatch, useSelector } from "react-redux";
import { storeFacilityDetails } from "../../redux/currentFacility/currentFacilityActions";

const SnapPhoto = ({ childDetails, setShowSnapPhoto }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [capturedImage, setCapturedImage] = useState();

  const webcamRef = useRef(null);

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.currentUser.currentUser);
  const facility = useSelector((state) => state.facility.currentFacility);

  const navigate = useNavigate();

  const videoConstraints = {
    width: 200,
    height: 200,
    facingMode: "user",
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();

    setCapturedImage(imageSrc);
  }, [webcamRef]);

  const navigateToChildAfterReg = () => {
    navigate(`/panel/child-details/${childDetails.id}`);
  };

  const navigateOnFormClose = () => {
    navigate(`/panel/child-details/${childDetails.id}`);
    setShowSnapPhoto(false);
  };

  const getUserFacility = () => {
    setLoading(true);
    api
      .get(`api/facility/${facility?.id}`)
      .then((res) => {
        dispatch(storeFacilityDetails(res.data.facility));
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const uploadPhoto = () => {
    // helper function: generate a new file from base64 String
    const dataURLtoFile = (dataurl, filename) => {
      const arr = dataurl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1);
        n -= 1; // to make eslint happy
      }
      return new File([u8arr], filename, { type: mime });
    };

    // generate file from base64 string
    const file = dataURLtoFile(capturedImage, "picture.png");

    // put file into form data
    const data = new FormData();

    data.append("child_id", childDetails.id);
    data.append("picture", file, file.name);

    setLoading(true);

    // const formData = new FormData();
    // formData.append("child_id", childDetails.id);
    // formData.append("picture", capturedImage);
    // console.log(capturedImage);

    api
      .post("api/child_picture", data)
      .then((res) => {
        toast.success("Profile picture updated successfully");
        setLoading(false);
        getUserFacility();
        navigateToChildAfterReg();
        // setTimeout(() => {
        // }, 1000);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    // fetch(capturedImage)
    //   .then((res) => res.blob())
    //   .then(console.log);
  };

  return (
    <div className="addChild__container" style={{ position: "fixed" }}>
      {loading ? (
        <div
          className="loader__container"
          style={{ height: "100vh", position: "fixed" }}
        >
          <ClimbingBoxLoader color="#ffffff" loading={loading} size={15} />
        </div>
      ) : null}
      <div
        className="addUserForm__content--container"
        style={{ minHeight: "100%", width: "100vw" }}
      >
        {/* <div className="addState__form"> */}
        <div
          className="addState__form"
          //   style={{ height: "auto", width: "30rem" }}
        >
          <span className="form__heading-and-close--CTA">
            <p className="addUserForm__formheading">Take Photo</p>
            <XIcon
              style={{ fill: "#F95959", cursor: "pointer" }}
              onClick={() => navigateOnFormClose()}
            />
          </span>
          {/* <label htmlFor="state" className="addUserForm__password--label">
            Select File
          </label> */}
          <div className="camera__frame-container">
            {capturedImage ? (
              <div className="captured__image-container">
                <img src={capturedImage} alt="" className="captured__image" />
              </div>
            ) : (
              <Webcam
                audio={false}
                height={300}
                ref={webcamRef}
                imageSmoothing={true}
                screenshotFormat="image/png"
                width={300}
                videoConstraints={videoConstraints}
              />
            )}

            {capturedImage ? (
              <button
                className="addUserForm__submit--CTA"
                style={{ width: "100%", marginTop: "2rem" }}
                onClick={() => {
                  // const imageSrc = getScreenshot();
                  setCapturedImage("");
                }}
              >
                Retake Photo
              </button>
            ) : (
              <button
                className="addUserForm__submit--CTA"
                style={{ width: "100%", marginTop: "2rem" }}
                onClick={() => {
                  // const imageSrc = getScreenshot();
                  capture();
                }}
              >
                Take Photo
              </button>
            )}
            <button
              className="addUserForm__submit--CTA"
              style={{ width: "100%", marginTop: "2rem" }}
              onClick={() => uploadPhoto()}
              //   disabled={!fileName || !selectedFile ? true : false}
            >
              Upload
            </button>
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default SnapPhoto;
