import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import FadeLoader from "react-spinners/FadeLoader";
import { ReactComponent as CloseIcon } from "../../assets/icons/x-svg.svg";
import "./updateQuestionnaire.css";
import { api } from "../..";

const UpdateQuestionnaire = ({ child_id, setShowUpdateQuestionnaire }) => {
  const [loading, setLoading] = useState(false);
  //   const [complaint, setComplaint] = useState("");
  const [dateOfOnset, setDateOfOnSet] = useState("");
  const [status, setStatus] = useState("");
  const [survivingChildren, setSurvivingChildren] = useState("");
  const [deadChildren, setDeadChildren] = useState("");
  const [childWeight, setChildWeight] = useState("");
  const [childWeightLessThanTwo, setChildWeightLessThanTwo] = useState("");
  const [babyTwin, setBabyTwin] = useState("");
  const [bottleFed, setBottleFed] = useState("");
  const [motherNeedSupport, setMotherNeedSupport] = useState("");
  const [siblingUnderweight, setSiblingsUnderweight] = useState("");
  const [otherReasons, setOtherReasons] = useState("");

  const uppdateChildQuestionnaire = () => {
    api
      .post("/api/questionaire", {
        child_id: child_id,
        no_surviving_childern: survivingChildren,
        no_dead_children: deadChildren,
        child_weight_at_birth: childWeight,
        child_weighed_lessthan_2: childWeightLessThanTwo,
        baby_twin: babyTwin,
        bottle_feed: bottleFed,
        mother_need_family_support: motherNeedSupport,
        siblings_underweight: siblingUnderweight,
        other_reasons: otherReasons,
      })
      .then((res) => {
        toast.success("Questionnnaire Updated Successfully");
        setSurvivingChildren("");
        setDeadChildren("");
        setChildWeight("");
        setChildWeightLessThanTwo("");
        setBabyTwin("");
        setBottleFed("");
        setMotherNeedSupport("");
        setSiblingsUnderweight("");
        setOtherReasons("");
      })
      .catch((error) => {
        toast.error("Error processing request, please try again.");
        console.log(error);
      });
  };

  return (
    <div className="showVaccines__container">
      {loading ? (
        <div className="loader__container">
          <FadeLoader color="purple" loading={loading} size={15} />
        </div>
      ) : null}
      <div
        className="addUserForm__content--container"
        style={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "2rem 2rem",
        }}
      >
        <div
          className="listed__vaccines--container update__questionnarire-container"
          style={{
            // height: "40rem",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: "column",
            padding: "2rem 3rem",
          }}
        >
          <span
            className="title-and-close--icon"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              marginBottom: "3rem",
            }}
          >
            <p
              className="title"
              style={{ fontWeight: "600", fontSize: "2rem" }}
            >
              Update Questionnaire
            </p>
            <CloseIcon
              onClick={() => setShowUpdateQuestionnaire(false)}
              style={{ cursor: "pointer" }}
            />
          </span>
          <label className="aefi-label">No of surviving children</label>
          <input
            type="number"
            value={survivingChildren}
            onChange={(e) => setSurvivingChildren(e.target.value)}
            className="aefi-input"
          />
          <label className="aefi-label">Number of dead children</label>
          <input
            type="number"
            value={deadChildren}
            onChange={(e) => setDeadChildren(e.target.value)}
            className="aefi-input"
          />
          <label className="aefi-label">Child weight at birth</label>
          <input
            type="number"
            value={childWeight}
            onChange={(e) => setChildWeight(e.target.value)}
            className="aefi-input"
          />
          <label className="aefi-label">
            Child weighed less than 2kg at birth
          </label>
          <select
            id="weight_at_birth"
            className="aefi-input"
            value={childWeightLessThanTwo}
            required
            onChange={(e) => setChildWeightLessThanTwo(e.target.value)}
          >
            <option value=""></option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          <label className="aefi-label">Is this baby a twin?</label>
          <select
            id="baby_twin"
            className="aefi-input"
            value={babyTwin}
            required
            onChange={(e) => setBabyTwin(e.target.value)}
          >
            <option value=""></option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          <label className="aefi-label">Is this baby bottle fed?</label>
          <select
            id="bottle_fed"
            className="aefi-input"
            value={bottleFed}
            required
            onChange={(e) => setBottleFed(e.target.value)}
          >
            <option value=""></option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          <label className="aefi-label">
            Does the mother need family support?
          </label>
          <select
            id="family_support"
            className="aefi-input"
            value={motherNeedSupport}
            required
            onChange={(e) => setMotherNeedSupport(e.target.value)}
          >
            <option value=""></option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          <label className="aefi-label">
            Are the baby's siblings underweight?
          </label>
          <select
            id="sibling_underweight"
            className="aefi-input"
            value={siblingUnderweight}
            required
            onChange={(e) => setSiblingsUnderweight(e.target.value)}
          >
            <option value=""></option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          <label className="aefi-label">Other Reasons?</label>
          <select
            id="sibling_underweight"
            className="aefi-input"
            value={otherReasons}
            required
            onChange={(e) => setOtherReasons(e.target.value)}
          >
            <option value=""></option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          <button
            className="submit-aefi--CTA"
            onClick={() => uppdateChildQuestionnaire()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateQuestionnaire;
