import { useState, useRef, useMemo, useEffect } from "react";
// import { ReactComponent as FezzantLogo } from "../../assets/icons/fezzant-beta.svg";
import { ReactComponent as MailLogo } from "../../assets/icons/mail-icon.svg";
import { ReactComponent as NameIcon } from "../../assets/icons/name-icon.svg";
import axios from "axios";
import { ReactComponent as XIcon } from "../../assets/icons/close-icon.svg";
import { ReactComponent as TickIcon } from "../../assets/icons/tick-icon.svg";
import { toast } from "react-toastify";
import PicturePrompt from "../PicturePrompt/PicturePrompt";
import { Dexie } from "dexie";
import { Link } from "react-router-dom";
import { db } from "../../App";
import { useLiveQuery } from "dexie-react-hooks";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import { api } from "../..";

// import Loading from "../Loading/Loading.component";
import "./addChild.css";

const ADDCHILDURL = "/api/register_child";

const AddChild = ({ closeForm }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailFocus, setEmailFocus] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [birthCertNo, setBirthCertNum] = useState("");
  const [birthCertNumFocus, setBirthCertNumFocus] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [dateOfBirthFocus, setDateOfBirthFocus] = useState(false);
  const [gender, setGender] = useState("");
  const [genderFocus, setGenderFocus] = useState(false);
  const [fatherName, setFatherName] = useState("");
  const [motherName, setMotherName] = useState("");
  const [currentFirstname, setCurrentFirstname] = useState("");
  const [currentLastname, setCurrentLastname] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");
  const [genotype, setGenotype] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  // const [currentEmail, setCurrentEmail] = useState("");
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState("");
  const [currentUserTypeId, setCurrentUserTypeId] = useState("");
  const [currentPrimaryCareGiver, setCurrentPrimaryCareGiver] = useState("0");
  const [currentGender, setCurrentGender] = useState("");
  const [currentAddress, setCurrentAddress] = useState("");
  const [currentNin, setCurrentNin] = useState("");
  const [firstNames, setFirstNames] = useState([]);
  const [lastNames, setLastNames] = useState([]);
  const [genders, setGenders] = useState([]);
  const [nins, setNins] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [emails, setEmails] = useState([]);
  const [address, setAddress] = useState([]);
  const [caregivers, setCaregivers] = useState([]);
  const [userTypeIds, setUserTypeIds] = useState([]);
  const [primaryCareGiver, setPrimaryCareGiver] = useState([]);
  const [careGiverName, setCareGiverName] = useState("");
  const [fatherNameFOcus, setFatherNameFocus] = useState(false);
  const [contactPhone, setContactPhone] = useState("");
  const [fatherPhoneFOcus, setFatherPhoneFocus] = useState(false);
  const [showAddParent, setShowAddParent] = useState(false);
  const [success, setSuccess] = useState(false);
  const [disable, setDisable] = useState(false);
  const [fatherAdded, setFatherAdded] = useState(false);
  const [showSnapPhoto, setShowSnapPhoto] = useState(false);
  const [motherAdded, setMotherAdded] = useState(false);
  const [guardianAdded, setGaurdianAdded] = useState(false);
  const [child, setChild] = useState("");

  const emailRef = useRef();

  const { childData } = db;

  const allItems = useLiveQuery(() => childData.toArray(), []);

  const addChildOffline = async (e) => {
    setLoading(true);
    setDisable(false);
    e.preventDefault();
    try {
      const response = await db.childData.add({
        firstName,
        lastName,
        birthCertNo,
        dateOfBirth,
        gender,
        firstNames,
        lastNames,
        emails,
        nins,
        phoneNumbers,
        caregivers,
        genders,
        userTypeIds,
        address,
      });
      setLoading(false);

      toast.success("Child's details added OFFLINE successfully!");
      setFirstName("");
      setLastName("");
      setGender("");
      setFirstNames("");
      setLastNames("");
      setEmails("");
      setPhoneNumbers("");
      setNins("");
      setCaregivers("");
      setGenders("");
      setUserTypeIds("");
      setAddress("");
      setBirthCertNum("");
      setContactPhone("");
      setDateOfBirth("");
      setCareGiverName("");
      setAddress("");
      setCurrentPrimaryCareGiver("");
      setShowAddParent(false);
      setFatherAdded(false);
      setMotherAdded(false);
      setGaurdianAdded(false);
    } catch (error) {
      setLoading(false);
      toast.error("An error occured. Please try again");
      console.log({ error });
      console.log("Dexie Not working");
    }
  };

  const addParentDetails = () => {
    setFirstNames([...firstNames, currentFirstname]);
    setLastNames([...lastNames, currentLastname]);
    setEmails([...emails, currentEmail]);
    setPhoneNumbers([...phoneNumbers, currentPhoneNumber]);
    setCaregivers([...caregivers, currentPrimaryCareGiver]);
    setGenders([...genders, currentGender]);
    setUserTypeIds([...userTypeIds, currentUserTypeId]);
    setAddress([...address, currentAddress]);
    setNins([...nins, currentNin]);

    if (currentPrimaryCareGiver === "1") {
      setCurrentPrimaryCareGiver("0");
      setDisable(true);
    }

    setShowAddParent(!showAddParent);
    if (currentUserTypeId === "5") {
      setFatherAdded(true);
    } else if (currentUserTypeId === "6") {
      setMotherAdded(true);
    } else if (currentUserTypeId === "7") {
      setGaurdianAdded(true);
    }

    setCurrentFirstname("");
    setCurrentLastname("");
    setCurrentEmail("");
    setCurrentPhoneNumber("");
    // setCurrentPrimaryCareGiver("");
    setCurrentNin("");
    setCurrentGender("");
    setCurrentUserTypeId("");
    setCurrentAddress("");

    toast.success("Parent Added Successfully!");
  };

  const navigateToChildAfterReg = (id) => {};

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisable(false);
    //if button enabled with JS hack
    setLoading(true);
    api
      .post(ADDCHILDURL, {
        first_name: firstName,
        last_name: lastName,
        birth_cert_no: birthCertNo,
        dob: dateOfBirth,
        gender: gender,
        p_firstname: firstNames,
        p_lastname: lastNames,
        // email: emails,
        phone_number: phoneNumbers,
        user_type_id: userTypeIds,
        p_gender: genders,
        address: address,
        caregiver: caregivers,
        nin: nins,
        no_parent: firstNames.length,
        genotype,
        blood_group: bloodGroup,
      })
      .then((res) => {
        setSuccess(true);
        toast.success("Child Added Successfully!");
        setFirstName("");
        setLastName("");
        setGender("");
        setFirstNames("");
        setLastNames("");
        setEmails("");
        setPhoneNumbers("");
        setCaregivers("");
        setGenders("");
        setUserTypeIds("");
        setNins("");
        setAddress("");
        setBirthCertNum("");
        setContactPhone("");
        setDateOfBirth("");
        setCareGiverName("");
        setAddress("");
        setGenotype("");
        setBloodGroup("");
        setCurrentPrimaryCareGiver("");
        setShowAddParent(false);
        setFatherAdded(false);
        setMotherAdded(false);
        setGaurdianAdded(false);
        setChild(res?.data?.child);
        setLoading(false);
      })
      .then(() => setShowSnapPhoto(!showSnapPhoto))
      .catch((error) => {
        if (!error?.response) {
          setErrMsg("No Server Response");
          toast.error("No Server Response");
        } else if (error.response?.status === 400) {
          setErrMsg("Child Already Exists");
          toast.error("Child Already Exists");
        } else {
          setErrMsg("Registration Failed");
          toast.error("Registration Failed");
        }

        setLoading(false);
      });
  };

  const addChild = navigator.onLine ? handleSubmit : addChildOffline;

  useEffect(() => {
    if (currentUserTypeId === "5") {
      setCurrentGender("male");
    } else if (currentUserTypeId === "6") {
      setCurrentGender("female");
    } else {
      return;
    }
  }, [currentUserTypeId]);

  // const handleParentGender = (e) => {
  //   if (currentUserTypeId === "5") {
  //     setCurrentGender("male");
  //   } else if (currentUserTypeId === "6") {
  //     setCurrentGender("female");
  //   } else {
  //     setCurrentGender(e.target.value);
  //   }
  // };

  return (
    <div className="addChild__container">
      {showSnapPhoto ? (
        <PicturePrompt closeAddChild={closeForm} child={child} />
      ) : null}
      {loading ? (
        <div
          className="loader__container"
          style={{ height: "100vh", position: "fixed" }}
        >
          <ClimbingBoxLoader color="#ffffff" loading={loading} size={15} />
        </div>
      ) : null}
      <div className="addUserForm__content--container">
        <div className="addUserForm__form--container">
          <span className="addUser__logo-span">{/* <FezzantLogo /> */}</span>
          <div className="add-user__form-and-text__container">
            <span className="addUser__heading-and-close--CTA">
              <span className="addUserForm__form--texts">
                {/* {loading ? (
                  <Loading type="cylon" color="rgb(101, 93, 138)" />
                ) : null} */}
                {/* {errMsg ? <h2>{errMsg}</h2> : null} */}

                <h2 className="addUserForm__formheading">Add Child</h2>

                {errorMessage ? (
                  <p
                    style={{
                      color: "#F95959",
                      marginBottom: "1rem",
                    }}
                  >
                    {errorMessage}
                  </p>
                ) : (
                  ""
                )}
                <p className="addUserForm__form-sub--text">
                  Fill in the child's details and submit.
                </p>
              </span>
              <XIcon
                style={{ fill: "#F95959", cursor: "pointer" }}
                onClick={closeForm}
              />
              {/* <Link to="/company-panel/users">
                <XIcon style={{ fill: "#F95959" }} />
              </Link> */}
            </span>
            <form
              action="
                "
              className="addUserForm__form"
              onSubmit={addChild}
            >
              {/* <label htmlFor="Email" className="addUserForm__email--label">
                Father's Email Address
              </label>
              <div className="addUserForm__email-and-icon">
                <input
                  type="email"
                  id="email"
                  ref={emailRef}
                  autoComplete="off"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                  aria-describedby="uidnote"
                  onFocus={() => setEmailFocus(true)}
                  onBlur={() => setEmailFocus(false)}
                  className={
                    errorMessage ? "email--input" : "addChild__email--input"
                  }
                />
                {errorMessage && (
                  <p
                    style={{
                      color: "#F95959",
                      marginTop: "1rem",
                    }}
                  >
                    Father's Email is required
                  </p>
                )}
                <MailLogo className="mail__icon" />
              </div> */}
              <label
                htmlFor="firstName"
                className="addUserForm__password--label"
              >
                Child's First Name
              </label>
              <div className="addUserForm__password-and-icons">
                <input
                  type="text"
                  className={
                    errorMessage ? "email--input" : "addChild__email--input"
                  }
                  id="firstName"
                  autoComplete="off"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                  // required
                  aria-describedby="uidnote"
                />
                {errorMessage && (
                  <p
                    style={{
                      color: "#F95959",
                      marginTop: "1rem",
                    }}
                  >
                    Child's First Name is required
                  </p>
                )}
                {/* <NameIcon className="mail__icon" /> */}
              </div>
              <label
                htmlFor="lasttName"
                className="addUserForm__password--label"
              >
                Child's Last Name
              </label>
              <div className="addUserForm__password-and-icons">
                <input
                  type="text"
                  className={
                    errorMessage ? "email--input" : "addChild__email--input"
                  }
                  id="lastName"
                  autoComplete="off"
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                  // required
                  aria-describedby="uidnote"
                  onFocus={() => setEmailFocus(true)}
                  onBlur={() => setEmailFocus(false)}
                />
                {errorMessage && (
                  <p
                    style={{
                      color: "#F95959",
                      marginTop: "1rem",
                    }}
                  >
                    Child's Last Name is required
                  </p>
                )}
                {/* <NameIcon className="mail__icon" /> */}
              </div>
              <label
                htmlFor="birthCertNo"
                className="addUserForm__password--label"
              >
                Child's Birth Certificate No
              </label>
              <div className="addUserForm__password-and-icons">
                <input
                  type="text"
                  className={
                    errorMessage ? "email--input" : "addChild__email--input"
                  }
                  id="birthCertNo"
                  autoComplete="off"
                  onChange={(e) => setBirthCertNum(e.target.value)}
                  value={birthCertNo}
                  // required
                  aria-describedby="uidnote"
                  onFocus={() => setBirthCertNumFocus(true)}
                  onBlur={() => setBirthCertNumFocus(false)}
                />
                {errorMessage && (
                  <p
                    style={{
                      color: "#F95959",
                      marginTop: "1rem",
                    }}
                  >
                    Child's Birth Certificate Number is required
                  </p>
                )}
                {/* <NameIcon className="mail__icon" /> */}
              </div>
              <label
                htmlFor="genotype"
                className="addUserForm__password--label"
              >
                Child's Genotype
              </label>
              <div className="addUserForm__password-and-icons">
                <select
                  // name="countries"
                  id="gender"
                  className={
                    errorMessage ? "email--input" : "addChild__email--input"
                  }
                  value={genotype}
                  // required
                  onChange={(e) => setGenotype(e.target.value)}
                >
                  <option value=""></option>
                  <option value="AA">AA</option>
                  <option value="AS">AS</option>
                  <option value="AB">AB</option>
                </select>
                {errorMessage && (
                  <p
                    style={{
                      color: "#F95959",
                      marginTop: "1rem",
                    }}
                  >
                    Child's genotype is required
                  </p>
                )}
                {/* <NameIcon className="mail__icon" /> */}
              </div>
              <label
                htmlFor="bloodGroup"
                className="addUserForm__password--label"
              >
                Child's Blood Group
              </label>
              <div className="addUserForm__password-and-icons">
                <select
                  // name="countries"
                  id="bloodGroup"
                  className={
                    errorMessage ? "email--input" : "addChild__email--input"
                  }
                  value={bloodGroup}
                  // required
                  onChange={(e) => setBloodGroup(e.target.value)}
                >
                  <option value=""></option>
                  <option value="O+">O+</option>
                  <option value="O-">O-</option>
                  <option value="A+">A+</option>
                  <option value="A-">A-</option>
                  <option value="B-">B-</option>
                  <option value="AB+">AB+</option>
                  <option value="AB-">AB-</option>
                  <option value="B+">B+</option>
                </select>
                {errorMessage && (
                  <p
                    style={{
                      color: "#F95959",
                      marginTop: "1rem",
                    }}
                  >
                    Child's blood group is required
                  </p>
                )}
                {/* <NameIcon className="mail__icon" /> */}
              </div>
              <label htmlFor="dob" className="addUserForm__password--label">
                Child's Date Of Birth
              </label>
              <div className="addUserForm__password-and-icons">
                <input
                  type="date"
                  className={
                    errorMessage ? "email--input" : "addChild__email--input"
                  }
                  id="dob"
                  autoComplete="off"
                  onChange={(e) =>
                    setDateOfBirth(e.target.value.split("/").join("-"))
                  }
                  value={dateOfBirth}
                  required
                  aria-describedby="uidnote"
                  onFocus={() => setDateOfBirthFocus(true)}
                  onBlur={() => setDateOfBirthFocus(false)}
                />
                {errorMessage && (
                  <p
                    style={{
                      color: "#F95959",
                      marginTop: "1rem",
                    }}
                  >
                    Child's DOB is required
                  </p>
                )}
                {/* <NameIcon className="mail__icon" /> */}
              </div>
              <label htmlFor=" gender" className="addUserForm__password--label">
                Child's Gender
              </label>
              <div className="addUserForm__password-and-icons">
                <select
                  // name="countries"
                  id="gender"
                  className={
                    errorMessage ? "email--input" : "addChild__email--input"
                  }
                  value={gender}
                  required
                  onChange={(e) => setGender(e.target.value)}
                  onFocus={() => setGenderFocus(true)}
                  onBlur={() => setGenderFocus(false)}
                  // {...register("country", { required: true })}
                >
                  <option value=""></option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                {errorMessage && (
                  <p
                    style={{
                      color: "#F95959",
                      marginTop: "1rem",
                    }}
                  >
                    Child's gender is required
                  </p>
                )}
              </div>
              <span className="added__parent-list--span">
                {fatherAdded ? (
                  <span className="added__parent-span">
                    <p className="added__parent-text">Father</p> <TickIcon />
                  </span>
                ) : null}
                {motherAdded ? (
                  <span className="added__parent-span">
                    <p className="added__parent-text">Mother</p> <TickIcon />
                  </span>
                ) : null}
                {guardianAdded ? (
                  <span className="added__parent-span">
                    <p className="added__parent-text">Gaurdian</p> <TickIcon />
                  </span>
                ) : null}
              </span>
              {showAddParent ? (
                <>
                  <label
                    htmlFor=" gender"
                    className="addUserForm__password--label"
                  >
                    Parent/Guardian
                  </label>
                  <div className="addUserForm__password-and-icons">
                    <select
                      // name="countries"
                      id="gender"
                      className={
                        errorMessage ? "email--input" : "addChild__email--input"
                      }
                      value={currentUserTypeId}
                      required
                      onChange={(e) => setCurrentUserTypeId(e.target.value)}
                    >
                      <option value=""></option>
                      <option value="5">Father</option>
                      <option value="6">Mother</option>
                      <option value="7">Guardian</option>
                    </select>
                    {errorMessage && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      >
                        Child's gender is required
                      </p>
                    )}
                  </div>
                  <label
                    htmlFor=" gender"
                    className="addUserForm__password--label"
                  >
                    Is Parent/Guardian Selected Above the Child's Primary Care
                    Giver?
                  </label>
                  <div className="addUserForm__password-and-icons">
                    <select
                      // name="countries"
                      disabled={disable ? true : false}
                      id="gender"
                      className={
                        errorMessage ? "email--input" : "addChild__email--input"
                      }
                      value={currentPrimaryCareGiver}
                      required
                      onChange={(e) =>
                        setCurrentPrimaryCareGiver(e.target.value)
                      }
                    >
                      <option value=""></option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                    {errorMessage && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      >
                        Child's gender is required
                      </p>
                    )}
                  </div>
                  <label
                    htmlFor=" gender"
                    className="addUserForm__password--label"
                  >
                    Parent/Guardian's Firstname
                  </label>
                  <div className="addUserForm__password-and-icons">
                    <input
                      type="text"
                      className={
                        errorMessage ? "email--input" : "addChild__email--input"
                      }
                      id="firstName"
                      autoComplete="off"
                      onChange={(e) => setCurrentFirstname(e.target.value)}
                      value={currentFirstname}
                      required
                      aria-describedby="uidnote"
                    />
                    {errorMessage && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      >
                        Child's First Name is required
                      </p>
                    )}
                    {/* <NameIcon className="mail__icon" /> */}
                  </div>
                  <label
                    htmlFor=" gender"
                    className="addUserForm__password--label"
                  >
                    Parent/Guardian's Lastname
                  </label>
                  <div className="addUserForm__password-and-icons">
                    <input
                      type="text"
                      className={
                        errorMessage ? "email--input" : "addChild__email--input"
                      }
                      id="firstName"
                      autoComplete="off"
                      onChange={(e) => setCurrentLastname(e.target.value)}
                      value={currentLastname}
                      required
                      aria-describedby="uidnote"
                    />
                    {errorMessage && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      >
                        Child's First Name is required
                      </p>
                    )}
                    {/* <NameIcon className="mail__icon" /> */}
                  </div>
                  {/* <label
                    htmlFor=" gender"
                    className="addUserForm__password--label"
                  >
                    Parent/Guardian's Email
                  </label>
                  <div className="addUserForm__password-and-icons">
                    <input
                      type="text"
                      className={
                        errorMessage ? "email--input" : "addChild__email--input"
                      }
                      id="firstName"
                      autoComplete="off"
                      onChange={(e) => setCurrentEmail(e.target.value)}
                      value={currentEmail}
                    
                      aria-describedby="uidnote"
                    />
                    {errorMessage && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      >
                        Child's First Name is required
                      </p>
                    )}
               
                  </div> */}
                  <label
                    htmlFor=" gender"
                    className="addUserForm__password--label"
                  >
                    Parent/Guardian's gender
                  </label>
                  <div className="addUserForm__password-and-icons">
                    <select
                      // name="countries"
                      id="gender"
                      className={
                        errorMessage ? "email--input" : "addChild__email--input"
                      }
                      value={currentGender}
                      // required
                      onChange={(e) => setCurrentGender(e.target.value)}
                    >
                      <option value=""></option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    {errorMessage && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      >
                        Child's gender is required
                      </p>
                    )}
                  </div>
                  <label
                    htmlFor=" gender"
                    className="addUserForm__password--label"
                  >
                    Parent/Guardian's Phone Number
                  </label>
                  <div className="addUserForm__password-and-icons">
                    <input
                      type="text"
                      className={
                        errorMessage ? "email--input" : "addChild__email--input"
                      }
                      id="firstName"
                      autoComplete="off"
                      onChange={(e) => setCurrentPhoneNumber(e.target.value)}
                      value={currentPhoneNumber}
                      required
                      aria-describedby="uidnote"
                    />
                    {errorMessage && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      >
                        Child's First Name is required
                      </p>
                    )}
                    {/* <NameIcon className="mail__icon" /> */}
                  </div>
                  <label
                    htmlFor=" nins"
                    className="addUserForm__password--label"
                  >
                    Parent/Guardian's NIN
                  </label>
                  <div className="addUserForm__password-and-icons">
                    <input
                      type="text"
                      className={
                        errorMessage ? "email--input" : "addChild__email--input"
                      }
                      id="nin"
                      autoComplete="off"
                      onChange={(e) => setCurrentNin(e.target.value)}
                      value={currentNin}
                      // required
                      aria-describedby="uidnote"
                    />
                    {errorMessage && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      ></p>
                    )}
                    {/* <NameIcon className="mail__icon" /> */}
                  </div>
                  <label
                    htmlFor=" gender"
                    className="addUserForm__password--label"
                  >
                    Parent/Guardian's Address
                  </label>
                  <div className="addUserForm__password-and-icons">
                    <input
                      type="text"
                      className={
                        errorMessage ? "email--input" : "addChild__email--input"
                      }
                      id="firstName"
                      autoComplete="off"
                      onChange={(e) => setCurrentAddress(e.target.value)}
                      value={currentAddress}
                      // required
                      aria-describedby="uidnote"
                    />
                    {errorMessage && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      >
                        Child's First Name is required
                      </p>
                    )}
                    {/* <NameIcon className="mail__icon" /> */}
                  </div>
                  {/* <label
                htmlFor="father_name"
                className="addUserForm__password--label"
              >
                Father's Name
              </label>
              <div className="addUserForm__password-and-icons">
                <input
                  type="text"
                  className={
                    errorMessage ? "email--input" : "addChild__email--input"
                  }
                  id="fatherName"
                  autoComplete="off"
                  onChange={(e) => setFatherName(e.target.value)}
                  value={fatherName}
                  required
                  aria-describedby="uidnote"
                  onFocus={() => setFatherNameFocus(true)}
                  onBlur={() => setFatherNameFocus(false)}
                />
                {errorMessage && (
                  <p
                    style={{
                      color: "#F95959",
                      marginTop: "1rem",
                    }}
                  >
                    Father's name is required
                  </p>
                )}
              
              </div> */}
                  {/* <label
                htmlFor="motherName"
                className="addUserForm__password--label"
              >
                Mother's Name
              </label>
              <div className="addUserForm__password-and-icons">
                <input
                  type="text"
                  className={
                    errorMessage ? "email--input" : "addChild__email--input"
                  }
                  id="motherName"
                  autoComplete="off"
                  onChange={(e) => setMotherName(e.target.value)}
                  value={motherName}
                  required
                  aria-describedby="uidnote"
                />
                {errorMessage && (
                  <p
                    style={{
                      color: "#F95959",
                      marginTop: "1rem",
                    }}
                  >
                    Mother's name is required
                  </p>
                )}
              </div> */}
                  <button
                    className="add_parent--CTA"
                    onClick={() => addParentDetails()}
                  >
                    Add Parent
                  </button>
                </>
              ) : null}

              {showAddParent ? null : (
                <button
                  className="add_parent--CTA"
                  onClick={() => setShowAddParent(!showAddParent)}
                  disabled={firstNames.length > 2 ? true : false}
                >
                  {firstNames.length >= 1
                    ? "+ Add Another Parent/Gaurdian"
                    : "+ Add Parent/Gaurdian"}
                </button>
              )}

              <button className="addUserForm__submit--CTA">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddChild;
