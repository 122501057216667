import { useEffect, useState } from "react";
import Highcharts from "highcharts";
import drilldown from "highcharts/modules/drilldown";
import { api } from "../..";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter-icon.svg";

const UnimunizedGraph = () => {
  const [year, setYear] = useState("2024");
  const [data, setData] = useState();
  const [yearEntry, setYearEntry] = useState("");

  useEffect(() => {
    const getGraphData = () => {
      api
        .get(`/api/graph_unimmunized?year=${year}`)
        .then((res) => {
          setData(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getGraphData();
  }, [year]);

  const getFilteredData = (e) => {
    e.preventDefault();
    setYear(yearEntry);
  };

  drilldown(Highcharts);

  useEffect(() => {
    if (data) {
      const seriesData = data?.series[0].data.map((item) => ({
        name: item.name,
        y: item.y,
        drilldown: item.drilldown,
      }));

      const drilldownSeries = data?.drilldown?.series.map((item) => ({
        name: item.name,
        id: item.id,
        data: item.data,
      }));

      const options = {
        chart: {
          type: "column",
        },
        title: {
          text: data.title.text,
        },
        xAxis: {
          type: "category",
        },

        legend: {
          enabled: false,
        },

        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
            },
          },
        },

        series: [
          {
            name: "Facilities",
            colorByPoint: true,
            data: seriesData,
          },
        ],
        drilldown: {
          series: drilldownSeries,
        },
      };

      Highcharts.chart("chart-container-5", options);
    }
  }, [data]);

  return (
    data && (
      <>
        <form
          className="filter__param-span filter__param-span-2"
          //   style={{ zIndex: "30" }}
          onSubmit={getFilteredData}
        >
          <div className="input__span-onn">
            <label htmlFor="date" className="filter__param-label">
              Year
            </label>
            <input
              type="number"
              id="date"
              value={yearEntry}
              onChange={(e) => setYearEntry(e.target.value)}
              className="filter__param-input filter__param-input-2"
              placeholder={year}
            />
          </div>

          <button
            className="filter__CTA filter__CTA-2"
            type="submit"
            disabled={!yearEntry}
          >
            Filter
            <FilterIcon style={{ marginLeft: "1rem" }} />
          </button>
        </form>
        <div
          id="chart-container-5"
          style={{
            height: "400px",
            width: "100%",
            marginTop: "2rem",
            marginBottom: "8rem",
          }}
        ></div>
      </>
    )
  );
};

export default UnimunizedGraph;
