import { useState } from "react";
import "./accordion.css";

const Accordion = () => {
  const [openAccordion, setOpenAccordion] = useState(false);

  return (
    <div
      className="full__vac-detail--accordion"
      onClick={() => setOpenAccordion(!openAccordion)}
    >
      <p className="faq__question-main--text">
        Why is BCG vaccine important for my child?
      </p>
      {openAccordion ? (
        <p className="faq__question-sub--text">
          It is important because, it provide protection for your child against
          tuberculosis
        </p>
      ) : null}
    </div>
  );
};

export default Accordion;
