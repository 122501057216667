export const vaccinations = [
  {
    id: "69",
    child_id: 4,
    vac_name: "OPV_0",
    code_name: null,
    date: "2023-09-14 20:56:35",
    status: "missed",
    created_at: "2023-07-26T20:56:35.000000Z",
    updated_at: "2023-07-26T20:56:35.000000Z",
  },
  {
    id: "69",
    child_id: 4,
    vac_name: "OPV_1",
    code_name: null,
    date: "2023-09-14 20:56:35",
    status: "missed",
    created_at: "2023-07-26T20:56:35.000000Z",
    updated_at: "2023-07-26T20:56:35.000000Z",
  },
  {
    id: "69",
    child_id: 4,
    vac_name: "BCG",
    code_name: null,
    date: "2023-09-14 20:56:35",
    status: "missed",
    created_at: "2023-07-26T20:56:35.000000Z",
    updated_at: "2023-07-26T20:56:35.000000Z",
  },
  {
    id: "69",
    child_id: 4,
    vac_name: "CCI",
    code_name: null,
    date: "2023-09-15 20:56:35",
    status: "taken",
    created_at: "2023-07-26T20:56:35.000000Z",
    updated_at: "2023-07-26T20:56:35.000000Z",
  },
  {
    id: "69",
    child_id: 4,
    vac_name: "BCG",
    code_name: null,
    date: "2023-09-15 20:56:35",
    status: "pending",
    created_at: "2023-07-26T20:56:35.000000Z",
    updated_at: "2023-07-26T20:56:35.000000Z",
  },
  {
    id: "69",
    child_id: 4,
    vac_name: "OBV",
    code_name: null,
    date: "2023-09-15 20:56:35",
    status: "taken",
    created_at: "2023-07-26T20:56:35.000000Z",
    updated_at: "2023-07-26T20:56:35.000000Z",
  },
  {
    id: "69",
    child_id: 4,
    vac_name: "FBI",
    code_name: null,
    date: "2023-09-20 20:56:35",
    status: "taken",
    created_at: "2023-07-26T20:56:35.000000Z",
    updated_at: "2023-07-26T20:56:35.000000Z",
  },
  {
    id: "69",
    child_id: 4,
    vac_name: "CGI",
    code_name: null,
    date: "2023-09-20 20:56:35",
    status: "taken",
    created_at: "2023-07-26T20:56:35.000000Z",
    updated_at: "2023-07-26T20:56:35.000000Z",
  },
  {
    id: "69",
    child_id: 4,
    vac_name: "BRB",
    code_name: null,
    date: "2023-09-20 20:56:35",
    status: "taken",
    created_at: "2023-07-26T20:56:35.000000Z",
    updated_at: "2023-07-26T20:56:35.000000Z",
  },
  {
    id: "69",
    child_id: 4,
    vac_name: "BRB",
    code_name: null,
    date: "2023-10-20 20:56:35",
    status: "taken",
    created_at: "2023-07-26T20:56:35.000000Z",
    updated_at: "2023-07-26T20:56:35.000000Z",
  },
  {
    id: "69",
    child_id: 4,
    vac_name: "BRB",
    code_name: null,
    date: "2023-11-02 20:56:35",
    status: "taken",
    created_at: "2023-07-26T20:56:35.000000Z",
    updated_at: "2023-07-26T20:56:35.000000Z",
  },
];

export const formatDateToDisplay = (inputDate) => {
  // Create a JavaScript Date object from the input string
  const date = new Date(inputDate);

  // Define months as an array for easy month name retrieval
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get day, month, and year from the Date object
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  // Function to add ordinal suffix to day (e.g., 1st, 2nd, 3rd, 4th)
  function getOrdinalSuffix(day) {
    if (day >= 11 && day <= 13) {
      return `${day}th`;
    }
    switch (day % 10) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  }

  // Get the ordinal day
  const ordinalDay = getOrdinalSuffix(day);

  // Create the formatted date string
  const formattedDate = `${ordinalDay} ${month}, ${year}`;

  return formattedDate;
};

// Example usage:
// const inputDate = "2023-09-20";
// const formattedDate = formatDateToDisplay(inputDate);
// console.log(formattedDate); // Output: "20th Sept, 2023"
