import { useState, useEffect, useRef } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { ReactComponent as XIcon } from "../../assets/icons/close-icon.svg";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { ReactComponent as VacMark } from "../../assets/icons/vac-check.svg";
import { ReactComponent as VacDot } from "../../assets/icons/vac-dot.svg";
import Badge from "@mui/material/Badge";
import { vaccinations, formatDateToDisplay } from "../../vacDates";
import { UseSelector, useSelector } from "react-redux/es/hooks/useSelector";
import "./newCalendar.css";

const NewCalendar = () => {
  const [takenDates, setTakenDays] = useState([]);

  const [pendingDates, setPendingDates] = useState([]);

  const [missedVac, setMissedVacs] = useState([]);

  const [vacsToDisplay, setVacsToDisplay] = useState([]);

  const [dateToDisplay, setDateToDisplay] = useState("");

  const [value, setValue] = useState(dayjs("2022-09-01"));

  // const [value, setValue] = useState(dayjs(vaccinations[0].date.substr(0, 10)));

  const initialValue = dayjs("2023-09-01");

  const baby = useSelector((state) => state.babies.baby);

  // console.log({ pendingDate: pendingDates[0] });

  //////////////////// Get highlighted days

  useEffect(() => {
    //////////////////// Make the calendar start from the first Vaccination
    // setValue(dayjs(vaccinations[0].date));
    const takenVacsDates = [];
    const missedVacsDates = [];
    const pendingVacsDates = [];

    const getDaysToHighlight = () => {
      baby?.vac_date.forEach((vaccination) => {
        const dateString = vaccination.date.substr(0, 10);

        if (vaccination.status === "taken") takenVacsDates.push(dateString);
        if (vaccination.status === "missed") missedVacsDates.push(dateString);
        if (vaccination.status === "pending") pendingVacsDates.push(dateString);
        // return setHighlightedDays([...highlightedDays, newDate]);
      });
    };

    getDaysToHighlight();
    setPendingDates(pendingVacsDates);
    // console.log(pendingVacsDates[0]);
    //////////////////// Make the calendar start from the pending Vaccination
    setValue(dayjs(pendingVacsDates[0]));
    setTakenDays(takenVacsDates);
    setMissedVacs(missedVacsDates);
  }, []);

  useEffect(() => {
    const vacsToDisplay = [];
    const getVacsToDisplay = () => {
      baby.vac_date?.forEach((vaccination) => {
        const vaccinationDateString = vaccination.date.substr(0, 10);
        // console.log({ vaccinationDateString });
        // console.log({ value });
        if (vaccinationDateString === value.format("YYYY-MM-DD")) {
          vacsToDisplay.push(vaccination.vac_name);
        }
      });
      setVacsToDisplay(vacsToDisplay);
      setDateToDisplay(formatDateToDisplay(value));
    };

    getVacsToDisplay();
    // console.log({ vacsToDisplay });
  }, [value]);

  function ServerDay(props) {
    // console.log({ props });
    const {
      takenDates = [],
      day,
      outsideCurrentMonth,
      pendingDates,
      missedVac,
      ...other
    } = props;

    const isSelected =
      !props.outsideCurrentMonth &&
      takenDates.includes(day.format("YYYY-MM-DD"));

    const vacPending = pendingDates.indexOf(day.format("YYYY-MM-DD"));
    const vacMissed = missedVac.indexOf(day.format("YYYY-MM-DD"));

    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        badgeContent={
          isSelected && vacPending < 0 ? (
            <VacMark className="taken__vac-icon" />
          ) : vacPending >= 0 ? (
            <VacDot className="pending__vac-icon" />
          ) : vacMissed >= 0 ? (
            <XIcon />
          ) : undefined
        }
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  }

  // console.log({ value: value.format("YYYY-DD-MM") });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        // defaultValue={}
        displayStaticWrapperAs="desktop"
        slots={{
          day: ServerDay,
        }}
        slotProps={{
          day: {
            takenDates,
            pendingDates,
            missedVac,
          },
        }}
        // referenceDate={dayjs("2022-04-17")}
        views={["year", "month", "day"]}
        value={value}
        onChange={(newValue) => setValue(newValue)}
      />
      {vacsToDisplay.length > 0 && (
        <div className="full__schedule-current--day-vacs">
          {vacsToDisplay.map((vac) => (
            <p className="full__schedule-current--day-vac">{vac}</p>
          ))}

          <p className="full__schedule-current--day-vac-date">
            Administered {dateToDisplay}
          </p>
        </div>
      )}
    </LocalizationProvider>
  );
};

export default NewCalendar;
