import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { api } from "../..";
import { toast } from "react-toastify";
import { ReactComponent as XIcon } from "../../assets/icons/close-icon.svg";

const AddAgency = ({ showAddAgency, setShowAddAgency }) => {
  const [stateName, setStateName] = useState();
  const [stateId, setStateId] = useState("");
  const [agencyName, setAgencyName] = useState("");
  const [states, setStates] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  useEffect(() => {
    api
      .get("/api/states")
      .then((res) => {
        setStates(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const addAgency = () => {
    api
      .post("/api/agency", { name: agencyName, state_id: stateId }, config)
      .then((res) => {
        setStateId("");
        setAgencyName("");

        toast.success("Agency Added Successfully!");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error Processing Request, please try again");
      });
  };

  return (
    <div className="addChild__container">
      <div
        className="addUserForm__content--container"
        style={{ height: "100%", width: "100vw" }}
      >
        <div className="addState__form">
          <span className="form__heading-and-close--CTA">
            <p className="addUserForm__formheading">Add Agency</p>
            <XIcon
              style={{ fill: "#F95959", cursor: "pointer" }}
              onClick={() => setShowAddAgency(!showAddAgency)}
            />
          </span>
          <label htmlFor="state" className="addUserForm__password--label">
            Agency's Name
          </label>
          <div className="addUserForm__password-and-icons">
            <input
              type="text"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              id="state"
              autoComplete="off"
              onChange={(e) => setAgencyName(e.target.value)}
              value={agencyName}
              style={{ width: "100%", margin: "1.5rem 0" }}
              required
              aria-describedby="uidnote"
            />
            {errorMessage && (
              <p
                style={{
                  color: "#F95959",
                  marginTop: "1rem",
                }}
              >
                Agency's Name is Required
              </p>
            )}
          </div>
          <label htmlFor=" gender" className="addUserForm__password--label">
            State
          </label>
          <div className="addUserForm__password-and-icons">
            <select
              // name="countries"
              id="state"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              value={stateId}
              required
              onChange={(e) => setStateId(e.target.value)}
              style={{
                width: "100%",
                marginTop: "1.5rem",
                // marginBottom: "1.5rem",
              }}

              // {...register("country", { required: true })}
            >
              <option value=""></option>
              {states?.map((state) => {
                return <option value={state.id}>{state.name} </option>;
              })}
            </select>
            {errorMessage && (
              <p
                style={{
                  color: "#F95959",
                  marginTop: "1rem",
                }}
              >
                State is required
              </p>
            )}
            {/* <NameIcon className="mail__icon" /> */}
          </div>
          <button
            className="addUserForm__submit--CTA"
            onClick={() => addAgency()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddAgency;
