import React, { useEffect, useState } from "react";
// import { ReactComponent as CheckIcon } from "../../assets/icons/check-icon.svg";
// import { ReactComponent as PeopleIcon } from "../../assets/icons/people-icon.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/user-icon.svg";
import { useAuthContext } from "../../contexts/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import "./dashboardTab.css";

const DashboardTab = ({
  textOne,
  textTwo,
  icon,
  iconBackground,
  boxShadow,
  width,
  tabSize,
  cardUrl,
}) => {
  const [changeCursor, setChangeCursor] = useState(false);

  const location = useLocation();

  // const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  // const one = JSON.parse(userDetails);

  const navigate = useNavigate();
  // console.log({ userDetails });

  useEffect(() => {
    if (
      textOne === "Children With Missed Dose" ||
      (textOne === "Total No Of Children" &&
        location.pathname !== "/panel/admin-dashboard")
    ) {
      setChangeCursor(true);
    }
  }, []);

  const navigateToUrl = () => {
    if (textOne === "Children With Missed Dose") {
      navigate("/panel/missed-vacs-list");
    } else if (textOne === "Total No Of Children") {
      navigate("/panel/babies");
    }
  };

  return (
    <>
      <div
        className="dashboard__tab"
        style={{
          width: width ? width : "",
          flex: tabSize ? tabSize : "",
          cursor: changeCursor ? "pointer" : null,
        }}
        onClick={() => navigateToUrl()}
      >
        <span className="tab__details-and-icon">
          <div
            className="icon__div"
            style={{
              background: `${iconBackground}`,
              boxShadow: `${boxShadow}`,
            }}
          >
            {icon}
          </div>
          <span className="welcome-and-user__name">
            <p className="tab__welcome">{textOne}</p>
            <p className="tab__userName">{textTwo}</p>
          </span>
        </span>
      </div>
    </>
  );
};

export default DashboardTab;
