import { useState, useRef, useMemo, useEffect } from "react";
import { ReactComponent as XIcon } from "../../assets/icons/close-icon.svg";
import { toast } from "react-toastify";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import { useSelector } from "react-redux";
import { api } from "../..";
import "./updateParent.css";

const UpdateParent = ({ showUpdateParent, setShowUpdateParent }) => {
  const baby = useSelector((state) => state.babies.baby);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  //   const [userToEdit, setUserToEdit] = useState({});
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [nin, setNin] = useState("");
  const [gender, setGender] = useState();
  const [primaryCareGiver, setPrimaryCareGiver] = useState("");
  const [hasCaregiver, setHasCaregiver] = useState(false);
  const [userTypeId, setUserTypeId] = useState("");
  const [parentId, setParentId] = useState();
  const [showEditParent, setShowEditparent] = useState(true);
  const [apiUrl, setApiUrl] = useState("");
  const [parentsToAdd, setParentsToAdd] = useState([]);
  //   const [hasPrimaryCare]
  const [errMsg, setErrMsg] = useState("");

  const emailRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();

    //if button enabled with JS hack
    setLoading(true);
    api
      .post(apiUrl, {
        first_name: firstName,
        last_name: lastName,
        gender,
        phone_number: phoneNumber,
        email,
        address,
        nin,
        user_type_id: userTypeId,
        primary_caregiver: primaryCareGiver,
      })
      .then((res) => {
        toast.success("Parent's Data Updated Successfully!");
        setTimeout(() => {
          setShowUpdateParent(false);
        }, 2000);
        setFirstName("");
        setLastName("");
        setGender("");
        setPhoneNumber("");
        setEmail("");
        setAddress("");
        setUserTypeId("");
        setNin("");
        setPrimaryCareGiver("");
        // setChild(res?.data?.child);
        setLoading(false);
      })

      .catch((error) => {
        if (!error?.response) {
          setErrMsg("No Server Response");
          toast.error("No Server Response");
        } else if (error.response?.status === 400) {
          setErrMsg("Child Already Exists");
          toast.error("Child Already Exists");
        } else {
          setErrMsg("Registration Failed");
          toast.error("Registration Failed");
        }

        setLoading(false);
      });
  };

  const handleUserTypeIdForAddingParent = () => {
    const parentsToAdd = ["5", "6", "7"];
    const existingParents = baby.user.filter((user) => user.user_type_id > "4");
    const combinedArray = parentsToAdd.concat(existingParents);
    const notExistingParent = combinedArray.filter((user) => {
      return !parentsToAdd.includes(user) || !existingParents.includes(user);
    });

    setParentsToAdd(notExistingParent);
  };

  useEffect(() => {
    // if (baby) {
    //   const primaryCareGiver = baby.user.filter(
    //     (user) => user.primary_caregiver === 1
    //   );

    //   console.log({ primaryCareGiver });
    //   if (primaryCareGiver.length > 0) {
    //     setHasCaregiver(true);
    //     console.log(primaryCareGiver[0].id);
    //     if (primaryCareGiver[0].id == parentId) {
    //       setPrimaryCareGiver("1");
    //     } else {
    //       setPrimaryCareGiver("0");
    //     }
    //   } else {
    //     setHasCaregiver(false);
    //   }
    // }

    const updateEditDetails = () => {
      const userToEdit = baby.user.filter((user) => user.id == parentId);

      if (userToEdit && showEditParent) {
        const user = userToEdit[0];
        // setUserToEdit(userToEdit[0]);
        setFirstName(user?.first_name);
        setLastName(user?.last_name);
        setPhoneNumber(user?.phone_number);
        setEmail(user?.email);
        setGender(user?.gender);
        setNin(user?.nin);
        setAddress(user?.address);
        setUserTypeId(user?.user_type_id);
        setPrimaryCareGiver(user?.primary_caregiver);
      } else {
        setFirstName("");
        setLastName("");
        setPhoneNumber("");
        setEmail("");
        setGender("");
        setNin("");
        setAddress("");
        setUserTypeId("");
        setPrimaryCareGiver("");
      }
    };
    updateEditDetails();

    if (showEditParent) {
      setApiUrl(`api/user/${parentId}`);
    } else {
      setApiUrl(`api/child_parent/${baby.id}`);
    }

    const handleUserTypeIdForAddingParent = () => {
      const parentsToAdd = ["5", "6", "7"];
      const parents = [];
      const existingParents = baby.user.forEach((user) => {
        if (user.user_type_id > "4") {
          parents.push(user.user_type_id);
        }
      });

      const combinedArray = parentsToAdd.concat(parents);

      const notExistingParent = combinedArray.filter((user) => {
        return !parents.includes(user);
      });

      setParentsToAdd(notExistingParent);
    };

    handleUserTypeIdForAddingParent();
  }, [baby, parentId, showEditParent]);

  useEffect(() => {
    if (userTypeId === "5") {
      setGender("male");
    } else if (userTypeId === "6") {
      setGender("female");
    } else {
      return;
    }
  }, [userTypeId]);

  return (
    <div className="addChild__container">
      {loading ? (
        <div
          className="loader__container"
          style={{ height: "100vh", position: "fixed" }}
        >
          <ClimbingBoxLoader color="#ffffff" loading={loading} size={15} />
        </div>
      ) : null}
      <div className="addUserForm__content--container">
        <div className="addUserForm__form--container">
          <span className="addUser__logo-span">{/* <FezzantLogo /> */}</span>
          <div className="add-user__form-and-text__container">
            <span className="addUser__heading-and-close--CTA">
              <span className="addUserForm__form--texts">
                {/* {loading ? (
                  <Loading type="cylon" color="rgb(101, 93, 138)" />
                ) : null} */}
                {/* {errMsg ? <h2>{errMsg}</h2> : null} */}

                <h2 className="addUserForm__formheading">Update Parents</h2>

                {errorMessage ? (
                  <p
                    style={{
                      color: "#F95959",
                      marginBottom: "1rem",
                    }}
                  >
                    {errorMessage}
                  </p>
                ) : (
                  ""
                )}
                <p className="addUserForm__form-sub--text">
                  Add or Edit Details and Submit.
                </p>
              </span>
              <XIcon
                style={{ fill: "#F95959", cursor: "pointer" }}
                onClick={() => setShowUpdateParent(!showUpdateParent)}
              />
            </span>
            <span className="parent__update-switch">
              <p
                className={
                  showEditParent
                    ? "inactive__parent-switch"
                    : "active__parent-switch"
                }
                onClick={() => setShowEditparent(false)}
              >
                Add Parent
              </p>
              <p
                className={
                  !showEditParent
                    ? "inactive__parent-switch"
                    : "active__parent-switch"
                }
                onClick={() => setShowEditparent(true)}
              >
                Edit Parent
              </p>
            </span>
            {showEditParent ? (
              <form
                action="
                "
                className="addUserForm__form"
                onSubmit={handleSubmit}
              >
                {/* <span className="added__parent-list--span">
                {fatherAdded ? (
                  <span className="added__parent-span">
                    <p className="added__parent-text">Father</p> <TickIcon />
                  </span>
                ) : null}
                {motherAdded ? (
                  <span className="added__parent-span">
                    <p className="added__parent-text">Mother</p> <TickIcon />
                  </span>
                ) : null}
                {guardianAdded ? (
                  <span className="added__parent-span">
                    <p className="added__parent-text">Gaurdian</p> <TickIcon />
                  </span>
                  ) : null}
                </span> */}
                {/* {showAddParent ? ( */}
                <>
                  <label
                    htmlFor=" gender"
                    className="addUserForm__password--label"
                  >
                    Select Parent/Guardian To Edit
                  </label>
                  <div className="addUserForm__password-and-icons">
                    <select
                      // name="countries"
                      id="gender"
                      className={
                        errorMessage ? "email--input" : "addChild__email--input"
                      }
                      value={parentId}
                      required
                      onChange={(e) => setParentId(e.target.value)}
                    >
                      <option value=""></option>
                      {baby.user
                        .filter((user) => user.user_type_id > 4)
                        .map((parent) => (
                          <option value={parent.id}>
                            {parent.user_type_id === "5"
                              ? "Father"
                              : parent.user_type_id === "6"
                              ? "Mother"
                              : "Gaurdian"}
                          </option>
                        ))}
                    </select>
                    {errorMessage && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      >
                        Child's gender is required
                      </p>
                    )}
                  </div>
                  {hasCaregiver ? null : (
                    <>
                      <label
                        htmlFor=" gender"
                        className="addUserForm__password--label"
                      >
                        Is Parent/Guardian Selected Above the Child's Primary
                        Care Giver?
                      </label>
                      <div className="addUserForm__password-and-icons">
                        <select
                          // name="countries"
                          // disabled={disable ? true : false}
                          id="gender"
                          className={
                            errorMessage
                              ? "email--input"
                              : "addChild__email--input"
                          }
                          value={primaryCareGiver}
                          required
                          onChange={(e) => setPrimaryCareGiver(e.target.value)}
                        >
                          <option value=""></option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                        {errorMessage && (
                          <p
                            style={{
                              color: "#F95959",
                              marginTop: "1rem",
                            }}
                          >
                            Child's gender is required
                          </p>
                        )}
                      </div>
                    </>
                  )}
                  <label
                    htmlFor=" gender"
                    className="addUserForm__password--label"
                  >
                    Parent/Guardian's Firstname
                  </label>
                  <div className="addUserForm__password-and-icons">
                    <input
                      type="text"
                      className={
                        errorMessage ? "email--input" : "addChild__email--input"
                      }
                      id="firstName"
                      autoComplete="off"
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                      required
                      aria-describedby="uidnote"
                    />
                    {errorMessage && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      >
                        Child's First Name is required
                      </p>
                    )}
                    {/* <NameIcon className="mail__icon" /> */}
                  </div>
                  <label
                    htmlFor=" gender"
                    className="addUserForm__password--label"
                  >
                    Parent/Guardian's Lastname
                  </label>
                  <div className="addUserForm__password-and-icons">
                    <input
                      type="text"
                      className={
                        errorMessage ? "email--input" : "addChild__email--input"
                      }
                      id="firstName"
                      autoComplete="off"
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                      required
                      aria-describedby="uidnote"
                    />
                    {errorMessage && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      >
                        Child's First Name is required
                      </p>
                    )}
                  </div>

                  <label
                    htmlFor=" gender"
                    className="addUserForm__password--label"
                  >
                    Parent/Guardian's gender
                  </label>
                  <div className="addUserForm__password-and-icons">
                    <select
                      // name="countries"
                      id="gender"
                      className={
                        errorMessage ? "email--input" : "addChild__email--input"
                      }
                      value={gender}
                      // required
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <option value=""></option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    {errorMessage && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      >
                        Child's gender is required
                      </p>
                    )}
                  </div>
                  <label
                    htmlFor=" gender"
                    className="addUserForm__password--label"
                  >
                    Parent/Guardian's Phone Number
                  </label>
                  <div className="addUserForm__password-and-icons">
                    <input
                      type="text"
                      className={
                        errorMessage ? "email--input" : "addChild__email--input"
                      }
                      id="firstName"
                      autoComplete="off"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      value={phoneNumber}
                      required
                      aria-describedby="uidnote"
                    />
                    {errorMessage && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      >
                        Child's First Name is required
                      </p>
                    )}
                    {/* <NameIcon className="mail__icon" /> */}
                  </div>
                  <label
                    htmlFor=" nins"
                    className="addUserForm__password--label"
                  >
                    Parent/Guardian's NIN
                  </label>
                  <div className="addUserForm__password-and-icons">
                    <input
                      type="text"
                      className={
                        errorMessage ? "email--input" : "addChild__email--input"
                      }
                      id="nin"
                      autoComplete="off"
                      onChange={(e) => setNin(e.target.value)}
                      value={nin}
                      // required
                      aria-describedby="uidnote"
                    />
                    {errorMessage && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      ></p>
                    )}
                    {/* <NameIcon className="mail__icon" /> */}
                  </div>
                  <label
                    htmlFor="email"
                    className="addUserForm__password--label"
                  >
                    Parent/Guardian's Email
                  </label>
                  <div className="addUserForm__password-and-icons">
                    <input
                      type="email"
                      className={
                        errorMessage ? "email--input" : "addChild__email--input"
                      }
                      id="email"
                      autoComplete="off"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      // required
                      aria-describedby="uidnote"
                    />
                    {errorMessage && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      ></p>
                    )}
                    {/* <NameIcon className="mail__icon" /> */}
                  </div>
                  <label
                    htmlFor=" gender"
                    className="addUserForm__password--label"
                  >
                    Parent/Guardian's Address
                  </label>
                  <div className="addUserForm__password-and-icons">
                    <input
                      type="text"
                      className={
                        errorMessage ? "email--input" : "addChild__email--input"
                      }
                      id="firstName"
                      autoComplete="off"
                      onChange={(e) => setAddress(e.target.value)}
                      value={address}
                      // required
                      aria-describedby="uidnote"
                    />
                    {errorMessage && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      >
                        Child's First Name is required
                      </p>
                    )}
                  </div>
                </>
                {/* ) : null} */}
                <button className="addUserForm__submit--CTA">Update</button>
              </form>
            ) : (
              <form
                action="
                "
                className="addUserForm__form"
                onSubmit={handleSubmit}
              >
                {/* <span className="added__parent-list--span">
                {fatherAdded ? (
                  <span className="added__parent-span">
                    <p className="added__parent-text">Father</p> <TickIcon />
                  </span>
                ) : null}
                {motherAdded ? (
                  <span className="added__parent-span">
                    <p className="added__parent-text">Mother</p> <TickIcon />
                  </span>
                ) : null}
                {guardianAdded ? (
                  <span className="added__parent-span">
                    <p className="added__parent-text">Gaurdian</p> <TickIcon />
                  </span>
                  ) : null}
                </span> */}
                {/* {showAddParent ? ( */}
                {parentsToAdd.length < 1 ? (
                  <p className="max__parent-added--text">
                    All Parents Have Been Added.
                  </p>
                ) : null}
                <>
                  <label
                    htmlFor=" gender"
                    className="addUserForm__password--label"
                  >
                    Select Parent/Guardian To Add
                  </label>
                  <div className="addUserForm__password-and-icons">
                    <select
                      // name="countries"
                      id="gender"
                      className={
                        errorMessage ? "email--input" : "addChild__email--input"
                      }
                      value={userTypeId}
                      disabled={parentsToAdd.length < 1 ? true : false}
                      required
                      onChange={(e) => setUserTypeId(e.target.value)}
                    >
                      <option value=""></option>
                      {parentsToAdd.map((parent) => (
                        <option value={parent}>
                          {parent === "5"
                            ? "Father"
                            : parent === "6"
                            ? "Mother"
                            : "Gaurdian"}
                        </option>
                      ))}
                    </select>
                    {errorMessage && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      >
                        Child's gender is required
                      </p>
                    )}
                  </div>
                  {hasCaregiver ? null : (
                    <>
                      <label
                        htmlFor=" gender"
                        className="addUserForm__password--label"
                      >
                        Is Parent/Guardian Selected Above the Child's Primary
                        Care Giver?
                      </label>
                      <div className="addUserForm__password-and-icons">
                        <select
                          // name="countries"
                          // disabled={disable ? true : false}
                          id="gender"
                          className={
                            errorMessage
                              ? "email--input"
                              : "addChild__email--input"
                          }
                          value={primaryCareGiver}
                          required
                          onChange={(e) => setPrimaryCareGiver(e.target.value)}
                        >
                          <option value=""></option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                        {errorMessage && (
                          <p
                            style={{
                              color: "#F95959",
                              marginTop: "1rem",
                            }}
                          >
                            Child's gender is required
                          </p>
                        )}
                      </div>
                    </>
                  )}
                  <label
                    htmlFor=" gender"
                    className="addUserForm__password--label"
                  >
                    Parent/Guardian's Firstname
                  </label>
                  <div className="addUserForm__password-and-icons">
                    <input
                      type="text"
                      className={
                        errorMessage ? "email--input" : "addChild__email--input"
                      }
                      id="firstName"
                      autoComplete="off"
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                      required
                      aria-describedby="uidnote"
                    />
                    {errorMessage && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      >
                        Child's First Name is required
                      </p>
                    )}
                    {/* <NameIcon className="mail__icon" /> */}
                  </div>
                  <label
                    htmlFor=" gender"
                    className="addUserForm__password--label"
                  >
                    Parent/Guardian's Lastname
                  </label>
                  <div className="addUserForm__password-and-icons">
                    <input
                      type="text"
                      className={
                        errorMessage ? "email--input" : "addChild__email--input"
                      }
                      id="firstName"
                      autoComplete="off"
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                      required
                      aria-describedby="uidnote"
                    />
                    {errorMessage && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      >
                        Child's First Name is required
                      </p>
                    )}
                  </div>

                  <label
                    htmlFor=" gender"
                    className="addUserForm__password--label"
                  >
                    Parent/Guardian's gender
                  </label>
                  <div className="addUserForm__password-and-icons">
                    <select
                      // name="countries"
                      id="gender"
                      className={
                        errorMessage ? "email--input" : "addChild__email--input"
                      }
                      // required
                      onChange={(e) => setGender(e.target.value)}
                      value={gender}
                    >
                      <option value=""></option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    {errorMessage && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      >
                        Child's gender is required
                      </p>
                    )}
                  </div>
                  <label
                    htmlFor=" gender"
                    className="addUserForm__password--label"
                  >
                    Parent/Guardian's Phone Number
                  </label>
                  <div className="addUserForm__password-and-icons">
                    <input
                      type="text"
                      className={
                        errorMessage ? "email--input" : "addChild__email--input"
                      }
                      id="firstName"
                      autoComplete="off"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      value={phoneNumber}
                      required
                      aria-describedby="uidnote"
                    />
                    {errorMessage && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      >
                        Child's First Name is required
                      </p>
                    )}
                    {/* <NameIcon className="mail__icon" /> */}
                  </div>
                  <label
                    htmlFor=" gender"
                    className="addUserForm__password--label"
                  >
                    Parent/Guardian's Email
                  </label>
                  <div className="addUserForm__password-and-icons">
                    <input
                      type="text"
                      className={
                        errorMessage ? "email--input" : "addChild__email--input"
                      }
                      id="firstName"
                      autoComplete="off"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      //   required
                      aria-describedby="uidnote"
                    />
                    {errorMessage && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      >
                        Child's First Name is required
                      </p>
                    )}
                    {/* <NameIcon className="mail__icon" /> */}
                  </div>
                  <label
                    htmlFor=" nins"
                    className="addUserForm__password--label"
                  >
                    Parent/Guardian's NIN
                  </label>
                  <div className="addUserForm__password-and-icons">
                    <input
                      type="text"
                      className={
                        errorMessage ? "email--input" : "addChild__email--input"
                      }
                      id="nin"
                      autoComplete="off"
                      onChange={(e) => setNin(e.target.value)}
                      value={nin}
                      // required
                      aria-describedby="uidnote"
                    />
                    {errorMessage && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      ></p>
                    )}
                    {/* <NameIcon className="mail__icon" /> */}
                  </div>
                  <label
                    htmlFor=" gender"
                    className="addUserForm__password--label"
                  >
                    Parent/Guardian's Address
                  </label>
                  <div className="addUserForm__password-and-icons">
                    <input
                      type="text"
                      className={
                        errorMessage ? "email--input" : "addChild__email--input"
                      }
                      id="firstName"
                      autoComplete="off"
                      onChange={(e) => setAddress(e.target.value)}
                      value={address}
                      // required
                      aria-describedby="uidnote"
                    />
                    {errorMessage && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      >
                        Child's First Name is required
                      </p>
                    )}
                  </div>
                </>
                {/* ) : null} */}
                <button className="addUserForm__submit--CTA">Submit</button>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateParent;
