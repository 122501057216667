import { ReactComponent as BellIcon } from "../../assets/icons/notification-bell-icon.svg";
import sampleBabyPic from "../../assets/images/sample-baby-img.png";
import { useNavigate } from "react-router-dom";
import defaultImage from "../../assets/images/imtrackerlogo.jpeg";

import { UseSelector, useSelector } from "react-redux/es/hooks/useSelector";
import "./babies.css";
import Notification from "../Notification/Notification";
import { useEffect, useState } from "react";

const Babies = () => {
  const [currentBabies, setCurrentBabies] = useState([]);

  const userDetails = useSelector((state) => state.currentUser.currentUser);

  // const babies = userDetails?.total_children_under_user;

  const babies = useSelector((state) => state.babies.babies);

  const facility = useSelector((state) => state.facility.currentFacility);

  const navigate = useNavigate();

  useEffect(() => {
    if (userDetails.user_type_id === "2" || userDetails.user_type_id === "3") {
      setCurrentBabies(babies);
    } else {
      setCurrentBabies(babies);
      // setCurrentBabies(userDetails.child);
    }
  }, []);

  return (
    <div className="all__babies-container">
      {userDetails.user_type_id === "2" ? (
        <div className="active__facility-note">
          {`Viewing ${facility?.name}'s Data`}
        </div>
      ) : null}
      <div className="all__babies-heading-and-notification">
        <p className="all__babies-heading">My Babies</p>
        <Notification />
      </div>
      <div className="all__babies-mobile--bg"></div>
      <div className="no__of-babies--box">
        <div className="baby__icon-box" />
        <p className="no__of-babies">
          {currentBabies?.length}{" "}
          {currentBabies?.length > 1 ? "Children" : "Child"}
        </p>
      </div>
      <div className="babies__imgs-and-details">
        {currentBabies?.map((baby) => (
          <div
            className="baby__img-and-details"
            onClick={() => navigate(`/panel/child-details/${baby.id}`)}
          >
            <div className="baby__image-box">
              {/* <img
                src={sampleBabyPic}
                alt="baby-face"
                className="baby__detail-img"
              /> */}

              {baby?.picture ? (
                <img
                  // src={`http://localhost:8000/documents/children_profile_pictures/${baby?.picture}`}
                  src={`https://api.imt-tracker.hayokmedicare.ng/documents/children_profile_pictures/${baby?.picture}`}
                  alt="profile_picture"
                  className="baby__detail-img"
                  onerror="this.src='../../assets/images/imtrackerlogo.jpeg';"
                />
              ) : (
                <img
                  src={defaultImage}
                  alt="profile_picture"
                  className="baby__detail-img"
                  onerror="this.src='../../assets/images/imtrackerlogo.jpeg';"
                />
              )}
            </div>
            {/* <p className="baby__name-mobile">Godiya</p> */}
            <div className="baby__text-details--box">
              <p className="baby__name-txt">
                {baby.first_name} {baby.last_name}
              </p>
              {/* <p className="other__baby-details--text">Age: 2 weeks</p> */}
              {/* {calculateAge(baby)} */}
              {/* <p className="other__baby-details--text">Weight:</p> */}
            </div>
          </div>
        ))}
        {/* <div className="baby__img-and-details">
          <div className="baby__image-box" style={{ background: "#FF6B6B" }}>
            <img
              src={sampleBabyPic}
              alt="baby-face"
              className="baby__detail-img"
            />
          </div>
          <p className="baby__name-mobile">Godiya</p>
          <div className="baby__text-details--box">
            <p className="baby__name-txt">Godiya</p>
            <p className="other__baby-details--text">Age: 2 weeks</p>
            <p className="other__baby-details--text">Weight: 4kg</p>
          </div>
        </div>
        <div className="baby__img-and-details">
          <div className="baby__image-box" style={{ background: "#FFA726" }}>
            <img
              src={sampleBabyPic}
              alt="baby-face"
              className="baby__detail-img"
            />
          </div>
          <p className="baby__name-mobile">Godiya</p>
          <div className="baby__text-details--box">
            <p className="baby__name-txt">Godiya</p>
            <p className="other__baby-details--text">Age: 2 weeks</p>
            <p className="other__baby-details--text">Weight: 4kg</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Babies;
