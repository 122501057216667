import ManageSub from "../ManageSub/ManageSub";
import "./subscription.css";

const Subscription = () => {
  return (
    <div>
      <ManageSub />
    </div>
  );
};

export default Subscription;
