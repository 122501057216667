import { ReactComponent as BabyAtBirth } from "../../assets/icons/baby-birth-icon.svg";
import { ReactComponent as BabyAt6Weeks } from "../../assets/icons/baby-6-weks.svg";
import { ReactComponent as BabyAt10Weeks } from "../../assets/icons/baby-10-weeks.svg";
import { ReactComponent as BabyAt14Weeks } from "../../assets/icons/baby-14-weeks.svg";
import { ReactComponent as BabyAt9months } from "../../assets/icons/baby-9-months.svg";
import { ReactComponent as BabyAt12months } from "../../assets/icons/baby-12-months.svg";
import { ReactComponent as BabyAt15months } from "../../assets/icons/baby-15-months.svg";
import NewCalendar from "../NewCalendar/NewCalendar";
import { UseSelector, useSelector } from "react-redux/es/hooks/useSelector";
import "./fullVacSchedule.css";

const FullVacSchedule = () => {
  const baby = useSelector((state) => state.babies.baby);

  return (
    <div className="full__vac-schedule--container">
      <p className="full__schedule-heading">Vaccination Calendar</p>

      <p className="full__schedule-sub--heading">
        Check child's vaccination schedule
      </p>

      <div className="full__schedule-calendar">
        <NewCalendar />
      </div>
      <div className="vac__schedules-per--age">
        <div className="vac__schedule-per--age">
          <div className="vac__schedule-age-and-illustration">
            <div className="vac__schedule-age--box-red">
              <p className="vac__schedule-age--text">Birth</p>
            </div>
            <div className="vac__schedule-illustration-box-red">
              <BabyAtBirth className="baby__at-birth--icon" />
            </div>
          </div>
          <div className="full__vac-schedule--vac-texts">
            <p className="full__vac-schedule--vac-text">
              BCG [Bacille Calmette- Guerin]
            </p>
            <p className="full__vac-schedule--vac-text">
              OPV0 [Oral Poliovirus Vaccine]
            </p>
            <p className="full__vac-schedule--vac-text">
              Hep BO birth [Hepatitis B]
            </p>
          </div>
        </div>
        <div className="vac__schedule-per--age">
          <div className="vac__schedule-age-and-illustration">
            <div className="vac__schedule-age--box-yellow">
              <p className="vac__schedule-age--text">6 Weeks</p>
            </div>
            <div className="vac__schedule-illustration-box-yellow">
              <BabyAt6Weeks className="baby__at-birth--icon" />
            </div>
          </div>

          <div className="full__vac-schedule--vac-texts">
            <p className="full__vac-schedule--vac-text">
              Pentavalent (DPT, Hep B and Hib) 1
            </p>
            <p className="full__vac-schedule--vac-text">
              PCV [Pnemococcal Conjugate Vaccine 1]
            </p>
            <p className="full__vac-schedule--vac-text">OPV1</p>
            <p className="full__vac-schedule--vac-text">IPV1</p>
            <p className="full__vac-schedule--vac-text">Rotavirus vaccine 1</p>
          </div>
        </div>
        <div className="vac__schedule-per--age">
          <div className="vac__schedule-age-and-illustration">
            <div className="vac__schedule-age--box-green">
              <p className="vac__schedule-age--text">10 Weeks</p>
            </div>
            <div className="vac__schedule-illustration-box-green">
              <BabyAt10Weeks className="baby__at-birth--icon" />
            </div>
          </div>
          <div className="full__vac-schedule--vac-texts">
            <p className="full__vac-schedule--vac-text">
              Pentavalent (DPT, Hep B and Hib) 2
            </p>
            <p className="full__vac-schedule--vac-text">
              Pnemococcal Conjugate Vaccine 2
            </p>
            <p className="full__vac-schedule--vac-text">OPV2</p>
            <p className="full__vac-schedule--vac-text">Rotavirus vaccine 2</p>
          </div>
        </div>
        <div className="vac__schedule-per--age">
          <div className="vac__schedule-age-and-illustration">
            <div className="vac__schedule-age--box-red">
              <p className="vac__schedule-age--text">14 Weeks</p>
            </div>
            <div className="vac__schedule-illustration-box-red">
              <BabyAt14Weeks className="baby__at-birth--icon" />
            </div>
          </div>
          <div className="full__vac-schedule--vac-texts">
            <p className="full__vac-schedule--vac-text">
              {" "}
              Pentavalent 3 (DPT, Hep B and Hib)
            </p>
            <p className="full__vac-schedule--vac-text">
              Pnemococcal Conjugate Vaccine 3
            </p>
            <p className="full__vac-schedule--vac-text">OPV3</p>
            <p className="full__vac-schedule--vac-text">Rotavirus vaccine 3</p>
            <p className="full__vac-schedule--vac-text">IPV2</p>
          </div>
        </div>
        <div className="vac__schedule-per--age">
          <div className="vac__schedule-age-and-illustration">
            <div className="vac__schedule-age--box-green">
              <p className="vac__schedule-age--text">6 Months</p>
            </div>
            <div className="vac__schedule-illustration-box-green">
              <BabyAt10Weeks className="baby__at-birth--icon" />
            </div>
          </div>
          <div className="full__vac-schedule--vac-texts">
            <p className="full__vac-schedule--vac-text">Vitamin A 1st dose</p>
            <p className="full__vac-schedule--vac-text">
              Pnemococcal Conjugate Vaccine 2
            </p>
            <p className="full__vac-schedule--vac-text">OPV2</p>
            <p className="full__vac-schedule--vac-text">Rotavirus vaccine 2</p>
          </div>
        </div>
        <div className="vac__schedule-per--age">
          <div className="vac__schedule-age-and-illustration">
            <div className="vac__schedule-age--box-yellow">
              <p className="vac__schedule-age--text">
                9 Months
                <br />
                12 Months
              </p>
            </div>
            <div className="vac__schedule-illustration-box-yellow">
              <BabyAt9months className="baby__at-birth--icon" />
            </div>
          </div>
          <div className="full__vac-schedule--vac-texts">
            <p className="full__vac-schedule--vac-text">
              Measles 1st dose (MCV1)
            </p>
            <p className="full__vac-schedule--vac-text">Yellow Fever</p>
            <p className="full__vac-schedule--vac-text">Meningitis Vaccine</p>
            <p className="full__vac-schedule--vac-text">Vitamin A 2nd dose</p>
          </div>
        </div>
        <div className="vac__schedule-per--age">
          <div className="vac__schedule-age-and-illustration">
            <div className="vac__schedule-age--box-green">
              <p className="vac__schedule-age--text">15 Months</p>
            </div>
            <div className="vac__schedule-illustration-box-green">
              <BabyAt12months className="baby__at-birth--icon" />
            </div>
          </div>
          <div className="full__vac-schedule--vac-texts">
            <p className="full__vac-schedule--vac-text">
              Measles 2 dose (MCV2)
            </p>
          </div>
        </div>
        <div className="vac__schedule-per--age">
          <div className="vac__schedule-age-and-illustration">
            <div className="vac__schedule-age--box-red">
              <p className="vac__schedule-age--text">9 Years</p>
            </div>
            <div className="vac__schedule-illustration-box-red">
              <BabyAt15months className="baby__at-birth--icon" />
            </div>
          </div>
          <div className="full__vac-schedule--vac-texts">
            <p className="full__vac-schedule--vac-text">HPV</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullVacSchedule;
