import { useMemo, useState } from "react";
import { usePaystackPayment } from "react-paystack";
import axios from "axios";
import { api } from "../..";
// import "./paystack.styles.css";

const PaystackHook = ({ amount, pricePlanId, facilityId }) => {
  // const [planId, setPlanId] = useState("");
  const [subscriptionId, setSubscriptionId] = useState("");
  const [transactionCode, setTransactionCode] = useState("");

  const token = localStorage.getItem("token");
  const publicKey = process.env.REACT_APP_PAYSTACK_TEST_PUBLIC_KEY;
  const email = "toyosiogundairo@gmail.com";

  const componentProps = {
    email,
    amount,
    publicKey,
    ref: " " + Math.floor(Math.random() * 100000000 + 1),

    // metaData: {
    //   businessId: Id,
    // },
  };

  // ssss

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
      amount: amount,
      price_plan_id: pricePlanId,
      facility_id: facilityId,
    };
  }, [token, amount, pricePlanId, facilityId]);

  // const configTwo = useMemo(() => {
  //   return {
  //     headers: { Authorization: `Bearer ${token}` },
  //     subscription_id: localStorage.getItem("subId"),
  //     transaction_code: localStorage.getItem("transCode"),
  //     transaction_type: "subscription",
  //     facility_id: facilityId,
  //   };
  // }, [token, facilityId]);

  // console.log("sjkad");
  const transaction_type = "subscription";

  const updateSubTable = () => {
    api
      .post("/api/subscription", config)
      .then((res) => {
        localStorage.setItem("subId", res.data?.data?.id);
        localStorage.setItem("facilityId", facilityId);
        setSubscriptionId(res.data?.data?.id);
      })
      .then(() => {
        updateTransactionTable(
          localStorage.getItem("subId"),
          localStorage.getItem("transCode"),
          transaction_type,
          localStorage.getItem("facilityId")
        );
      })

      .catch((err) => console.log(err));
  };

  const updateTransactionTable = (
    subscription_id,
    transaction_code,
    transaction_type,
    facility_id
  ) => {
    api
      .post("/api/transaction", {
        subscription_id,
        transaction_code,
        transaction_type,
        facility_id,
      })
      .then((res) => {
        // setTimeout(() => {
        localStorage.removeItem("subId");
        localStorage.removeItem("transCode");
        localStorage.removeItem("facilityId");
        // }, [1000]);
      })
      .catch((err) => {
        console.log(err);
        localStorage.removeItem("subId");
        localStorage.removeItem("transCode");
        localStorage.removeItem("facilityId");
        // setTimeout(() => {
        //   localStorage.removeItem("subId");
        //   localStorage.removeItem("transCode");
        // }, [1000]);
      });
  };

  // console.log({ subscriptionId });
  // console.log({ transactionCode });
  // const updateTransactionTable = () => {
  //   axios
  //     .post("/api/transaction", {})
  //     .then((res) => console.log({ res }))
  //     .catch((err) => console.log(err));

  // }

  const onSuccess = (response) => {
    setTransactionCode(response?.reference);
    localStorage.setItem("transCode", response.reference);

    alert("Payment Successfull!");
    setTimeout(() => {
      updateSubTable();
    }, 2000);
  };

  const onClose = () => {
    alert("Are you sure you want to cancel payment?");
  };

  const initializePayment = usePaystackPayment(componentProps);

  const makePurchase = () => {
    initializePayment(onSuccess, onClose);
  };

  return (
    <span className="proceed__CTA--span">
      <button onClick={makePurchase} className="add__lga--CTA">
        Proceed to payment
      </button>
    </span>
  );
};

export default PaystackHook;
