import { useState, useMemo } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { ReactComponent as XIcon } from "../../assets/icons/close-icon.svg";
import { api } from "../..";

const AddLocalGovt = ({ setShowAddLga, showAddLga, id }) => {
  const [lgaName, setLgaName] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  const addLocalGovt = () => {
    api
      .post("/api/lga", { state_id: id, name: lgaName }, config)
      .then((res) => {
        setLgaName("");
        toast.success("LGA Added Successfully!");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error Processing Request, please try again");
      });
  };

  return (
    <div className="addChild__container">
      <div
        className="addUserForm__content--container"
        style={{ height: "100%", width: "100vw" }}
      >
        <div className="addState__form">
          <span className="form__heading-and-close--CTA">
            <p className="addUserForm__formheading">Add LGA</p>
            <XIcon
              style={{ fill: "#F95959", cursor: "pointer" }}
              onClick={() => setShowAddLga(!showAddLga)}
            />
          </span>
          <label htmlFor="state" className="addUserForm__password--label">
            LGA Name
          </label>
          <div className="addUserForm__password-and-icons">
            <input
              type="text"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              id="state"
              autoComplete="off"
              onChange={(e) => setLgaName(e.target.value)}
              value={lgaName}
              style={{ width: "100%", marginTop: "1.5rem" }}
              required
              aria-describedby="uidnote"
            />
            {errorMessage && (
              <p
                style={{
                  color: "#F95959",
                  marginTop: "1rem",
                }}
              >
                State's Name is Required
              </p>
            )}
          </div>
          <button
            className="addUserForm__submit--CTA"
            onClick={() => addLocalGovt()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddLocalGovt;
