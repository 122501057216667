import { useState, useMemo } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { ReactComponent as XIcon } from "../../assets/icons/close-icon.svg";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import { api } from "../..";

const AddModule = ({ showAddModule, setShowAddModule }) => {
  const [moduleName, setModuleName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  const addModule = () => {
    api
      .post("/api/tracker_module", { name: moduleName }, config)
      .then((res) => {
        setModuleName("");
        toast.success("Module Added Successfully!");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error Processing Request. please try again");
      });
  };

  return (
    <div className="addChild__container">
      {loading ? (
        <div className="loader__container">
          <ClimbingBoxLoader color="#ffffff" loading={loading} size={15} />
        </div>
      ) : null}
      <div
        className="addUserForm__content--container"
        style={{ height: "100%", width: "100vw" }}
      >
        <div className="addState__form">
          <span className="form__heading-and-close--CTA">
            <p className="addUserForm__formheading">Add Module</p>
            <XIcon
              style={{ fill: "#F95959", cursor: "pointer" }}
              onClick={() => setShowAddModule(!showAddModule)}
            />
          </span>
          <label htmlFor="module" className="addUserForm__password--label">
            Module Name
          </label>
          <div className="addUserForm__password-and-icons">
            <input
              type="text"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              id="module"
              autoComplete="off"
              onChange={(e) => setModuleName(e.target.value)}
              value={moduleName}
              style={{ width: "100%", marginTop: "1.5rem" }}
              required
              aria-describedby="uidnote"
            />
            {errorMessage && (
              <p
                style={{
                  color: "#F95959",
                  marginTop: "1rem",
                }}
              >
                Module's Name is Required
              </p>
            )}
          </div>
          <button
            className="addUserForm__submit--CTA"
            onClick={() => addModule()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddModule;
