import { currentFacilityActionTypes } from "./currentFacilityActionTypes";

const INITIAL_STATE = {
  currentFacility: null,
};

const facilityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case currentFacilityActionTypes.STORE_FACILITY_DETAILS:
      return {
        ...state,
        currentFacility: action.payload,
      };

    default:
      return state;
  }
};

export default facilityReducer;
