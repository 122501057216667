import { useState, useMemo } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { ReactComponent as XIcon } from "../../assets/icons/close-icon.svg";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";

import { useEffect } from "react";
import { api } from "../..";

const LinkModule = ({ showLinkModule, setShowLinkModule, id }) => {
  const [lgaName, setLgaName] = useState();
  const [modules, setModules] = useState([]);
  const [moduleId, setModuleId] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  useEffect(() => {
    getModules();
  }, []);

  const linkModuleToAgency = () => {
    api
      .post("/api/agency_tracker/attach", {
        agency_id: id,
        tracker_id: moduleId,
      })
      .then((res) => {
        setModuleId("");
        toast.success("Module linked successfully!");
      })
      .catch((err) => {
        toast.error("Error Processing Request, please try again");
      });
  };

  const getModules = () => {
    setLoading(true);
    api
      .get("/api/tracker_module")
      .then((res) => {
        setModules(res.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <div className="addChild__container">
      {loading ? (
        <div className="loader__container">
          <ClimbingBoxLoader color="#ffffff" loading={loading} size={15} />
        </div>
      ) : null}
      <div
        className="addUserForm__content--container"
        style={{ height: "100%", width: "100vw" }}
      >
        <div className="addState__form">
          <span className="form__heading-and-close--CTA">
            <p className="addUserForm__formheading">Link Module</p>
            <XIcon
              style={{ fill: "#F95959", cursor: "pointer" }}
              onClick={() => setShowLinkModule(!showLinkModule)}
            />
          </span>
          <label htmlFor="state" className="addUserForm__password--label">
            Select Module To Link
          </label>
          <div className="addUserForm__password-and-icons">
            <select
              // name="countries"
              id="state"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              value={moduleId}
              required
              onChange={(e) => setModuleId(e.target.value)}
              style={{
                width: "100%",
                marginTop: "1.5rem",
                marginBottom: "1.5rem",
              }}

              // {...register("country", { required: true })}
            >
              <option value=""></option>
              {modules?.map((module) => {
                return <option value={module.id}>{module.name} </option>;
              })}
            </select>
            {errorMessage && (
              <p
                style={{
                  color: "#F95959",
                  marginTop: "1rem",
                }}
              >
                Module is required
              </p>
            )}
            {/* <NameIcon className="mail__icon" /> */}
          </div>
          <button
            className="addUserForm__submit--CTA"
            onClick={() => linkModuleToAgency()}
            style={{ marginTop: "1rem" }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default LinkModule;
