import React, { useState, useEffect, useMemo } from "react";
import DashboardTab from "../DashboardTab/DashboardTab";
import { ReactComponent as NurseIcon } from "../../assets/icons/nurse-icon.svg";
import { ReactComponent as BabyIcon } from "../../assets/icons/baby-icon.svg";
import { ReactComponent as HospitalIcon } from "../../assets/icons/hospital-icon.svg";
import { ReactComponent as CountIcon } from "../../assets/icons/countingbabies.svg";
import { ReactComponent as TotalPatientsIcon } from "../../assets/icons/total-patients-icon.svg";
import { ReactComponent as CompletedIcon } from "../../assets/icons/complete-vac-icon.svg";
import { ReactComponent as UncompletedIcon } from "../../assets/icons/uncomplete-vac-icon.svg";
import { v4 as uuidv4 } from "uuid";
import Table from "../Table/Table";
import axios from "axios";
import AddChild from "../AddChild/AddChild";
import AddAdmin from "../AddAdmin/AddAdmin";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import { useAuthContext } from "../../contexts/AuthContext";
import { ReactComponent as AddUserIcon } from "../../assets/icons/add-user.svg";
import { ReactComponent as CareGiverIcon } from "../../assets/icons/care-giver-icon.svg";
import { api } from "../..";
import { useDispatch, useSelector } from "react-redux";
import { compareAsc, format } from "date-fns";
import "chart.js/auto";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import {
  Chart,
  ArcElement,
  Tooltip,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import AddFacilityWorker from "../AddFacilityWorker/AddFacilityWorker";
import AddImWorker from "../AddImWorker/AddImWorker";
import { storeUserDetails } from "../../redux/currentUser/currentUser.actions";
import { storeFacilityDetails } from "../../redux/currentFacility/currentFacilityActions";
import { storeAgencyDetails } from "../../redux/currentAgency/currentAgencyActions";
import AddFacility from "../AddFacility/AddFacility";
import Notification from "../Notification/Notification";
import DrilldownGraph from "../DrillDownGraph/DrillDownGraph";
import FacilitiesVacsGraph from "../FacilitiesVacsGraph/FacilitiesVacsGraph";
import FacilityChildrenGraph from "../FacilityChildrenGraph/FacilityChildrenGraph";
import ZeroDoseGraph from "../ZeroDoseGraph/ZeroDoseGraph";
import UnimunizedGraph from "../UnimmunizedGraph/UnimmunizedGraph";
import DropOutGraph from "../DropOutGraph/DropOutGraph";
// import "./healthCareWorker.css";
// import axios from "axios";

Chart.register(ArcElement, Tooltip, BarElement, CategoryScale, LinearScale);

const AdminDashboard = () => {
  const [userData, setUserData] = useState();
  const [showRegChildForm, setShowRegChildForm] = useState(false);
  const [showRegAdminForm, setShowRegAdminForm] = useState(false);
  const [showRegImWorkerForm, setShowRegImWorkerForm] = useState(false);
  // const [facility, setFacility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [offlineUserData, setOfflineUserData] = useState();
  const [offlineFacilityData, setOfflineFacilityData] = useState();
  const [showAddFacility, setShowAddFacility] = useState(false);
  const [facilities, setFacilities] = useState([]);
  const [children, setChildren] = useState();
  const [upComingVacs, setUpcomingVacs] = useState([]);

  const [takenChartData, setTakenChartData] = useState();
  const [missedChartData, setMissedChartData] = useState();
  const [genderChartData, setGenderChartData] = useState();
  const [pendingChartData, setPendingChartData] = useState();
  const [drillDownData, setDrillDownData] = useState();
  const [childrenGraphData, setChildrenGraphData] = useState();

  // const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const userDetails = useSelector((state) => state.currentUser.currentUser);
  const facility = useSelector((state) => state.facility.currentFacility);
  const agency = useSelector((state) => state.agency.currentAgency);

  const { setAuthDetails } = useAuthContext();

  const dispatch = useDispatch();

  // const token = localStorage.getItem("token");

  // const config = useMemo(() => {
  //   return {
  //     headers: { Authorization: `Bearer ${token}` },
  //   };
  // }, [token]);

  useEffect(() => {
    // getUserFacility();
    dispatch(storeFacilityDetails(""));
    getUserAgency();
    getFacilities();
    // getPendingChartData();
    // getUserData();
    // getTakenChartData();
    // getMissedChartData();
    // getGenderChartData();
    getDrillData();
    getDrillDataTwo();
  }, [
    showRegChildForm,
    showRegImWorkerForm,
    showRegAdminForm,
    showAddFacility,
  ]);

  const getDrillData = () => {
    api.get("/api/graph_facchildern").then((res) => {
      setDrillDownData(res.data);
    });
  };
  const getDrillDataTwo = () => {
    api
      .get(`/api/graph_regchildren?start_date=01-01-2000&end_date=01-01-2024`)
      .then((res) => {
        setChildrenGraphData(res.data);
      });
  };

  // useEffect(() => {
  //   getUserData();
  // }, [showRegChildForm, showRegAdminForm]);

  useEffect(() => {
    // getUserFacility();
    // localStorage.setItem("offlineUserData", JSON.stringify(userData));

    setOfflineUserData(JSON.parse(localStorage.getItem("offlineUserData")));
    setAuthDetails(JSON.parse(localStorage.getItem("offlineUserData")));
  }, [userDetails, showRegAdminForm]);

  if (userDetails !== undefined) {
    localStorage.setItem("offlineUserData", JSON.stringify(userDetails));
  }

  // const getUserData = () => {
  //   api
  //     .get(`/api/user/${userDetails?.id}`)
  //     .then((res) => {
  //       console.log({ res });
  //       dispatch(storeUserDetails(res?.data?.user));
  //       // setUserData(res?.data);
  //       // dispatch(storeUserDetails(res?.data));
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       // setLoading(false);
  //     });
  // };

  const getTakenChartData = () => {
    api
      .get("/api/dash1")
      .then((res) => {
        setTakenChartData(res.data[0]);
      })
      .catch((err) => console.log(err));
  };

  const getPendingChartData = () => {
    api
      .get("/api/dash3")
      .then((res) => {
        // console.log(res.data[0]);
        setPendingChartData(res.data[0]);
      })
      .catch((err) => console.log(err));
  };

  const getMissedChartData = () => {
    api
      .get("/api/dash2")
      .then((res) => {
        // console.log(res.data[0]);
        setMissedChartData(res.data[0]);
      })
      .catch((err) => console.log(err));
  };

  const getGenderChartData = () => {
    api
      .get("/api/gender")
      .then((res) => {
        setGenderChartData(res.data[0]);
      })
      .catch((err) => console.log(err));
  };

  const getUserAgency = () => {
    setLoading(true);
    api
      .get(`api/agency/${agency?.id}`)
      .then((res) => {
        // dispatch(storeAgencyDetails(res.data));
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getFacilities = () => {
    api
      .get("/api/facilities")
      .then((res) => setFacilities(res.data.data.data))
      .catch((error) => console.log(error));
  };

  // const offlineFacilityData =
  // JSON.parse(
  //   localStorage.getItem("offlineFacilityData")
  // );
  if (!offlineFacilityData?.data) {
    localStorage.setItem("offlineFacilityData", JSON.stringify(agency));
  }

  useEffect(() => {
    if (!navigator.onLine)
      setOfflineFacilityData(
        JSON.parse(localStorage.getItem("offlineFacilityData"))
      );
  }, []);

  const dashboardTabData = navigator.onLine
    ? [
        // {
        //   textOne: "Total Patients",
        //   textTwo: agency?.total_patients,
        //   tabIcon: <TotalPatientsIcon />,
        //   iconBackground:
        //     "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
        //   boxShadow:
        //     "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        // },
        // {
        //   textOne: "Babies Registered",
        //   textTwo: agency?.total_children_in_the_agency.length,
        //   tabIcon: <BabyIcon />,

        //   iconBackground:
        //     "linear-gradient(195deg, rgb(180 186 115), rgb(180 182 87))",
        //   boxShadow:
        //     "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(76 175 79 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        // },

        {
          textOne: "Completed Vaccinations",
          textTwo: agency?.children_with_completed_vaccination,
          tabIcon: <CompletedIcon />,
          iconBackground:
            "linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))",
          boxShadow:
            "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(76 175 79 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        },
        {
          textOne: "Uncompleted Vaccinations",
          textTwo: agency?.children_with_uncompleted_vaccination,
          tabIcon: <UncompletedIcon />,
          iconBackground:
            "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))",
          boxShadow:
            "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(233 30 98 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        },

        {
          textOne: "Total No Of Children",
          textTwo: agency?.total_children_under_agency,
          tabIcon: <CountIcon />,
          iconBackground:
            "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
          boxShadow:
            "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(0 187 212 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        },
        // {
        //   textOne: "Immunization Workers",
        //   textTwo: facility.data?.user?.filter(
        //     (user) => user.user_type_id === "4"
        //   ).length,
        //   tabIcon: <NurseIcon />,
        //   iconBackground:
        //     "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
        //   boxShadow:
        //     "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        // },
        {
          textOne: "Facilities",
          textTwo: agency?.total_facilities,
          tabIcon: <CareGiverIcon />,
          iconBackground:
            "linear-gradient(195deg, rgb(180 186 115), rgb(180 182 87))",
          boxShadow:
            "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(76 175 79 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        },
      ]
    : [
        {
          textOne: "Babies Registered",
          textTwo: offlineUserData?.data?.child?.length,
          tabIcon: <BabyIcon />,
          iconBackground:
            "linear-gradient(195deg, rgb(180 186 115), rgb(180 182 87))",
          boxShadow:
            "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(76 175 79 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        },
        {
          textOne: "Completed Vaccinations",
          textTwo: offlineFacilityData?.childern_with_completed_vacination,
          tabIcon: <CompletedIcon />,
          iconBackground:
            "linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))",
          boxShadow:
            "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(76 175 79 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        },
        {
          textOne: "Uncompleted Vaccinations",
          textTwo: offlineFacilityData?.childern_with_uncompleted_vacination,
          tabIcon: <UncompletedIcon />,
          iconBackground:
            "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))",
          boxShadow:
            "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(233 30 98 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        },

        {
          textOne: "Total No Of Children",
          textTwo: offlineFacilityData?.total_children_in_the_facility,
          tabIcon: <CountIcon />,
          iconBackground:
            "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
          boxShadow:
            "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(0 187 212 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        },
        {
          textOne: "Immunization Workers",
          textTwo: offlineFacilityData?.total_immunization_workers,
          tabIcon: <NurseIcon />,
          iconBackground:
            "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
          boxShadow:
            "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        },
      ];

  const tableTitleOne = "Facilities";
  const tableTitleTwo = "Babies Registered";
  const tableTitleThree = "Facility Workers";

  // const immunizationWorkers = facility.data?.user?.filter(
  //   (user) => user.user_type_id === "3"
  // );

  // const immunizationWorkers = navigator.online
  //   ? facility.data?.user?.filter((user) => user.user_type_id === "3")
  //   : offlineFacilityData.data?.user?.filter(
  //       (user) => user.user_type_id === "3"
  //     );

  // console.log({ immunizationWorkers });
  // console.log({ facility });

  const tableDataOne = {
    headings: ["Name", "Phone Number", "Email", "Action"],
    rows: agency?.agency?.facility.user
      ?.filter((user) => user.user_type_id === "3")
      .map((user) => {
        // if (user.user_type_id === "3") {
        return {
          dataOne: user.first_name + " " + user.last_name,
          dataTwo: user.phone_number,
          dataThree: user.email,
          dataFour: "/panel/personnel-data/",
          dataFive: user.id,
        };
        // } else {
        //   return "";
        // }
      }),
  };
  const tableDataThree = {
    headings: ["Name", "Address", "Reg Date", "Action"],
    rows: facilities.map((facility) => {
      // rows: agency?.data?.facility.map((facility) => {
      // if (user.user_type_id === "3") {
      return {
        dataOne: facility.name,
        dataTwo: facility.address,
        dataThree: facility.created_at.substr(0, 10),
        // dataThree: user.email,
        dataFour: "/panel/facility-details/",
        dataFive: facility.id,
      };
      // } else {
      //   return "";
      // }
    }),
  };

  ///////////////////////////////////// FOR DISPLAYING IMMUNIZATION WORKERS ON THE TABLE
  // const tableDataThree = {
  //   headings: ["Name", "Phone Number", "Email", "Action"],
  //   rows: agency.data?.user
  //     ?.filter((user) => user.user_type_id === "4")
  //     .map((user) => {
  //       // if (user.user_type_id === "3") {
  //       return {
  //         dataOne: user.first_name + " " + user.last_name,
  //         dataTwo: user.phone_number,
  //         dataThree: user.email,
  //         dataFour: "/panel/personnel-data/",
  //         dataFive: user.id,
  //       };
  //       // } else {
  //       //   return "";
  //       // }
  //     }),
  // };

  // const childDataDisplayed = facility?.data?.child;

  const childDataDisplayed = navigator.onLine
    ? facility?.data?.child
    : offlineFacilityData?.data?.child;

  const tableDataTwo = {
    headings: ["Name", "VAC Number", "DOB", "Action"],
    rows: childDataDisplayed?.map((child) => {
      return {
        dataOne: child.first_name + " " + child.last_name,
        dataTwo: child.child_vac_id,
        dataThree: child.date_of_birth,
        dataFour: "/panel/child-details/",
        dataFive: child.id,
      };
    }),
  };

  const closeForm = () => {
    setShowRegChildForm(!showRegChildForm);
  };
  const closeFacilityWorkerForm = () => {
    setShowRegAdminForm(!showRegAdminForm);
  };
  const closeimworkerForm = () => {
    setShowRegImWorkerForm(!showRegImWorkerForm);
  };

  const closeAddFacilityForm = () => {
    setShowAddFacility(!showAddFacility);
  };

  let takenData;

  if (takenChartData) {
    takenData = {
      labels: [...takenChartData?.labels],
      datasets: [
        {
          label: takenChartData?.datasets[0].label,
          data: [...takenChartData?.datasets[0].data],
          backgroundColor: [
            "rgb(255, 99, 132)",
            "rgb(54, 162, 235)",
            "rgb(255, 205, 86)",
          ],
          // barPercentage: 0.5,
          // barThickness: 6,
          // maxBarThickness: 8,
          // minBarLength: 2,
          // title: ["title"],
          borderWidth: 1,
          hoverOffset: 4,
        },
      ],
    };
  }
  let missedData;

  if (missedChartData) {
    missedData = {
      labels: [...missedChartData?.labels],
      datasets: [
        {
          label: missedChartData?.datasets[0].label,
          data: [...missedChartData?.datasets[0].data],
          backgroundColor: [
            "rgb(255, 99, 132)",
            "rgb(54, 162, 235)",
            "rgb(255, 205, 86)",
          ],
          // barPercentage: 0.5,
          // barThickness: 6,
          // maxBarThickness: 8,
          // minBarLength: 2,
          // title: ["title"],
          borderWidth: 1,
          hoverOffset: 4,
        },
      ],
    };
  }

  let pendingData;

  if (pendingChartData) {
    pendingData = {
      labels: [...pendingChartData?.labels],
      datasets: [
        {
          label: pendingChartData?.datasets[0].label,
          data: [...pendingChartData?.datasets[0].data],
          backgroundColor: [
            "rgb(255, 99, 132)",
            "rgb(54, 162, 235)",
            "rgb(255, 205, 86)",
          ],
          //         // barPercentage: 0.5,
          //         // barThickness: 6,
          //         // maxBarThickness: 8,
          //         // minBarLength: 2,
          //         // title: ["title"],
          borderWidth: 1,
          hoverOffset: 4,
        },
      ],
    };
  }

  let genderData;

  if (genderChartData) {
    genderData = {
      labels: [...genderChartData?.labels],
      datasets: [
        {
          label: genderChartData?.datasets[0].label,
          data: [...genderChartData?.datasets[0].data],

          backgroundColor: [
            "rgb(255, 99, 132)",
            "rgb(54, 162, 235)",
            "rgb(255, 205, 86)",
          ],
          // barPercentage: 0.5,
          // barThickness: 6,
          // maxBarThickness: 8,
          // minBarLength: 2,
          // title: ["title"],
          borderWidth: 1,
          hoverOffset: 4,
        },
      ],
    };
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,

    ticks: {
      precision: 0,
    },
    plugins: {
      title: {
        display: true,
        text: "Data on Taken Vaccines Per Vaccine (In Percentage)",

        // displayColors: false,
        // position: "top",
      },

      legend: {
        display: false,
      },

      // subtitle: {
      //   display: true,
      //   text: "Chart Subtitle",
      //   color: "blue",
      //   font: {
      //     size: 12,
      //     family: "tahoma",
      //     weight: "normal",
      //     style: "italic",
      //   },
      //   padding: {
      //     bottom: 10,
      //   },
      // },
    },
  };

  const optionsTwo = {
    responsive: true,
    maintainAspectRatio: false,
    ticks: {
      precision: 0,
    },
    plugins: {
      title: {
        display: true,
        text: "Data On Missed Vaccines Per Vaccine (In Percentage)",
        // displayColors: false,
        // position: "top",
      },

      legend: {
        display: false,
      },
      // subtitle: {
      //   display: true,
      //   text: "Chart Subtitle",
      //   color: "blue",
      //   font: {
      //     size: 12,
      //     family: "tahoma",
      //     weight: "normal",
      //     style: "italic",
      //   },
      //   padding: {
      //     bottom: 10,
      //   },
      // },
    },
  };
  const optionsThree = {
    responsive: true,
    maintainAspectRatio: false,
    ticks: {
      precision: 0,
    },
    plugins: {
      title: {
        display: true,
        text: "Data On Registered Children's Genders (In Percentage)",
        // displayColors: false,
        // position: "top",
      },

      legend: {
        display: false,
      },
      // subtitle: {
      //   display: true,
      //   text: "Chart Subtitle",
      //   color: "blue",
      //   font: {
      //     size: 12,
      //     family: "tahoma",
      //     weight: "normal",
      //     style: "italic",
      //   },
      //   padding: {
      //     bottom: 10,
      //   },
      // },
    },
  };

  const optionsFour = {
    responsive: true,
    maintainAspectRatio: false,
    ticks: {
      precision: 0,
    },
    plugins: {
      title: {
        display: true,
        text: "Data On Registered Children's Genders (In Percentage)",
        //       // displayColors: false,
        //       // position: "top",
      },

      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="details__components-container">
      {/* <div className="notification__container">
        <Notification />
      </div> */}
      {loading ? (
        <div className="loader__container">
          <ClimbingBoxLoader color="#ffffff" loading={loading} size={15} />
        </div>
      ) : null}
      {showRegChildForm ? <AddChild closeForm={closeForm} /> : null}
      {showRegAdminForm ? (
        <AddFacilityWorker
          closeAdminForm={closeFacilityWorkerForm}
          formTitle="Add Facility Worker"
          regUrl="/api/register/health_worker"
        />
      ) : null}
      {showRegImWorkerForm ? (
        <AddImWorker
          closeAdminForm={closeimworkerForm}
          formTitle="Add Immunization worker"
          regUrl="/api/register/immunization_worker"
        />
      ) : null}
      {showAddFacility ? (
        <AddFacility
          closeForm={closeAddFacilityForm}
          // formTitle="Add F"
          // regUrl="/api/register/i"
        />
      ) : null}
      <p className="facility__name">{facility?.data?.name}</p>
      <div className="tab__container">
        {dashboardTabData.map((data) => {
          return (
            <DashboardTab
              key={uuidv4()}
              textOne={data.textOne}
              textTwo={data.textTwo}
              icon={data.tabIcon}
              iconBackground={data.iconBackground}
              boxShadow={data.boxShadow}
              // cardUrl="/panel/missed-vacs-list"
            />
          );
        })}
      </div>
      {/* <Online> */}
      {/* {navigator.onLine ? ( */}
      <>
        <DrilldownGraph
          drillDownData={drillDownData}
          title="Vaccination Data Per Facility"
        />
        <FacilitiesVacsGraph />
        <FacilityChildrenGraph data={childrenGraphData} />
        <ZeroDoseGraph />
        <UnimunizedGraph />
        <DropOutGraph />
        {/* {takenChartData ? (
          <>
            <p className="chart__title">Vaccines Taken</p>
            <div className="charts__container">
              <Bar data={takenData} options={options} />
            </div>
          </>
        ) : null} */}
        {/* {missedChartData ? (
          <>
            <p className="chart__title">Vaccines Missed</p>
            <div className="charts__container">
              <Bar data={missedData} options={optionsTwo} />
            </div>
          </>
        ) : null} */}
        {/* 
        {pendingChartData ? (
          <>
            <p className="chart__title">Vaccines Pending</p>
            <div className="charts__container">
              <Bar data={pendingData} options={optionsFour} />
            </div>
          </>
        ) : null} */}
        {/* {genderChartData ? (
          <>
            <p className="chart__title">Gender Distribution</p>
            <div className="charts__container">
              <Pie data={genderData} options={optionsThree} />
            </div>
          </>
        ) : null} */}
      </>
      {/* ) : null} */}
      {/* </Online> */}

      {/* {navigator.onLine ? ( */}

      <Table
        title={tableTitleOne}
        tableData={tableDataThree}
        buttonTitle={"Add Facility"}
        buttonIcon={<AddUserIcon className="add__user-icon" />}
        buttonUrl="/panel/add-facility"
        buttonFunction={setShowAddFacility}
        buttonFunctionValue={showAddFacility}
        marginTop="6rem"
      />

      {/* <Table
        title={tableTitleThree}
        tableData={tableDataOne}
        buttonTitle={"Add Facility Worker"}
        buttonIcon={<AddUserIcon className="add__user-icon" />}
        buttonUrl="/panel/reg-facility-worker"
        buttonFunction={setShowRegAdminForm}
        buttonFunctionValue={showRegAdminForm}
        marginTop="6rem"
      /> */}
      {/* ) : null} */}
      {/* {navigator.onLine ? ( */}
      {/* <Table
        title={tableTitleOne}
        tableData={tableDataThree}
        buttonTitle={"Add Immunization Worker"}
        buttonIcon={<AddUserIcon className="add__user-icon" />}
        buttonUrl="/panel/reg-immunization-worker"
        buttonFunction={setShowRegImWorkerForm}
        buttonFunctionValue={showRegImWorkerForm}
        marginTop="6rem"
      /> */}

      {/* ) : null} */}

      {/* <Table
        title={tableTitleTwo}
        tableData={tableDataTwo}
        buttonTitle="Add Child"
        buttonIcon={<AddUserIcon className="add__user-icon" />}
        buttonUrl="/panel/add-child"
        marginTop="6rem"
        buttonFunction={setShowRegChildForm}
        buttonFunctionValue={showRegChildForm}
      /> */}
    </div>
  );
};

export default AdminDashboard;
