import { useState, useEffect, useMemo } from "react";
import PaystackHook from "../PaystackHook/PaystackHook";
import axios from "axios";
import "./manageSub.css";
import { api } from "../..";

const ManageSub = () => {
  const [pricePlans, setPricePlans] = useState([]);
  const [loading, setLoading] = useState(false);

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  useEffect(() => {
    getPlans();
  }, []);

  const getPlans = () => {
    setLoading(true);
    api
      .get("/api/price_plan", config)
      .then((res) => {
        setLoading(false);
        setPricePlans(res?.data.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div className="manage__sub-container">
      {pricePlans?.map((plan, index) => {
        return (
          <span className="state__name-and-edit--CTA">
            <p className="state__name">
              {index + 1}. {plan.id}
            </p>
            <PaystackHook
              amount={plan.monthly_price}
              pricePlanId={plan.id}
              facilityId={userDetails.facility_id}
            />
          </span>
        );
      })}
    </div>
  );
};

export default ManageSub;
