import { useState, useMemo, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ReactComponent as EyeLogo } from "../../assets/icons/eye-icon.svg";
import { ReactComponent as EyeOffLogo } from "../../assets/icons/eye-off.svg";
import { ReactComponent as LockLogo } from "../../assets/icons/lock-icon.svg";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as XIcon } from "../../assets/icons/close-icon.svg";
import { api } from "../..";
// import Loading from "../Loading/Loading.component";

const PWD_REGEX = /^.{6,24}$/;
// const REGADMINURL = "/api/admin/register";

const AddImWorker = ({ closeAdminForm, formTitle }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("password");

  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [lga, setLga] = useState("");
  const [emailFocus, setEmailFocus] = useState(false);

  const [address, setAddress] = useState("");
  const [addressFocus, setAddressFocus] = useState(false);

  const [facility, setFacility] = useState("");

  const [facilityId, setFacilityId] = useState("");

  const [firstName, setFirstName] = useState("");
  const [firstNameFocus, setFirstNameFocus] = useState("");

  const [lastName, setLastName] = useState("");
  const [lastNameFocus, setLastNameFocus] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberFocus, setPhoneNumberFocus] = useState(false);

  const [gender, setGender] = useState("");
  const [genderFocus, setGenderFocus] = useState("");

  const [dateOfBirth, setDateOfBirth] = useState("");
  const [cadre, setCadre] = useState("");
  const [agency, setAgency] = useState("");

  const [states, setStates] = useState();
  const [lgas, setLgas] = useState();
  const [cadres, setCadres] = useState([]);
  const [agencies, setAgencies] = useState([]);

  const [stateId, setStateId] = useState("");
  const [lgaId, setLgaId] = useState("");
  const [facilities, setFacilities] = useState();

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  const emailRef = useRef();
  const errRef = useRef();

  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  useEffect(() => {
    api.get("/api/states", config).then((res) => {
      setStates(res.data);
    });
    api.get("/api/cadre", config).then((res) => {
      setCadres(res.data.data);
    });

    api.get("/api/agency", config).then((res) => {
      setAgencies(res.data.data);
    });
  }, []);

  useEffect(() => {
    api.get(`/api/lga/${stateId}`, config).then((res) => {
      setLgas(res.data.data);
    });
  }, [stateId]);

  useEffect(() => {
    api
      .get(
        `/api/facility`,
        {
          params: {
            state_id: stateId,
            lga_id: lgaId,
          },
        },
        config
      )
      .then((res) => {
        setFacilities(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [lgaId]);

  const togglePasswordType = () => {
    setShowPassword(!showPassword);
    setType(showPassword ? "text" : "password");
  };

  const location = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data, e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await api.post(
        "/api/register/immunization_worker",
        {
          phone_number: phoneNumber,
          gender: gender,
          first_name: firstName,
          last_name: lastName,
          email: email,
          address: address,
          password: pwd,
          facility_id: facilityId,
          cadre_id: cadre,
          date_of_birth: dateOfBirth,
          // agency_id: agency,
          state_id: stateId,
          lga_id: lgaId,
        },
        config
      );
      toast.success("User added successfully!");

      setSuccess(true);
      setPhoneNumber("");
      setAddress("");
      setGender("");
      setFirstName("");
      setLastName("");
      setEmail("");
      setShowPassword("");
      setState("");
      setLga("");
      setLgaId("");
      setFacilityId("");
      setDateOfBirth("");
      setAgency("");
      setCadre("");
      setLoading(false);
    } catch (error) {
      if (!error?.response) {
        toast.error("No Server Response");
      } else if (error.response?.status === 400) {
        toast.error("User Already Exists");
      } else {
        toast.error("Registration Failed");
      }

      // errRef.current.focus();
      setLoading(false);
    }
  };

  useEffect(() => {
    const result = PWD_REGEX.test(pwd);
    setValidPwd(result);
    const match = pwd === matchPwd;
    setValidMatch(match);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setErrMsg("");
  }, [pwd, matchPwd]);

  return (
    <>
      <div className="addChild__container">
        {/* {loading ? <div className="overlay"></div> : null} */}
        {/* {loading ? <Loading type="cylon" color="rgb(101, 93, 138)" /> : null} */}
        <div className="addUserForm__content--container">
          <div className="createCompLogin__form--container">
            <span className="createCompAccNavLogo-and-links">
              {/* <FezzantLogo /> */}
            </span>
            <div className="form-and-text__container--admin">
              <span
                className="createCompLogin__form--texts"
                // onClick={() => (localStorage.token ? navigate("/panel") : "")}
              >
                {/* {loading ? (
                    <Loading type="cylon" color="rgb(101, 93, 138)" />
                  ) : null} */}
                <span className="header-and-close--icon">
                  <h2
                    className="createCompLogin__formheading"
                    style={{ marginRight: "3rem" }}
                  >
                    Add Immunization Worker
                  </h2>

                  <XIcon
                    style={{ fill: "#F95959", cursor: "pointer" }}
                    onClick={() => closeAdminForm()}
                  />
                </span>
                <p
                  className={errorMessage ? "errorMessage" : "offScreen"}
                  aria-live="assertive"
                >
                  {errorMessage}
                </p>

                <p className="createCompLogin__form-sub--text">
                  Fill the forms below to create your account.
                </p>
              </span>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="createCompLogin__form"
              >
                <label
                  htmlFor="firstName"
                  className="createCompLogin__email--label"
                >
                  First Name
                </label>
                <div className="createCompLogin__email-and-icon">
                  <input
                    type="text"
                    className={
                      errorMessage
                        ? "email--input"
                        : "createCompLogin__email--input"
                    }
                    id="firstName"
                    autoComplete="off"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                    required
                    aria-describedby="uidnote"
                    onFocus={() => setFirstNameFocus(true)}
                    onBlur={() => setFirstNameFocus(false)}
                    // {...register("firstName", { required: true })}
                  />
                  {errors.firstName && (
                    <p
                      style={{
                        color: "#F95959",
                        marginTop: "1rem",
                      }}
                    >
                      First name is required
                    </p>
                  )}
                  {/* <NameIcon className="mail__icon" /> */}
                </div>
                <label
                  htmlFor="LastName"
                  className="createCompLogin__email--label"
                >
                  Last Name
                </label>
                <div className="createCompLogin__email-and-icon">
                  <input
                    type="text"
                    className={
                      errorMessage
                        ? "email--input"
                        : "createCompLogin__email--input"
                    }
                    id="lastName"
                    autoComplete="off"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    required
                    aria-describedby="uidnote"
                    onFocus={() => setLastNameFocus(true)}
                    onBlur={() => setLastNameFocus(false)}
                    // {...register("lastName", { required: true })}
                  />
                  {errors.lastName && (
                    <p
                      style={{
                        color: "#F95959",
                        marginTop: "1rem",
                      }}
                    >
                      Last name is required
                    </p>
                  )}
                  {/* <NameIcon className="mail__icon" /> */}
                </div>

                <label
                  htmlFor="gender"
                  className="createCompLogin__email--label"
                >
                  Phone Number
                </label>
                <div className="createCompLogin__email-and-icon">
                  <input
                    className={
                      errorMessage
                        ? "email--input"
                        : "createCompLogin__email--input"
                    }
                    type="text"
                    id="phoneNumber"
                    autoComplete="off"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    value={phoneNumber}
                    required
                    aria-describedby="uidnote"
                    onFocus={() => setPhoneNumberFocus(true)}
                    onBlur={() => setPhoneNumberFocus(false)}
                    // {...register("company", { required: true })}
                  />
                  {errors.phoneNumber && (
                    <p
                      style={{
                        color: "#F95959",
                        marginTop: "1rem",
                      }}
                    >
                      Phone number is required
                    </p>
                  )}

                  {/* <CompnyIcon className="mail__icon" /> */}
                </div>
                <label
                  htmlFor="email"
                  className="createCompLogin__email--label"
                >
                  Email
                </label>
                <div className="createCompLogin__email-and-icon">
                  <input
                    className={
                      errorMessage
                        ? "email--input"
                        : "createCompLogin__email--input"
                    }
                    type="email"
                    id="email"
                    autoComplete="off"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    // required
                    aria-describedby="uidnote"
                    onFocus={() => setEmailFocus(true)}
                    onBlur={() => setEmailFocus(false)}
                    // {...register("company", { required: true })}
                  />
                  {errors.email && (
                    <p
                      style={{
                        color: "#F95959",
                        marginTop: "1rem",
                      }}
                    >
                      Email is required
                    </p>
                  )}

                  {/* <CompnyIcon className="mail__icon" /> */}
                </div>

                <label
                  htmlFor="gender"
                  className="createCompLogin__email--label"
                >
                  Gender
                </label>
                <div className="createCompLogin__email-and-icon">
                  <select
                    // name="countries"
                    id="gender"
                    className={
                      errorMessage
                        ? "email--input"
                        : "createCompLogin__email--input"
                    }
                    value={gender}
                    // required
                    onChange={(e) => setGender(e.target.value)}
                    onFocus={() => setGenderFocus(true)}
                    onBlur={() => setGenderFocus(false)}
                    // {...register("country", { required: true })}
                  >
                    <option value=""></option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                  {errors.gender && (
                    <p
                      style={{
                        color: "#F95959",
                        marginTop: "1rem",
                      }}
                    >
                      Please Select Your Gender
                    </p>
                  )}

                  {/* <CountryIcon className="mail__icon" /> */}
                </div>
                <label htmlFor="dob" className="addUserForm__password--label">
                  Date Of Birth
                </label>
                <div className="addUserForm__password-and-icons">
                  <input
                    type="date"
                    className={
                      errorMessage ? "email--input" : "addChild__email--input"
                    }
                    id="dob"
                    autoComplete="off"
                    onChange={(e) =>
                      setDateOfBirth(e.target.value.split("/").join("-"))
                    }
                    value={dateOfBirth}
                    // required
                    aria-describedby="uidnote"
                  />
                  {errorMessage && (
                    <p
                      style={{
                        color: "#F95959",
                        marginTop: "1rem",
                      }}
                    >
                      DOB is required
                    </p>
                  )}
                  {/* <NameIcon className="mail__icon" /> */}
                </div>
                <label
                  htmlFor="gender"
                  className="createCompLogin__email--label"
                >
                  Cadre
                </label>
                <div className="createCompLogin__email-and-icon">
                  <select
                    // name="countries"
                    id="gender"
                    className={
                      errorMessage
                        ? "email--input"
                        : "createCompLogin__email--input"
                    }
                    value={cadre}
                    // required
                    onChange={(e) => setCadre(e.target.value)}

                    // {...register("country", { required: true })}
                  >
                    <option value=""></option>
                    {cadres?.map((cadre) => {
                      return <option value={cadre.id}>{cadre.title}</option>;
                    })}
                    {/* <option value="Female">Female</option> */}
                  </select>
                  {errors.gender && (
                    <p
                      style={{
                        color: "#F95959",
                        marginTop: "1rem",
                      }}
                    >
                      Please Select Your Gender
                    </p>
                  )}

                  {/* <CountryIcon className="mail__icon" /> */}
                </div>

                <label
                  htmlFor="address"
                  className="createCompLogin__email--label"
                >
                  Address
                </label>
                <div className="createCompLogin__email-and-icon">
                  <input
                    className={
                      errorMessage
                        ? "email--input"
                        : "createCompLogin__email--input"
                    }
                    type="text"
                    id="address"
                    autoComplete="off"
                    onChange={(e) => setAddress(e.target.value)}
                    value={address}
                    // required
                    aria-describedby="uidnote"
                    onFocus={() => setAddressFocus(true)}
                    onBlur={() => setAddressFocus(false)}
                    // {...register("company", { required: true })}
                  />
                  {/* {errors.email && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      >
                         is required
                      </p>
                    )} */}

                  {/* <CompnyIcon className="mail__icon" /> */}
                </div>
                <label
                  htmlFor=" gender"
                  className="addUserForm__password--label"
                >
                  State
                </label>
                <div className="addUserForm__password-and-icons">
                  <select
                    id="state"
                    className={
                      errorMessage ? "email--input" : "addChild__email--input"
                    }
                    value={stateId}
                    required
                    onChange={(e) => setStateId(e.target.value)}
                    style={{
                      width: "100%",
                      marginTop: "1.5rem",
                      marginBottom: "1.5rem",
                    }}
                  >
                    <option value=""></option>
                    {states?.map((state) => {
                      return <option value={state.id}>{state.name} </option>;
                    })}
                  </select>
                  {errorMessage && (
                    <p
                      style={{
                        color: "#F95959",
                        marginTop: "1rem",
                      }}
                    >
                      State is required
                    </p>
                  )}
                </div>
                <label
                  htmlFor=" gender"
                  className="addUserForm__password--label"
                >
                  LGA
                </label>
                <div className="addUserForm__password-and-icons">
                  <select
                    id="Lga"
                    className={
                      errorMessage ? "email--input" : "addChild__email--input"
                    }
                    value={lgaId}
                    required
                    onChange={(e) => setLgaId(e.target.value)}
                    style={{
                      width: "100%",
                      marginTop: "1.5rem",
                      marginBottom: "1.5rem",
                    }}
                  >
                    <option value=""></option>
                    {lgas?.map((lga) => {
                      return <option value={lga.id}>{lga.name}</option>;
                    })}
                  </select>
                  {errorMessage && (
                    <p
                      style={{
                        color: "#F95959",
                        marginTop: "1rem",
                      }}
                    >
                      LGA is required
                    </p>
                  )}
                </div>
                {/* <label
                  htmlFor="gender"
                  className="createCompLogin__email--label"
                >
                  Agency
                </label>
                <div className="createCompLogin__email-and-icon">
                  <select
                   
                    id="gender"
                    className={
                      errorMessage
                        ? "email--input"
                        : "createCompLogin__email--input"
                    }
                    value={agency}
                    required
                    onChange={(e) => setAgency(e.target.value)}

                  
                  >
                    <option value=""></option>
                    {agencies?.map((agency) => {
                      return <option value={agency.id}>{agency.name}</option>;
                    })}
                    
                  </select>
                  {errors.gender && (
                    <p
                      style={{
                        color: "#F95959",
                        marginTop: "1rem",
                      }}
                    >
                      Please Select Agency
                    </p>
                  )}

                 
                </div> */}
                {/* {location.pathname === "/panel/personnel-dashboard" ? null : (
                  <>
                    <label
                      htmlFor="facility"
                      className="createCompLogin__email--label"
                    >
                      Facility
                    </label>
                    <div className="createCompLogin__email-and-icon">
                      <select
                        // name="countries"
                        id="facility"
                        className={
                          errorMessage
                            ? "email--input"
                            : "createCompLogin__email--input"
                        }
                        value={facilityId}
                        required
                        onChange={(e) => setFacilityId(e.target.value)}
                        // {...register("country", { required: true })}
                      >
                        <option value=""></option>
                        {facilities?.map((facility) => {
                          return (
                            <option value={facility.id}>{facility.name}</option>
                          );
                        })}
                      </select>
                      {errors.facility && (
                        <p
                          style={{
                            color: "#F95959",
                            marginTop: "1rem",
                          }}
                        >
                          Please Select a Facility
                        </p>
                      )}

                 
                    </div>
                  </>
                )} */}

                <label
                  htmlFor="password"
                  className="createCompLogin__password--label"
                >
                  Password
                </label>
                <div className="createCompLogin__password-and-icons">
                  <input
                    type={type}
                    className={
                      errorMessage || (pwdFocus && !validPwd)
                        ? "email--input"
                        : "createCompLogin__email--input"
                    }
                    id="password"
                    // value={pwd}
                    onChange={(e) => setPwd(e.target.value)}
                    autoComplete="false"
                    required
                    aria-invalid={validPwd ? "false" : "true"}
                    aria-describedby="pwdnote"
                    onFocus={() => setPwdFocus(true)}
                    onBlur={() => setPwdFocus(false)}
                    // {...register("password", { required: true })}
                  />
                  <p
                    id="pwdnote"
                    className={
                      pwdFocus && !validPwd ? "instruction" : "offScreen"
                    }
                  >
                    6 to 24 characters.
                    {/* <br />
                    Must include uppercase and lowercase letters, a number and a
                    special character.
                    <br />
                    Allowed special characters:{" "}
                    <span aria-label="exclamation mark">!</span>{" "}
                    <span aria-label="at symbol">@</span>{" "}
                    <span aria-label="hashtag">#</span>{" "}
                    <span aria-label="dollar sign">$</span>{" "}
                    <span aria-label="percent">%</span> */}
                  </p>

                  {type === "password" ? (
                    <EyeLogo
                      className="eye__icon"
                      onClick={togglePasswordType}
                    />
                  ) : (
                    <EyeOffLogo
                      className="eye__icon"
                      onClick={togglePasswordType}
                    />
                  )}
                  {/* <LockLogo className="lock__icon" /> */}
                </div>
                <label
                  htmlFor="confirm_pwd"
                  className="createCompLogin__password--label"
                >
                  Confirm Password
                </label>
                <div className="createCompLogin__password-and-icons">
                  <input
                    type={type}
                    id="confirm_pwd"
                    value={matchPwd}
                    onChange={(e) => setMatchPwd(e.target.value)}
                    autoComplete="false"
                    required
                    aria-invalid={validMatch ? "false" : "true"}
                    aria-describedby="confirmNote"
                    onFocus={() => setMatchFocus(true)}
                    onBlur={() => setMatchFocus(false)}
                    className={
                      errorMessage || (matchFocus && !validMatch)
                        ? "email--input"
                        : "createCompLogin__email--input"
                    }

                    // {...register("confirmPassword", { required: true })}
                  />
                  <p
                    id="confirmnote"
                    className={!validMatch ? "instruction" : "offScreen"}
                  >
                    Must match the first password input field.
                  </p>

                  {type === "password" ? (
                    <EyeLogo
                      className="eye__icon"
                      onClick={togglePasswordType}
                    />
                  ) : (
                    <EyeOffLogo
                      className="eye__icon"
                      onClick={togglePasswordType}
                    />
                  )}
                  {/* <LockLogo className="lock__icon" /> */}
                </div>
                {/* 
                  <p className="forgot__password">Forgot Your Password</p> */}

                <button
                  disabled={!validPwd || !validMatch ? true : false}
                  className="createCompLogin__submit--CTA"
                >
                  Create Account
                </button>
              </form>
            </div>
          </div>
          {/* <div className="createCompLogin__image--container">
            <div className="createCompLogin__image--box">
         
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default AddImWorker;
