import DashboardTab from "../../DashboardTab/DashboardTab";
import { Outlet } from "react-router-dom";
import UserAccountDetails from "../../HealtcareWorker/HealthCareWorker";
import { useLocation } from "react-router-dom";
import UserDetailsNav from "../../UserDetailsNav/UserDetailsNav";
import "./dashboard.css";

const Dashboard = ({
  superAdminSideBarLinks,
  adminSideBarLinks,
  facilityHealthWorkerSideBarLinks,
  imWorkerSideBarLinks,
  parentSideBarLinks,
}) => {
  const location = useLocation();

  return (
    <div className="dashboard__container">
      <UserDetailsNav
        pathname={location.pathname}
        superAdminSideBarLinks={superAdminSideBarLinks}
        adminSideBarLinks={adminSideBarLinks}
        facilityHealthWorkerSideBarLinks={facilityHealthWorkerSideBarLinks}
        parentSideBarLinks={parentSideBarLinks}
        imWorkerSideBarLinks={imWorkerSideBarLinks}
      />
      <Outlet />
    </div>
  );
};

export default Dashboard;
