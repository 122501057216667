import { useState } from "react";
import { toast } from "react-toastify";
import { ReactComponent as XIcon } from "../../assets/icons/close-icon.svg";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../..";

const UpdatePopulation = () => {
  const [population, setPopulation] = useState();
  const [year, setYear] = useState();
  const [facilityId, setFacilityId] = useState("");
  const [facilities, setFacilities] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const UpdatePopulation = () => {
    api
      .post("/api/population", { facility_id: facilityId, population, year })
      .then((res) => {
        // setRate("");
        toast.success("Population Updated Successfully!");
        setFacilityId("");
        setPopulation("");
        setYear("");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error Processing Request, please try again");
      });
  };

  useEffect(() => {
    const getFacilities = () => {
      api
        .get("/api/facilities")
        .then((res) => setFacilities(res.data.data.data))
        .catch((error) => console.log(error));
    };

    getFacilities();
  }, []);

  return (
    <div className="addChild__container">
      <div
        className="addUserForm__content--container"
        style={{ height: "100%", width: "100vw" }}
      >
        <div className="addState__form">
          <span className="form__heading-and-close--CTA">
            <p className="addUserForm__formheading">Update Population</p>
            <XIcon
              style={{ fill: "#F95959", cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </span>
          <label htmlFor="population" className="addUserForm__password--label">
            Population
          </label>
          <div
            className="addUserForm__password-and-icons"
            style={{ marginBottom: "1.5rem" }}
          >
            <input
              type="number"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              id="population"
              autoComplete="off"
              onChange={(e) => setPopulation(e.target.value)}
              value={population}
              style={{ width: "100%", marginTop: "1.5rem" }}
              required
              aria-describedby="uidnote"
            />
            {errorMessage && (
              <p
                style={{
                  color: "#F95959",
                  marginTop: "1rem",
                }}
              >
                Population is Required
              </p>
            )}
          </div>
          <label htmlFor="year" className="addUserForm__password--label">
            Year
          </label>
          <div
            className="addUserForm__password-and-icons"
            style={{ marginBottom: "1.5rem" }}
          >
            <input
              type="number"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              id="year"
              autoComplete="off"
              onChange={(e) => setYear(e.target.value)}
              value={year}
              style={{
                width: "100%",
                marginTop: "1.5rem",
                marginBottom: "1.5rem",
              }}
              required
              step="1"
              aria-describedby="uidnote"
            />
            {errorMessage && (
              <p
                style={{
                  color: "#F95959",
                  marginTop: "1rem",
                }}
              >
                Population is Required
              </p>
            )}
          </div>
          <label htmlFor="facility" className="addUserForm__password--label">
            Select Facility
          </label>
          <div className="addUserForm__password-and-icons">
            <select
              // name="countries"
              id="facility"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              value={facilityId}
              style={{ width: "100%", marginTop: "1.5rem" }}
              required
              onChange={(e) => setFacilityId(e.target.value)}
            >
              <option value=""></option>
              {facilities.map((facility) => (
                <option value={facility.id}>{facility.name}</option>
              ))}
            </select>
          </div>
          <button
            className="addUserForm__submit--CTA"
            onClick={() => UpdatePopulation()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdatePopulation;
