import { useState } from "react";
import { toast } from "react-toastify";
import { ReactComponent as XIcon } from "../../assets/icons/close-icon.svg";
import axios from "axios";
import { api } from "../..";

const AddUserType = ({ showAddUserType, setShowAddUserType }) => {
  const [userType, setUserType] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  const addUserType = () => {
    api
      .post("/api/usertype", { list: userType })
      .then((res) => {
        setUserType("");
        toast.success("User Type Added Successfully!");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error Processing Request, please try again");
      });
  };

  return (
    <div className="addChild__container">
      <div
        className="addUserForm__content--container"
        style={{ height: "100%", width: "100vw" }}
      >
        <div className="addState__form">
          <span className="form__heading-and-close--CTA">
            <p className="addUserForm__formheading">Add User Type</p>
            <XIcon
              style={{ fill: "#F95959", cursor: "pointer" }}
              onClick={() => setShowAddUserType(!showAddUserType)}
            />
          </span>
          <label htmlFor="state" className="addUserForm__password--label">
            User Type
          </label>
          <div className="addUserForm__password-and-icons">
            <input
              type="text"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              id="state"
              autoComplete="off"
              onChange={(e) => setUserType(e.target.value)}
              value={userType}
              style={{ width: "100%", marginTop: "1.5rem" }}
              required
              aria-describedby="uidnote"
            />
            {errorMessage && (
              <p
                style={{
                  color: "#F95959",
                  marginTop: "1rem",
                }}
              >
                User Type is Required
              </p>
            )}
          </div>
          <button
            className="addUserForm__submit--CTA"
            onClick={() => addUserType()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddUserType;
