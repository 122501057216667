import React, { useState, useEffect, useMemo } from "react";
import DashboardTab from "../DashboardTab/DashboardTab";
import { ReactComponent as NurseIcon } from "../../assets/icons/nurse-icon.svg";
import { ReactComponent as BabyIcon } from "../../assets/icons/baby-icon.svg";
import { ReactComponent as HospitalIcon } from "../../assets/icons/hospital-icon.svg";
import { ReactComponent as CountIcon } from "../../assets/icons/countingbabies.svg";
import { ReactComponent as TotalPatientsIcon } from "../../assets/icons/total-patients-icon.svg";
import { ReactComponent as CompletedIcon } from "../../assets/icons/complete-vac-icon.svg";
import { ReactComponent as UncompletedIcon } from "../../assets/icons/uncomplete-vac-icon.svg";
import Table from "../Table/Table";
import axios from "axios";
import AddChild from "../AddChild/AddChild";
import AddAdmin from "../AddAdmin/AddAdmin";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import Highcharts from "highcharts";
import { useAuthContext } from "../../contexts/AuthContext";
import { ReactComponent as AddUserIcon } from "../../assets/icons/add-user.svg";
import { ReactComponent as CareGiverIcon } from "../../assets/icons/care-giver-icon.svg";
import drilldown from "highcharts/modules/drilldown";
import { compareAsc, format } from "date-fns";
import "chart.js/auto";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import {
  Chart,
  ArcElement,
  Tooltip,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import "./healthCareWorker.css";
import { api } from "../../";
import { useSelector, useDispatch } from "react-redux";
import { storeFacilityDetails } from "../../redux/currentFacility/currentFacilityActions";
import AddImWorker from "../AddImWorker/AddImWorker";
import { storeUserDetails } from "../../redux/currentUser/currentUser.actions";
import Notification from "../Notification/Notification";
import { storeBabiesDetails } from "../../redux/babies/babiesActions";
import DrilldownGraph from "../DrillDownGraph/DrillDownGraph";
// import axios from "axios";

Chart.register(ArcElement, Tooltip, BarElement, CategoryScale, LinearScale);

const HealtcareWorker = () => {
  const [userData, setUserData] = useState();
  const [showRegChildForm, setShowRegChildForm] = useState(false);
  const [showRegAdminForm, setShowRegAdminForm] = useState(false);
  const [facilityChildren, setFacilityChildren] = useState([]);
  const [facilitImWorkers, setFacilityImWorkers] = useState([]);
  // const [facility, setFacility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [offlineUserData, setOfflineUserData] = useState();
  const [children, setChildren] = useState();
  const [upComingVacs, setUpcomingVacs] = useState([]);
  const [offlineFacilityData, setOfflineFacilityData] = useState();

  const [takenChartData, setTakenChartData] = useState();
  const [missedChartData, setMissedChartData] = useState();
  const [genderChartData, setGenderChartData] = useState();
  const [pendingChartData, setPendingChartData] = useState();
  const [drillDownData, setDrillDownData] = useState();

  // const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const userDetails = useSelector((state) => state.currentUser.currentUser);
  const facility = useSelector((state) => state.facility.currentFacility);
  const babies = useSelector((state) => state.babies.babies);

  const { setAuthDetails } = useAuthContext();

  const dispatch = useDispatch();

  useEffect(() => {
    // getChildren();
    getUserData();
    getUserFacility();
    getFacilityChildren();
    getFacilityImWorkers();
    getTakenChartData();
    getMissedChartData();
    getGenderChartData();
    getPendingChartData();
    // setTimeout(() => {
    // }, 5000);
    getDrillData();
  }, [showRegChildForm, showRegAdminForm]);

  const getDrillData = () => {
    api.get("/api/graph_facchildern").then((res) => {
      setDrillDownData(res.data);
    });
  };

  useEffect(() => {
    // getUserFacility();
    localStorage.setItem("offlineUserData", JSON.stringify(userDetails));

    setOfflineUserData(JSON.parse(localStorage.getItem("offlineUserData")));
    setAuthDetails(JSON.parse(localStorage.getItem("offlineUserData")));
  }, [userDetails, showRegAdminForm]);

  if (userDetails !== undefined) {
    localStorage.setItem("offlineUserData", JSON.stringify(userDetails));
  }

  const getUserData = () => {
    api
      .get(`/api/user/${userDetails?.id}`)
      .then((res) => {
        dispatch(storeUserDetails(res?.data?.user));
        // setUserData(res?.data);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };

  const getUserFacility = () => {
    setLoading(true);
    api
      .get(`api/facility/${userDetails?.facility_id}`)
      .then((res) => {
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getTakenChartData = () => {
    api
      .get(`/api/dash1?facility_id=${facility.id}`)
      .then((res) => {
        setTakenChartData(res.data[0]);
      })
      .catch((err) => console.log(err));
  };

  const getMissedChartData = () => {
    api
      .get(`/api/dash2?facility_id=${facility.id}`)
      .then((res) => {
        // console.log(res.data[0]);
        setMissedChartData(res.data[0]);
      })
      .catch((err) => console.log(err));
  };
  const getPendingChartData = () => {
    api
      .get(`/api/dash3?facility_id=${facility.id}`)
      .then((res) => {
        // console.log(res.data[0]);
        setPendingChartData(res.data[0]);
      })
      .catch((err) => console.log(err));
  };

  const getGenderChartData = () => {
    api
      .get(`/api/gender?facility_id=${facility.id}`)
      .then((res) => {
        setGenderChartData(res.data[0]);
      })
      .catch((err) => console.log(err));
  };

  const getFacilityChildren = () => {
    api
      .get(`api/facility_children`)
      .then((res) => {
        setFacilityChildren(res.data.data.data);
        dispatch(storeBabiesDetails(res.data.data.data));
      })
      .catch((err) => console.log(err));
  };

  const getFacilityImWorkers = () => {
    api
      .get(`api/facility_imworkers`)
      .then((res) => {
        setFacilityImWorkers(res.data.data.data);
      })
      .catch((err) => console.log(err));
  };

  // console.log({ takenChartData });
  // console.log({ missedChartData });
  // console.log({ genderChartData });

  // if (userData) {
  // const getUserFacility = () => {
  //   setLoading(true);

  //   api
  //     .get(`api/facility/${userData?.data?.facility_id}`)
  //     .then((res) => {
  //       // console.log(res);
  //       setFacility(res.data);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setLoading(false);
  //     });
  // };
  // }

  // const getChildren = () => {
  //   api
  //     .get("/api/childern")
  //     .then((res) => {
  //       setChildren(res.data.data);
  //       // console.log({ children });
  //       // console.log(res);
  //     })
  //     .catch((error) => console.log(error));
  // };

  // let offlineUserData;

  useEffect(() => {
    setOfflineFacilityData(
      JSON.parse(localStorage.getItem("offlineFacilityData"))
    );
  }, []);

  // const offlineUserData = JSON.parse(localStorage.getItem("offlineUserData"));
  // const offlineFacilityData = JSON.parse(
  //   localStorage.getItem("offlineFacilityData")
  // );

  if (!offlineFacilityData?.data) {
    localStorage.setItem("offlineFacilityData", JSON.stringify(facility));
  }

  // setTimeout(() => {
  //   offlineUserData = JSON.parse(localStorage.getItem("offlineUserData"));
  // }, 3000);

  // console.log({ offlineFacilityData });
  // console.log({ offlineUserData });

  // console.log(navigator);

  // const dashboardTabData = onlineTabData;

  const dashboardTabData = navigator.onLine
    ? [
        {
          textOne: "Children With Missed Dose",
          textTwo: facility?.children_with_missed_vaccination,
          tabIcon: <UncompletedIcon />,
          iconBackground:
            "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))",
          boxShadow:
            "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(233 30 98 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        },
        // {
        //   textOne: "Total Patients",
        //   textTwo: facility?.total_patients,
        //   tabIcon: <TotalPatientsIcon />,
        //   iconBackground:
        //     "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
        //   boxShadow:
        //     "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        // },
        {
          textOne: "Babies Registered",
          textTwo: userDetails?.total_children_under_user,
          tabIcon: <BabyIcon />,

          iconBackground:
            "linear-gradient(195deg, rgb(180 186 115), rgb(180 182 87))",
          boxShadow:
            "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(76 175 79 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        },

        {
          textOne: "Completed Vaccinations",
          textTwo: facility?.children_with_completed_vaccination,
          tabIcon: <CompletedIcon />,
          iconBackground:
            "linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))",
          boxShadow:
            "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(76 175 79 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        },
        {
          textOne: "Uncompleted Vaccinations",
          textTwo: facility?.children_with_uncompleted_vaccination,
          tabIcon: <UncompletedIcon />,

          iconBackground:
            "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
          boxShadow:
            "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        },

        {
          textOne: "Total No Of Children",
          textTwo: facility?.total_children_in_the_facility,
          tabIcon: <CountIcon />,
          iconBackground:
            "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
          boxShadow:
            "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(0 187 212 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        },
        {
          textOne: "Immunization Workers",
          textTwo: facility?.total_immunization_workers,
          tabIcon: <NurseIcon />,
          iconBackground:
            "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
          boxShadow:
            "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        },
        {
          textOne: "Caregivers",
          textTwo: facility?.total_primary_caregiver,
          tabIcon: <CareGiverIcon />,
          iconBackground:
            "linear-gradient(195deg, rgb(180 186 115), rgb(180 182 87))",
          boxShadow:
            "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(76 175 79 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        },
      ]
    : [
        {
          textOne: "Babies Registered",
          textTwo: offlineUserData?.child?.length,
          tabIcon: <BabyIcon />,
          iconBackground:
            "linear-gradient(195deg, rgb(180 186 115), rgb(180 182 87))",
          boxShadow:
            "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(76 175 79 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        },
        {
          textOne: "Completed Vaccinations",
          textTwo: offlineFacilityData?.children_with_completed_vaccination,
          tabIcon: <CompletedIcon />,
          iconBackground:
            "linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))",
          boxShadow:
            "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(76 175 79 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        },
        {
          textOne: "Uncompleted Vaccinations",
          textTwo: offlineFacilityData?.children_with_uncompleted_vaccination,
          tabIcon: <UncompletedIcon />,
          iconBackground:
            "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))",
          boxShadow:
            "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(233 30 98 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        },

        {
          textOne: "Total No Of Children",
          textTwo: offlineFacilityData?.total_children_in_the_facility,
          tabIcon: <CountIcon />,
          iconBackground:
            "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
          boxShadow:
            "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(0 187 212 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        },
        {
          textOne: "Immunization Workers",
          textTwo: offlineFacilityData?.total_immunization_workers,
          tabIcon: <NurseIcon />,
          iconBackground:
            "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
          boxShadow:
            "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
        },
      ];

  const tableTitleOne = "Immunization Workers";
  const tableTitleTwo = "Babies Registered";

  // const immunizationWorkers = navigator.online
  //   ? facility.data?.user?.filter((user) => user.user_type_id === "3")
  //   : offlineFacilityData.data?.user?.filter(
  //       (user) => user.user_type_id === "3"
  //     );

  // console.log({ immunizationWorkers });
  // console.log({ facility });

  const tableDataOne = {
    headings: ["Name", "Phone Number", "Email", "Action"],
    rows: facilitImWorkers.map((user) => {
      // if (user.user_type_id === "3") {
      return {
        dataOne: user.first_name + " " + user.last_name,
        dataTwo: user.phone_number,
        dataThree: user.email,
        dataFour: "/panel/personnel-data/",
        dataFive: user.id,
      };
      // } else {
      //   return "";
      // }
    }),
  };

  // const childDataDisplayed = facility?.data?.child;

  const childDataDisplayed = navigator.onLine
    ? babies
    : offlineFacilityData?.facility?.child;

  const tableDataTwo = {
    headings: ["Name", "VAC Number", "DOB", "Action"],
    rows: childDataDisplayed
      ?.filter((child, index) => index < 15)
      .map((child) => {
        return {
          dataOne: child.first_name + " " + child.last_name,
          dataTwo: child.child_vac_id,
          dataThree: child.date_of_birth,
          dataFour: "/panel/child-details/",
          dataFive: child.id,
        };
      }),
  };

  const closeForm = () => {
    setShowRegChildForm(!showRegChildForm);
  };
  const closeAdminForm = () => {
    setShowRegAdminForm(!showRegAdminForm);
  };

  let takenData;

  if (takenChartData) {
    takenData = {
      labels: [...takenChartData?.labels],
      datasets: [
        {
          label: takenChartData?.datasets[0].label,
          data: [...takenChartData?.datasets[0].data],
          backgroundColor: [
            "rgb(255, 99, 132)",
            "rgb(54, 162, 235)",
            "rgb(255, 205, 86)",
          ],
          // barPercentage: 0.5,
          // barThickness: 6,
          // maxBarThickness: 8,
          // minBarLength: 2,
          // title: ["title"],
          borderWidth: 1,
          hoverOffset: 4,
        },
      ],
    };
  }
  let missedData;

  if (missedChartData) {
    missedData = {
      labels: [...missedChartData?.labels],
      datasets: [
        {
          label: missedChartData?.datasets[0].label,
          data: [...missedChartData?.datasets[0].data],
          backgroundColor: [
            "rgb(255, 99, 132)",
            "rgb(54, 162, 235)",
            "rgb(255, 205, 86)",
          ],
          //         // barPercentage: 0.5,
          //         // barThickness: 6,
          //         // maxBarThickness: 8,
          //         // minBarLength: 2,
          //         // title: ["title"],
          borderWidth: 1,
          hoverOffset: 4,
        },
      ],
    };
  }
  let pendingData;

  if (pendingChartData) {
    pendingData = {
      labels: [...pendingChartData?.labels],
      datasets: [
        {
          label: pendingChartData?.datasets[0].label,
          data: [...pendingChartData?.datasets[0].data],
          backgroundColor: [
            "rgb(255, 99, 132)",
            "rgb(54, 162, 235)",
            "rgb(255, 205, 86)",
          ],
          //         // barPercentage: 0.5,
          //         // barThickness: 6,
          //         // maxBarThickness: 8,
          //         // minBarLength: 2,
          //         // title: ["title"],
          borderWidth: 1,
          hoverOffset: 4,
        },
      ],
    };
  }
  let genderData;

  if (genderChartData) {
    genderData = {
      labels: [...genderChartData?.labels],
      datasets: [
        {
          label: genderChartData?.datasets[0].label,
          data: [...genderChartData?.datasets[0].data],

          backgroundColor: [
            "rgb(255, 99, 132)",
            "rgb(54, 162, 235)",
            "rgb(255, 205, 86)",
          ],
          //         // barPercentage: 0.5,
          //         // barThickness: 6,
          //         // maxBarThickness: 8,
          //         // minBarLength: 2,
          //         // title: ["title"],
          borderWidth: 1,
          hoverOffset: 4,
        },
      ],
    };
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,

    ticks: {
      precision: 0,
    },
    plugins: {
      title: {
        display: true,
        text: "Data on Taken Vaccines Per Vaccine (In Percentage)",

        //       // displayColors: false,
        //       // position: "top",
      },

      legend: {
        display: false,
      },

      //     // subtitle: {
      //     //   display: true,
      //     //   text: "Chart Subtitle",
      //     //   color: "blue",
      //     //   font: {
      //     //     size: 12,
      //     //     family: "tahoma",
      //     //     weight: "normal",
      //     //     style: "italic",
      //     //   },
      //     //   padding: {
      //     //     bottom: 10,
      //     //   },
      //     // },
    },
  };

  const optionsTwo = {
    responsive: true,
    maintainAspectRatio: false,
    ticks: {
      precision: 0,
    },
    plugins: {
      title: {
        display: true,
        text: "Data On Missed Vaccines Per Vaccine (In Percentage)",
        //       // displayColors: false,
        //       // position: "top",
      },

      legend: {
        display: false,
      },
      //     // subtitle: {
      //     //   display: true,
      //     //   text: "Chart Subtitle",
      //     //   color: "blue",
      //     //   font: {
      //     //     size: 12,
      //     //     family: "tahoma",
      //     //     weight: "normal",
      //     //     style: "italic",
      //     //   },
      //     //   padding: {
      //     //     bottom: 10,
      //     //   },
      //     // },
    },
  };
  const optionsThree = {
    responsive: true,
    maintainAspectRatio: false,
    ticks: {
      precision: 0,
    },
    plugins: {
      title: {
        display: true,
        text: "Data On Registered Children's Genders (In Percentage)",
        //       // displayColors: false,
        //       // position: "top",
      },

      legend: {
        display: false,
      },
    },
  };
  const optionsFour = {
    responsive: true,
    maintainAspectRatio: false,
    ticks: {
      precision: 0,
    },
    plugins: {
      title: {
        display: true,
        text: "Data On Registered Children's Genders (In Percentage)",
        //       // displayColors: false,
        //       // position: "top",
      },

      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="details__components-container">
      <div
        className="baby__image-and-notification"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          width: "100%",
          marginBottom: "2rem",
        }}
      >
        <p className="tab__userName">{facility?.name}</p>

        <Notification />
      </div>
      {loading ? (
        <div className="loader__container">
          <ClimbingBoxLoader color="#ffffff" loading={loading} size={15} />
        </div>
      ) : null}
      {showRegChildForm ? <AddChild closeForm={closeForm} /> : null}
      {showRegAdminForm ? (
        <AddImWorker
          closeAdminForm={closeAdminForm}
          formTitle="Add Immunization Worker"
        />
      ) : null}
      <p className="facility__name">{facility?.data?.name}</p>
      <div className="tab__container">
        {dashboardTabData.map((data) => {
          return (
            <DashboardTab
              textOne={data.textOne}
              textTwo={data.textTwo}
              icon={data.tabIcon}
              iconBackground={data.iconBackground}
              boxShadow={data.boxShadow}
            />
          );
        })}
      </div>
      {/* <Online> */}
      {takenChartData ? (
        <>
          <p className="chart__title">Vaccines Taken</p>
          <div className="charts__container">
            <Bar data={takenData} options={options} />
          </div>
        </>
      ) : null}
      {/* <DrilldownGraph drillDownData={drillDownData} title="Vaccination Data" /> */}

      {missedChartData ? (
        <>
          <p className="chart__title">Vaccines Missed</p>
          <div className="charts__container">
            <Bar data={missedData} options={optionsTwo} />
          </div>
        </>
      ) : null}
      {pendingChartData ? (
        <>
          <p className="chart__title">Vaccines Pending</p>
          <div className="charts__container">
            <Bar data={pendingData} options={optionsFour} />
          </div>
        </>
      ) : null}
      {genderChartData ? (
        <>
          <p className="chart__title">Gender Distribution</p>
          <div className="charts__container">
            <Pie data={genderData} options={optionsThree} />
          </div>
        </>
      ) : null}
      {/* </Online> */}

      <Table
        title={tableTitleTwo}
        tableData={tableDataTwo}
        buttonTitle="Add Child"
        buttonIcon={<AddUserIcon className="add__user-icon" />}
        buttonUrl="/panel/add-child"
        marginTop="6rem"
        buttonFunction={setShowRegChildForm}
        buttonFunctionValue={showRegChildForm}
      />
      {navigator.onLine ? (
        <Table
          title={tableTitleOne}
          tableData={tableDataOne}
          buttonTitle={"Add Immunization Worker"}
          buttonIcon={<AddUserIcon className="add__user-icon" />}
          buttonUrl="/panel/register-admin"
          buttonFunction={setShowRegAdminForm}
          buttonFunctionValue={showRegAdminForm}
          marginTop="6rem"
        />
      ) : null}
    </div>
  );
};

export default HealtcareWorker;
