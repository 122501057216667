import { useState, useEffect, useMemo } from "react";
import DashboardTab from "../DashboardTab/DashboardTab";
import axios from "axios";
// import { ReactComponent as CheckIcon } from "../../assets/icons/check-icon.svg";
// import { ReactComponent as PeopleIcon } from "../../assets/icons/people-icon.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/user-icon2.svg";
import { ReactComponent as BellIcon } from "../../assets/icons/notification-bell-icon.svg";
import headerIllustration from "../../assets/images/parent-dash-img.png";
import tipVid from "../../assets/images/tip-vid.png";
import { ReactComponent as BabyIcon } from "../../assets/icons/baby-icon.svg";
import { ReactComponent as SampleIcon } from "../../assets/icons/sample-icon.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar-iconTwo.svg";
import { useLocation } from "react-router-dom";
import ChildBioData from "../ChildBioData/ChildBioData";
import CalendarComponent from "../ChildCalendar/Calendar";
import Table from "../Table/Table";
import { toast } from "react-toastify";
import Notification from "../Notification/Notification";
import { useDispatch, useSelector } from "react-redux";
import { storeBabiesDetails } from "../../redux/babies/babiesActions";
import { api } from "../..";
import { useNavigate } from "react-router-dom";
import "./parentDashboard.css";
import { storeUserDetails } from "../../redux/currentUser/currentUser.actions";

const ParentDashboard = () => {
  const [children, setChildren] = useState();
  const [clickedId, setClickedId] = useState("");
  const [pendingVacs, setPendingVacs] = useState();
  const [fullPendingVacs, setFullPendingVacs] = useState([]);
  const [firstChildPendingVacs, setFirstChildPendingVacs] = useState([]);
  const [firstChildRecievedVacs, setFirstChildReceivedVacs] = useState([]);
  const [secondChildPendingVacs, setSecondChildPendingVacs] = useState([]);
  const [secondChildRecievedVacs, setSecondChildReceivedVacs] = useState([]);
  const [thirdChildPendingVacs, setthirdChildPendingVacs] = useState([]);
  const [thirdChildRecievedVacs, setFirstthirddReceivedVacs] = useState([]);
  // const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const location = useLocation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const userDetails = useSelector((state) => state.currentUser.currentUser);

  const storePendingVacData = (children) => {
    let allChildPendingVacs = [];
    children.forEach((child) => {
      const pendingVacs = child.vac_date.filter(
        (vac) => vac.status === "pending"
      );
      const childPendingDetails = {
        name: child.first_name,
        pendingVacs: pendingVacs,
      };
      allChildPendingVacs.push(childPendingDetails);
    });
    setFullPendingVacs(allChildPendingVacs);
  };
  useEffect(() => {
    // const getUserDetails = () => {
    //   api
    //     .get(`/api/user/${userDetails.id}`)
    //     .then((res) => {
    //       console.log(res.data);
    //       setChildren(res.data.data?.child);
    // dispatch(storeUserDetails(res.data));
    dispatch(storeBabiesDetails(userDetails?.data?.child));
    storePendingVacData(userDetails?.data?.child);

    let pendingVaccinations;

    if (userDetails?.data?.child.length >= 1) {
      pendingVaccinations = userDetails?.data.child[0].vac_date.filter(
        (vac) => vac.status === "pending"
      );
    }
    setPendingVacs(pendingVaccinations);
    //     })
    // //     .catch((error) => console.log(error));
    // // };

    // getChildren();
    // getUserDetails();
  }, [userDetails]);

  // const getChildren = () => {
  //   api
  //     .get("/api/childern", config)
  //     .then((res) => {
  //       setChildren(res.data.data);
  //       console.log({ children });
  //       console.log(res);
  //     })
  //     .catch((error) => console.log(error));
  // };

  const tableTitle = "Children";

  const tableData = {
    headings: ["Name", "VAC Number", "DOB", "Action"],
    rows: userDetails?.child?.map((child) => {
      return {
        dataOne: child.first_name + " " + child.last_name,
        dataTwo: "VAC/01/a20",
        dataThree: child.date_of_birth,
        dataFour: "/panel/child-details/",
        dataFive: child.id,
      };
    }),
  };

  const parentTabData = [
    {
      textOne: "Welcome!",
      textTwo: userDetails.first_name + " " + userDetails.last_name,
      tabIcon: <UserIcon />,
      iconBackground:
        "linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))",
      boxShadow:
        "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(76 175 79 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
    },

    {
      textOne: "Babies",
      textTwo: userDetails?.child?.length,
      tabIcon: <BabyIcon />,
      iconBackground:
        "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))",
      boxShadow:
        "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(233 30 98 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
    },
    // {
    //   textOne: "Child's Next Appointment",
    //   textTwo: "12/2/2022",
    //   tabIcon: <CalendarIcon />,
    //   iconBackground:
    //     "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
    //   boxShadow:
    //     "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(0 187 212 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
    // },
  ];

  const childName = "fresh";

  return (
    <>
      <div className="parentDashboard__container">
        <div className="parent__name-and-notification">
          <p className="welcome__text">
            Welcome, {userDetails?.data.first_name}.🌹
          </p>
          {/* <p className="welcome__text">
            Welcome, {parentTabData[0].textTwo}.🌹
          </p> */}
          <Notification />
        </div>
        {/* <div className="tab__container"> */}
        {/* {parentTabData.map((data) => {
            return (
              <DashboardTab
                textOne={data.textOne}
                textTwo={data.textTwo}
                icon={data.tabIcon}
                iconBackground={data.iconBackground}
                boxShadow={data.boxShadow}
                width="47%"
              />
            );
          })} */}
        {/* <ErrandStatus /> */}
        {/* </div> */}

        <div className="kids__tab-details-and-image">
          <div className="kids__tab-details-and-upcoming--vacs">
            <div className="kids__num-tabs-and-mobile-name">
              <div className="mobile__name-and-notification">
                <h1 className="mobile__parent-name">
                  {userDetails?.data?.first_name}
                </h1>
                <Notification />
              </div>

              <div className="kids__num-tabs">
                <div className="kids__num-tab--one">
                  <SampleIcon className="kids__num-icon" />
                  <p className="kids__num kids__num-one">
                    {userDetails?.data?.child.length}{" "}
                    {userDetails?.data?.child?.length < 1 ? "Kids" : "Kid"}
                  </p>
                </div>
                <div className="kids__num-tab--two">
                  <SampleIcon className="kids__num-icon" />
                  <p className="kids__num kids__num-two">
                    {userDetails?.data?.child?.length}{" "}
                    {userDetails?.data?.child?.length > 1 ? "Kids" : "Kid"}
                  </p>
                </div>
              </div>
            </div>
            <div className="upcoming__vacs-container">
              <p className="upcoming__vacs-heading">Upcoming Vaccinations👨‍⚕️ </p>

              {pendingVacs?.map((vac, index) => {
                if (index < 3) {
                  return (
                    <div className="upcoming__vacs-details upcoming__vacs-details--one">
                      <p className="upcoming__vac-baby--name">
                        {userDetails?.data?.child[0].first_name}
                      </p>
                      <p className="upcoming__vac-date">
                        {vac.date.substr(0, 10)}
                      </p>
                      <p className="upcoming__vac-name">{vac.vac_name}</p>
                    </div>
                  );
                }
                return null;
              })}
              {/* <div className="upcoming__vacs-details">
                <p className="upcoming__vac-name">BCG</p>
                <p className="upcoming__vac-date">12/07/24</p>
                <p className="upcoming__vac-baby--name">Godiya</p>
              </div> */}
            </div>
          </div>
          <div className="dashboard__img-container">
            <img
              src={headerIllustration}
              className="dashboard__img"
              alt="header-illustration"
            />
          </div>
        </div>
        {/* <p className="vaccination__history-heading">Vaccination History</p>
        <table class="borderless-table">
          <thead>
            <tr>
              {userDetails?.data?.child?.map((child) => (
                <th>{child.first_name}</th>
              ))}
              <th>Header 2</th>
              <th>Header 3</th>
            </tr>
          </thead>
          <tbody className="border__table-body">
            <tr>
              <td className="table__row-data">Cell 1</td>
              <td>Cell 2</td>
              <td>Cell 3</td>
            </tr>
            <tr>
              <td>Cell 4</td>
              <td>Cell 5</td>
              <td>Cell 5</td>
            </tr>
            <tr>
              <td>Cell 5</td>
              <td>Cell 6</td>
              <td>Cell 6</td>
            </tr>
          </tbody>
        </table> */}

        <div className="mobile__vac-history">
          <div className="mobile__vac-history--baby--names">
            <p
              className={
                clickedId === "godiya"
                  ? "vac__history-baby--name active__vac-history-baby--name"
                  : "vac__history-baby--name"
              }
              id="godiya"
              onClick={(e) => setClickedId(e.target.id)}
            >
              Godiya
            </p>
            <p
              className={
                clickedId === "clara"
                  ? "vac__history-baby--name active__vac-history-baby--name"
                  : "vac__history-baby--name"
              }
              id="clara"
              onClick={(e) => setClickedId(e.target.id)}
            >
              Clara
            </p>
            <p
              className={
                clickedId === "fresh"
                  ? "vac__history-baby--name active__vac-history-baby--name"
                  : "vac__history-baby--name"
              }
              id="fresh"
              onClick={(e) => setClickedId(e.target.id)}
            >
              {childName}
            </p>
          </div>
          <div className="current__child-vac--history">
            <div className="current__child-vac--box">
              <p className="current__child-vaccine">Vaccine Received</p>
              <p className="current__child-vaccine-no">6</p>
            </div>
            <div className="current__child-vac--box">
              <p className="current__child-vaccine">Pending Received</p>
              <p className="current__child-vaccine-no">7</p>
            </div>
            <div className="current__child-vac--box total__vaccines-box">
              <p className="current__child-vaccine">Total Received</p>
              <p className="current__child-vaccine-no">8</p>
            </div>
          </div>
        </div>

        <div className="view__full-vac--history">
          <button className="view__full-vac--CTA">
            View full vaccination history
          </button>
        </div>

        <div className="next__scheduled-vacs">
          <p className="next__schedule-vacs--heading">
            Next Scheduled Vaccinations.
          </p>
          <div className="next__-scheduled-vacs--tabs">
            {fullPendingVacs &&
              fullPendingVacs.map((vac) => (
                <div className="next__scheduled-vac--tab">
                  <p className="next__scheduled-vac--baby-name">{vac.name}</p>
                  <div className="next__scheduled-vacs--vac-names">
                    {vac.pendingVacs
                      .filter((vac, index) => index < 3)
                      .map((vac) => (
                        <p className="next__scheduled-vacs--vac-name">
                          {vac.vac_name}
                        </p>
                      ))}
                    {/* <p className="next__scheduled-vacs--vac-name">PCV</p>
                    <p className="next__scheduled-vacs--vac-name">ROTA</p> */}
                  </div>

                  <p className="next__scheduled-vacs--date">
                    {vac.pendingVacs[0].date.substr(0, 10)}
                  </p>
                </div>
              ))}
            {/* <div className="next__scheduled-vac--tab">
              <p className="next__scheduled-vac--baby-name">Clara</p>
              <div className="next__scheduled-vacs--vac-names">
                <p className="next__scheduled-vacs--vac-name">OPV</p>
                <p className="next__scheduled-vacs--vac-name">PENTA</p>
                <p className="next__scheduled-vacs--vac-name">PCV</p>
                <p className="next__scheduled-vacs--vac-name">ROTA</p>
              </div>
              <p className="next__scheduled-vacs--date">12/07/24</p>
            </div>
            <div className="next__scheduled-vac--tab last__schedule-tab">
              <p className="next__scheduled-vac--baby-name">Fresh</p>
              <div className="next__scheduled-vacs--vac-names">
                <p className="next__scheduled-vacs--vac-name">OPV</p>
                <p className="next__scheduled-vacs--vac-name">PENTA</p>
                <p className="next__scheduled-vacs--vac-name">PCV</p>
                <p className="next__scheduled-vacs--vac-name">ROTA</p>
              </div>
              <p className="next__scheduled-vacs--date">12/07/24</p>
            </div> */}
          </div>
          <div className="mobile__scheduled-vacs">
            <div className="schedule__vac-baby--names-mobile">
              <p
                className={
                  clickedId === "dan"
                    ? "schedule__vac-baby--name-mobile active__vac-history-baby--name"
                    : "schedule__vac-baby--name-mobile"
                }
                id="dan"
                onClick={(e) => setClickedId(e.target.id)}
              >
                Godiya
              </p>
              <p className="schedule__vac-baby--name-mobile">Godiya</p>
              <p className="schedule__vac-baby--name-mobile">Godiya</p>
            </div>
            <div className="scheduled__vacs-name-and-date-mobile">
              <div className="scheduled__vacs-box--mobile">
                <p className="scheduled__vacs-names--box-mobile">OPV</p>
                <p className="scheduled__vacs-names--box-mobile">OPV</p>
                <p className="scheduled__vacs-names--box-mobile">OPV</p>
                <p className="scheduled__vacs-names--box-mobile">OPV</p>
              </div>
              <div className="scheduled__vacs-date--box-mobile">
                <p className="schedule__vacs-date--mobile">12/07/24</p>
              </div>
            </div>
          </div>
          <div className="view__full-vac--history">
            <button
              className="view__full-vac--CTA"
              onClick={() => navigate(`/panel/full-schedule`)}
            >
              View All Scheduled
            </button>
          </div>
        </div>
        <div className="vaccination__information-container">
          <div className="vaccination__history-vaccines">
            <p className="vaccination__information-heading">
              Vaccination Information💉
            </p>
            <div className="vaccination__information-tabs">
              <div className="vaccination__information-tabs--one">
                <div className="vaccination__information-tab">
                  <p className="vaccination__tab-text">
                    BCG (Bacille Calmette- Guerin)
                  </p>
                </div>
                <div className="vaccination__information-tab">
                  <p className="vaccination__tab-text">
                    OPV1 [Oral Poliovirus Vaccine]
                  </p>
                </div>
              </div>
              <div className="vaccination__information-tabs--one">
                <div className="vaccination__information-tab">
                  <p className="vaccination__tab-text">
                    BCG (Bacille Calmette- Guerin)
                  </p>
                </div>
                <div className="vaccination__information-tab">
                  <p className="vaccination__tab-text">
                    BCG (Bacille Calmette- Guerin)
                  </p>
                </div>
              </div>
            </div>
            <div className="view__full-vac--history">
              <button
                className="view__full-vac--CTA vac__history-CTA"
                onClick={() => navigate("/panel/vacs-tips")}
              >
                See more
              </button>
            </div>
          </div>
          <div className="dashboard__img-container vac__information-img--container">
            <img
              src={headerIllustration}
              className="dashboard__img"
              alt="header-illustration"
              style={{ flex: "1" }}
            />
          </div>
        </div>
        <p className="health__tips-heading">Lets learn vaccine tips</p>
        <div className="health__tips-container">
          <div className="health__tip-vid--content">
            <div className="health__tips-vid--container">
              <img
                src={tipVid}
                alt="health__tip-vid"
                className="health__tips-vid"
              />
            </div>
            <div className="health__tips-vid--container">
              <img
                src={tipVid}
                alt="health__tip-vid"
                className="health__tips-vid"
              />
            </div>
            <div className="health__tips-vid--container">
              <img
                src={tipVid}
                alt="health__tip-vid"
                className="health__tips-vid"
              />
            </div>
            <div className="health__tips-vid--container">
              <img
                src={tipVid}
                alt="health__tip-vid"
                className="health__tips-vid"
              />
            </div>
            <div className="health__tips-vid--container">
              <img
                src={tipVid}
                alt="health__tip-vid"
                className="health__tips-vid"
              />
            </div>
          </div>
        </div>

        {/* <Table
          title={tableTitle}
          tableData={tableData}
          marginTop="6rem"
          buttonTitle="Add Child"
          buttonFunction={setShowRegChildForm}
          buttonFunctionValue={showRegChildForm}
        />
        <CalendarComponent /> */}
      </div>
    </>
  );
};

export default ParentDashboard;
