// import Calendar from "react-awesome-calendar";
import React, { useState, useEffect } from "react";
import { ReactComponent as XIcon } from "../../assets/icons/close-icon.svg";
// import FullCalendar from "@fullcalendar/react"; // must go before plugins
// import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
// import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import "react-big-calendar/lib/css/react-big-calendar.css";
import "./calendar.styles.css";

// import { useState } from "react";
// import DatePicker from "react-datepicker";

// const events = [
//   {
//     title: "BCG",
//     allDay: true,
//     start: new Date(2020, 0, 4),
//     end: new Date(2020, 0, 4),
//   },
//   {
//     title: "OPV 1",
//     allDay: true,
//     start: new Date(2020, 1, 14),
//     end: new Date(2020, 1, 14),
//   },
//   {
//     title: "PENTA",
//     allDay: true,
//     start: new Date(2019, 6, 5),
//     end: new Date(2019, 6, 5),
//   },
// ];

function CalendarComponent({ vaccinations }) {
  const [newEvent, setNewEvent] = useState({
    title: "",
    start: "",
    allDay: true,
    end: "",
  });

  let eventsMapped = [];

  const [allEvents, setAllEvents] = useState(eventsMapped);

  const locales = {
    // eslint-disable-next-line global-require
    "en-US": require("date-fns/locale/en-US"),
  };

  // const localizer = dateFnsLocalizer({
  //   format,
  //   parse,
  //   startOfWeek,
  //   getDay,
  //   locales,
  // });

  const handleAddEvent = () => {
    setAllEvents([...allEvents, newEvent]);
  };

  useEffect(() => {}, [allEvents]);

  // useEffect(() => {
  vaccinations.forEach((vaccine, index) => {
    eventsMapped.push({
      title: vaccine.vac_name + " " + "(" + vaccine.status + ")",
      start: vaccine.date.substr(0, 10),
      allDay: true,
      end: vaccine.date.substr(0, 10),
      backgroundColor:
        vaccine.status === "pending"
          ? "#EEAD06"
          : vaccine.status === "missed"
          ? "#e03535 "
          : "#0B7E09",
    });
  });

  // setAllEvents(eventsMapped);
  // }, []);

  // vaccinations.forEach((vaccine, index) => {
  //   if (index > 1 && index < 24) {
  //     eventsMapped.push({
  //       title: vaccine[0],
  //       start: vaccine[1],
  //       allDay: true,
  //       end: vaccine[1],
  //     });
  //   }
  // });

  // console.log(events.events);

  const userType = "parent";

  const stat = "due";

  return (
    <div
      className="calendar__container"
      // style={{ width: "100%", height: "30rem" }}
    >
      {/* <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        initialDate={`${allEvents[0].start.substr(0, 10)}`}
        events={allEvents}
        eventBackgroundColor={"red"}

        style={{ height: "500rem", margin: "50px" }}
      /> */}
      {/* {userType === "nurse" ? (
        <div className="addEventDetails">
          <div>
            <input
              type="text"
              label="Enter Vaccination Entry"
              width="6rem"
              value={newEvent.title}
              onChange={(e) =>
                setNewEvent({ ...newEvent, title: e.target.value })
              }
            />
          </div>

          <span className="datePicker__span">
            <DatePicker
              placeholderText="State Date"
              style={{ marginRight: "10px" }}
              selected={newEvent.start}
              onChange={(start) => setNewEvent({ ...newEvent, start })}
              className="date__picker"
            />
            <DatePicker
              placeholderText="End Date"
              // style={{ marginRight: "10px" }}
              selected={newEvent.end}
              onChange={(end) => setNewEvent({ ...newEvent, end })}
              className="date__picker"
            />
          </span>

          <button
            className="add__event--CTA"
            onClick={handleAddEvent}
            type="submit"
          >
            Add Entry
          </button>
        </div>
      ) : null} */}

      {/* <Calendar
        defaultDate={`${allEvents[0].start.substr(0, 10)}`}
        defaultView="agenda"
        localizer={localizer}
        events={allEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500, margin: "50px" }}
      /> */}
    </div>
  );
}

export default CalendarComponent;
