import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as MailLogo } from "../../assets/icons/mail-icon.svg";
// import { ReactComponent as CompnyIcon } from "../../assets/icons/company-icon.svg";
import { useForm } from "react-hook-form";
import axios from "axios";
import { api } from "../..";
import { ReactComponent as EyeLogo } from "../../assets/icons/eye-icon.svg";
import { ReactComponent as EyeOffLogo } from "../../assets/icons/eye-off.svg";
import { ReactComponent as LockLogo } from "../../assets/icons/lock-icon.svg";
// import { ReactComponent as NameIcon } from "../../assets/icons/name-icon.svg";
// import { ReactComponent as CountryIcon } from "../../assets/icons/country-icon.svg";
import { useNavigate, useLocation } from "react-router-dom";
// import Loading from "../Loading/Loading.component";
import "./register.css";

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const Register = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("password");

  const [email, setEmail] = useState("test@test.com");
  const [emailFocus, setEmailFocus] = useState(false);

  const [address, setAddress] = useState("test@test.com");
  const [addressFocus, setAddressFocus] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [firstNameFocus, setFirstNameFocus] = useState("");

  const [lastName, setLastName] = useState("");
  const [lastNameFocus, setLastNameFocus] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberFocus, setPhoneNumberFocus] = useState(false);

  const [gender, setGender] = useState("");
  const [genderFocus, setGenderFocus] = useState("");

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  const emailRef = useRef();
  const errRef = useRef();

  const navigate = useNavigate();

  const togglePasswordType = () => {
    setShowPassword(!showPassword);
    setType(showPassword ? "text" : "password");
  };

  const location = useLocation();

  const REGUSERURL = "/api/user/register";
  const REGADMINURL = "/api/admin/register";
  const REGSUPERADMINURL = "/api/super_admin/register";

  const URL = location.pathname === "/register-user" ? REGUSERURL : REGADMINURL;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data, e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await api.post(URL, {
        phone_number: phoneNumber,
        gender: gender,
        first_name: firstName,
        last_name: lastName,
        email: email,
        address: address,
        password: pwd,
      });
      setSuccess(true);
      setPhoneNumber("");
      setAddress("");
      setGender("");
      setFirstName("");
      setLastName("");
      setEmail("");
      setShowPassword("");
      setLoading(false);
    } catch (error) {
      if (!error?.response) {
        setErrMsg("No Server Response");
      } else if (error.response?.status === 400) {
        setErrMsg("Account Already Exists");
      } else {
        setErrMsg("Registration Failed");
      }

      errRef.current.focus();
      setLoading(false);
    }
  };

  useEffect(() => {
    const result = PWD_REGEX.test(pwd);
    setValidPwd(result);
    const match = pwd === matchPwd;
    setValidMatch(match);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setErrMsg("");
  }, [pwd, matchPwd]);

  return (
    <>
      {success ? (
        <section className="comp__acc--success">
          <span className="success__text-and-login__CTA">
            <h5 className="success__text">Account successfully created</h5>

            <button className="goToLogin__CTA" onClick={() => navigate("/")}>
              Log In
            </button>
          </span>
        </section>
      ) : (
        <div className="createCompLogin__container">
          {/* {loading ? <div className="overlay"></div> : null} */}
          {/* {loading ? <Loading type="cylon" color="rgb(101, 93, 138)" /> : null} */}
          <div className="createCompLogin__content--container">
            <div className="createCompLogin__form--container">
              <span className="createCompAccNavLogo-and-links">
                {/* <FezzantLogo /> */}
              </span>
              <div className="form-and-text__container">
                <span
                  className="createCompLogin__form--texts"
                  onClick={() => (localStorage.token ? navigate("/panel") : "")}
                >
                  {/* {loading ? (
                    <Loading type="cylon" color="rgb(101, 93, 138)" />
                  ) : null} */}
                  <h2 className="createCompLogin__formheading">
                    {URL === REGUSERURL
                      ? "Register User"
                      : "Register HealthCare Personnel"}{" "}
                  </h2>

                  <p
                    className={errorMessage ? "errorMessage" : "offScreen"}
                    aria-live="assertive"
                  >
                    {errorMessage}
                  </p>

                  <p className="createCompLogin__form-sub--text">
                    Fill the forms below to create your account.
                  </p>
                </span>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="createCompLogin__form"
                >
                  <label
                    htmlFor="firstName"
                    className="createCompLogin__email--label"
                  >
                    First Name
                  </label>
                  <div className="createCompLogin__email-and-icon">
                    <input
                      type="text"
                      className={
                        errorMessage
                          ? "email--input"
                          : "createCompLogin__email--input"
                      }
                      id="firstName"
                      autoComplete="off"
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                      required
                      aria-describedby="uidnote"
                      onFocus={() => setFirstNameFocus(true)}
                      onBlur={() => setFirstNameFocus(false)}
                      // {...register("firstName", { required: true })}
                    />
                    {errors.firstName && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      >
                        First name is required
                      </p>
                    )}
                    {/* <NameIcon className="mail__icon" /> */}
                  </div>
                  <label
                    htmlFor="LastName"
                    className="createCompLogin__email--label"
                  >
                    Last Name
                  </label>
                  <div className="createCompLogin__email-and-icon">
                    <input
                      type="text"
                      className={
                        errorMessage
                          ? "email--input"
                          : "createCompLogin__email--input"
                      }
                      id="lastName"
                      autoComplete="off"
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                      required
                      aria-describedby="uidnote"
                      onFocus={() => setLastNameFocus(true)}
                      onBlur={() => setLastNameFocus(false)}
                      // {...register("lastName", { required: true })}
                    />
                    {errors.lastName && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      >
                        Last name is required
                      </p>
                    )}
                    {/* <NameIcon className="mail__icon" /> */}
                  </div>

                  <label
                    htmlFor="gender"
                    className="createCompLogin__email--label"
                  >
                    Phone Number
                  </label>
                  <div className="createCompLogin__email-and-icon">
                    <input
                      className={
                        errorMessage
                          ? "email--input"
                          : "createCompLogin__email--input"
                      }
                      type="text"
                      id="phoneNumber"
                      autoComplete="off"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      value={phoneNumber}
                      required
                      aria-describedby="uidnote"
                      onFocus={() => setPhoneNumberFocus(true)}
                      onBlur={() => setPhoneNumberFocus(false)}
                      // {...register("company", { required: true })}
                    />
                    {errors.phoneNumber && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      >
                        Phone number is required
                      </p>
                    )}

                    {/* <CompnyIcon className="mail__icon" /> */}
                  </div>
                  <label
                    htmlFor="email"
                    className="createCompLogin__email--label"
                  >
                    Email
                  </label>
                  <div className="createCompLogin__email-and-icon">
                    <input
                      className={
                        errorMessage
                          ? "email--input"
                          : "createCompLogin__email--input"
                      }
                      type="email"
                      id="email"
                      autoComplete="off"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      required
                      aria-describedby="uidnote"
                      onFocus={() => setEmailFocus(true)}
                      onBlur={() => setEmailFocus(false)}
                      // {...register("company", { required: true })}
                    />
                    {errors.email && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      >
                        Email is required
                      </p>
                    )}

                    {/* <CompnyIcon className="mail__icon" /> */}
                  </div>

                  <label
                    htmlFor="gender"
                    className="createCompLogin__email--label"
                  >
                    Gender
                  </label>
                  <div className="createCompLogin__email-and-icon">
                    <select
                      // name="countries"
                      id="gender"
                      className={
                        errorMessage
                          ? "email--input"
                          : "createCompLogin__email--input"
                      }
                      value={gender}
                      required
                      onChange={(e) => setGender(e.target.value)}
                      onFocus={() => setGenderFocus(true)}
                      onBlur={() => setGenderFocus(false)}
                      // {...register("country", { required: true })}
                    >
                      <option value=""></option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                    {errors.gender && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      >
                        Please Select Your Gender
                      </p>
                    )}

                    {/* <CountryIcon className="mail__icon" /> */}
                  </div>
                  <label
                    htmlFor="address"
                    className="createCompLogin__email--label"
                  >
                    Address
                  </label>
                  <div className="createCompLogin__email-and-icon">
                    <input
                      className={
                        errorMessage
                          ? "email--input"
                          : "createCompLogin__email--input"
                      }
                      type="text"
                      id="address"
                      autoComplete="off"
                      onChange={(e) => setAddress(e.target.value)}
                      value={address}
                      required
                      aria-describedby="uidnote"
                      onFocus={() => setAddressFocus(true)}
                      onBlur={() => setAddressFocus(false)}
                      // {...register("company", { required: true })}
                    />
                    {/* {errors.email && (
                      <p
                        style={{
                          color: "#F95959",
                          marginTop: "1rem",
                        }}
                      >
                         is required
                      </p>
                    )} */}

                    {/* <CompnyIcon className="mail__icon" /> */}
                  </div>
                  <label
                    htmlFor="password"
                    className="createCompLogin__password--label"
                  >
                    Password
                  </label>
                  <div className="createCompLogin__password-and-icons">
                    <input
                      type={type}
                      className={
                        errorMessage || (pwdFocus && !validPwd)
                          ? "email--input"
                          : "createCompLogin__email--input"
                      }
                      id="password"
                      // value={pwd}
                      onChange={(e) => setPwd(e.target.value)}
                      autoComplete="false"
                      required
                      aria-invalid={validPwd ? "false" : "true"}
                      aria-describedby="pwdnote"
                      onFocus={() => setPwdFocus(true)}
                      onBlur={() => setPwdFocus(false)}
                      // {...register("password", { required: true })}
                    />
                    <p
                      id="pwdnote"
                      className={
                        pwdFocus && !validPwd ? "instruction" : "offScreen"
                      }
                    >
                      8 to 24 characters.
                      <br />
                      Must include uppercase and lowercase letters, a number and
                      a special character.
                      <br />
                      Allowed special characters:{" "}
                      <span aria-label="exclamation mark">!</span>{" "}
                      <span aria-label="at symbol">@</span>{" "}
                      <span aria-label="hashtag">#</span>{" "}
                      <span aria-label="dollar sign">$</span>{" "}
                      <span aria-label="percent">%</span>
                    </p>

                    {type === "password" ? (
                      <EyeLogo
                        className="eye__icon"
                        onClick={togglePasswordType}
                      />
                    ) : (
                      <EyeOffLogo
                        className="eye__icon"
                        onClick={togglePasswordType}
                      />
                    )}
                    {/* <LockLogo className="lock__icon" /> */}
                  </div>
                  <label
                    htmlFor="confirm_pwd"
                    className="createCompLogin__password--label"
                  >
                    Confirm Password
                  </label>
                  <div className="createCompLogin__password-and-icons">
                    <input
                      type={type}
                      id="confirm_pwd"
                      value={matchPwd}
                      onChange={(e) => setMatchPwd(e.target.value)}
                      autoComplete="false"
                      required
                      aria-invalid={validMatch ? "false" : "true"}
                      aria-describedby="confirmNote"
                      onFocus={() => setMatchFocus(true)}
                      onBlur={() => setMatchFocus(false)}
                      className={
                        errorMessage || (matchFocus && !validMatch)
                          ? "email--input"
                          : "createCompLogin__email--input"
                      }

                      // {...register("confirmPassword", { required: true })}
                    />
                    <p
                      id="confirmnote"
                      className={!validMatch ? "instruction" : "offScreen"}
                    >
                      Must match the first password input field.
                    </p>

                    {type === "password" ? (
                      <EyeLogo
                        className="eye__icon"
                        onClick={togglePasswordType}
                      />
                    ) : (
                      <EyeOffLogo
                        className="eye__icon"
                        onClick={togglePasswordType}
                      />
                    )}
                    {/* <LockLogo className="lock__icon" /> */}
                  </div>
                  {/* 
                  <p className="forgot__password">Forgot Your Password</p> */}

                  <button
                    disabled={!validPwd || !validMatch ? true : false}
                    className="createCompLogin__submit--CTA"
                  >
                    Create Account
                  </button>
                </form>
              </div>
            </div>
            <div className="createCompLogin__image--container">
              <div className="createCompLogin__image--box">
                {/* <img
                  src={require("../../assets/images/createcompacc.png")}
                  alt="createCompLogin__image"
                /> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Register;
