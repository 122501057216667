import { useState, useMemo } from "react";
import axios from "axios";
import { api } from "../..";
import { toast } from "react-toastify";
import { ReactComponent as XIcon } from "../../assets/icons/close-icon.svg";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";

const AddFacility = ({ closeForm }) => {
  const [facilityName, setFacilityName] = useState("");
  const [address, setAddress] = useState("");
  const [states, setStates] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [lgas, setLgas] = useState();
  const [wards, setWards] = useState([]);
  const [stateId, setStateId] = useState("");
  const [lgaId, setLgaId] = useState("");
  const [wardId, setWardId] = useState("");
  const [agencyId, setAgencyId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const agency = useSelector((state) => state.agency.currentAgency);

  useEffect(() => {
    api.get("/api/states").then((res) => {
      setStates(res.data);
    });
    getAgencies();
  }, []);

  useEffect(() => {
    api.get(`/api/lga/${stateId}`).then((res) => {
      setLgas(res.data.data);
    });
  }, [stateId]);

  useEffect(() => {
    if (lgaId) {
      api.get(`/api/ward/${lgaId}`).then((res) => {
        setWards(res.data.data);
      });
    }
  }, [lgaId]);

  const getAgencies = () => {
    api
      .get("/api/agency")
      .then((res) => {
        // setLoading(false);
        setAgencies(res.data?.data);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };

  const addFacility = () => {
    setLoading(true);
    api
      .post("api/facility", {
        name: facilityName,
        state_id: stateId,
        lga_id: lgaId,
        address: address,
        // agency_id: agency.agency.id,
      })
      .then((res) => {
        setFacilityName("");
        setAddress("");
        setAgencyId("");
        setStateId("");
        setLgaId("");
        setLoading(false);
        toast.success("Facility Added Successfully!");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error processing request. Please try again.");
        setLoading(false);
      });
  };

  return (
    <div className="addChild__container">
      {loading ? (
        <div className="loader__container">
          <ClimbingBoxLoader color="#ffffff" loading={loading} size={15} />
        </div>
      ) : null}
      <div
        className="addUserForm__content--container"
        style={{ height: "100%", width: "100vw" }}
      >
        <div className="addState__form">
          <span className="form__heading-and-close--CTA">
            <p className="addUserForm__formheading">Add Facility</p>
            <XIcon
              style={{ fill: "#F95959", cursor: "pointer" }}
              onClick={() => closeForm()}
            />
          </span>
          <label
            htmlFor="facilityName"
            className="addUserForm__password--label"
          >
            Facility Name
          </label>
          <div className="addUserForm__password-and-icons">
            <input
              type="text"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              id="state"
              autoComplete="off"
              onChange={(e) => setFacilityName(e.target.value)}
              value={facilityName}
              style={{
                width: "100%",
                marginTop: "1.5rem",
                marginBottom: "1.5rem",
              }}
              required
              aria-describedby="uidnote"
            />
            {errorMessage && (
              <p
                style={{
                  color: "#F95959",
                  marginTop: "1rem",
                }}
              >
                Facility's Name is Required
              </p>
            )}
          </div>
          <label htmlFor=" gender" className="addUserForm__password--label">
            State
          </label>
          <div className="addUserForm__password-and-icons">
            <select
              // name="countries"
              id="state"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              value={stateId}
              required
              onChange={(e) => setStateId(e.target.value)}
              style={{
                width: "100%",
                marginTop: "1.5rem",
                marginBottom: "1.5rem",
              }}

              // {...register("country", { required: true })}
            >
              <option value=""></option>
              {states?.map((state) => {
                return <option value={state.id}>{state.name} </option>;
              })}
            </select>
            {errorMessage && (
              <p
                style={{
                  color: "#F95959",
                  marginTop: "1rem",
                }}
              >
                State is required
              </p>
            )}
            {/* <NameIcon className="mail__icon" /> */}
          </div>
          <label htmlFor=" gender" className="addUserForm__password--label">
            LGA
          </label>
          <div className="addUserForm__password-and-icons">
            <select
              // name="countries"
              id="Lga"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              value={lgaId}
              required
              onChange={(e) => setLgaId(e.target.value)}
              style={{
                width: "100%",
                marginTop: "1.5rem",
                marginBottom: "1.5rem",
              }}
              //   onFocus={() => setGenderFocus(true)}
            >
              <option value=""></option>
              {lgas?.map((lga) => {
                return <option value={lga.id}>{lga.name}</option>;
              })}
            </select>
            {errorMessage && (
              <p
                style={{
                  color: "#F95959",
                  marginTop: "1rem",
                }}
              >
                LGA is required
              </p>
            )}
            {/* <NameIcon className="mail__icon" /> */}
          </div>
          <label htmlFor=" gender" className="addUserForm__password--label">
            Ward
          </label>
          <div className="addUserForm__password-and-icons">
            <select
              // name="countries"
              id="ward"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              value={wardId}
              required
              onChange={(e) => setWardId(e.target.value)}
              style={{
                width: "100%",
                marginTop: "1.5rem",
                marginBottom: "1.5rem",
              }}
              //   onFocus={() => setGenderFocus(true)}
            >
              <option value=""></option>
              {wards?.map((ward) => {
                return <option value={ward.id}>{ward.name}</option>;
              })}
            </select>
            {errorMessage && (
              <p
                style={{
                  color: "#F95959",
                  marginTop: "1rem",
                }}
              >
                Ward is required
              </p>
            )}
            {/* <NameIcon className="mail__icon" /> */}
          </div>
          {/* <label htmlFor=" gender" className="addUserForm__password--label">
            Agency
          </label>
          <div className="addUserForm__password-and-icons">
            <select
              // name="countries"
              id="agency"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              value={agencyId}
              required
              onChange={(e) => setAgencyId(e.target.value)}
              style={{
                width: "100%",
                marginTop: "1.5rem",
                marginBottom: "1.5rem",
              }}
          
            >
              <option value=""></option>
              {agencies?.map((agency) => {
                return <option value={agency.id}>{agency.name}</option>;
              })}
            </select>
            {errorMessage && (
              <p
                style={{
                  color: "#F95959",
                  marginTop: "1rem",
                }}
              >
                Agency is required
              </p>
            )}
          
          </div> */}
          <label htmlFor="state" className="addUserForm__password--label">
            Address
          </label>
          <div className="addUserForm__password-and-icons">
            <input
              type="text"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              id="address"
              autoComplete="off"
              onChange={(e) => setAddress(e.target.value)}
              value={address}
              style={{
                width: "100%",
                marginTop: "1.5rem",
              }}
              required
              aria-describedby="uidnote"
            />
            {errorMessage && (
              <p
                style={{
                  color: "#F95959",
                  marginTop: "1rem",
                }}
              >
                Address is Required
              </p>
            )}
          </div>
          <button
            className="addUserForm__submit--CTA"
            onClick={() => addFacility()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddFacility;
