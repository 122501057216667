import { currentUserActionTypes } from "./currentUser.actionTypes";

const INITIAL_STATE = {
  currentUser: null,
  facilityWorkerData: null,
};

const currentUserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case currentUserActionTypes.STORE_USER_DETAILS:
      return {
        ...state,
        currentUser: action.payload,
      };

    case currentUserActionTypes.STORE_FACILITY_WORKER_DETAILS:
      return {
        ...state,
        facilityWorkerData: action.payload,
      };

    default:
      return state;
  }
};

export default currentUserReducer;
