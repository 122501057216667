import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { ReactComponent as SettingsIcon } from "../../assets/icons/manage-vac-icon.svg";
import { ReactComponent as VerticalMenuIcon } from "../../assets/icons/vertical-menu-icon.svg";
import { ReactComponent as UpdateQIcon } from "../../assets/icons/update-q-icon.svg";
import { ReactComponent as UploadFileIcon } from "../../assets/icons/upload-file-icon.svg";
import { ReactComponent as CameraIcon } from "../../assets/icons/camera-icon.svg";
import { useSelector } from "react-redux";
import defaultImage from "../../assets/images/imtrackerlogo.jpeg";
import "./childBio.css";
import { api } from "../..";
import { useNavigate } from "react-router-dom";

const ChildBioData = ({
  childDetails,
  setShowVaccineList,
  showVaccineList,
  setShowUpdateParent,
  questionnaire,
  setShowUpdateQuestionnaire,
  setShowUploadFile,
  setShowSnapPhoto,
  setShowWeightUpdate,
  setShowHeightUpdate,
  showEditChildBio,
  setShowEditChildBio,
}) => {
  const [showProfileUpdateOptions, setShowProfileUpdateOptions] =
    useState(false);

  const baby = useSelector((state) => state.babies.baby);

  const str = `${childDetails?.first_name} ${childDetails?.last_name}`;
  const matches = str.match(/\b(\w)/g);
  const acronym = matches.join("");

  const navigate = useNavigate();

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const linkChildToUser = () => {
    api
      .post("/api/child_user", {
        child_id: childDetails.id,
        user_id: userDetails.id,
      })
      .then((res) => {
        toast.success("Child linked Successfully!");
      })
      .catch((error) =>
        toast.error("Error processing request, please try again.")
      );
  };

  // const childImage = ;

  return (
    <div className="child__details--content">
      {/* {loading ? (
        <div className="loader__container">
          <FadeLoader color="purple" loading={loading} size={15} />
        </div>
      ) : null} */}
      <span className="childPicture-and-CTAs">
        {/* <p className="username__abbr">{acronym}</p> */}
        {userDetails?.user_type === "user" ? null : (
          <span className="child__profile--CTAs">
            <button
              className="profile__CTA"
              onClick={() =>
                setShowProfileUpdateOptions(!showProfileUpdateOptions)
              }
              style={{ fontWeight: "600", fontSize: "1.8rem" }}
            >
              Make Updates
              <VerticalMenuIcon
                style={{
                  fill: "#ffffff",
                  marginLeft: "1rem",
                }}
              />
            </button>
            {/* <button
              className="profile__CTA"
              onClick={() => setShowUpdateQuestionnaire(true)}
            >
              Update Questionnaire
            </button>

            <button
              className="profile__CTA"
              onClick={() => setShowVaccineList(!showVaccineList)}
            >
              Manage Vaccinations
            </button> */}

            {showProfileUpdateOptions ? (
              <span className="profile__update-options">
                <span
                  className="acc__update-option"
                  onClick={() => setShowVaccineList(!showVaccineList)}
                >
                  <SettingsIcon style={{ marginRight: "2rem" }} />
                  <p>Manage Vaccinations</p>
                </span>
                <span
                  className="acc__update-option"
                  onClick={() => setShowEditChildBio(!showEditChildBio)}
                >
                  <UpdateQIcon style={{ marginRight: "2rem" }} />
                  <p>Update Bio</p>
                </span>
                <span
                  className="acc__update-option"
                  onClick={() => setShowUpdateQuestionnaire(true)}
                >
                  <UpdateQIcon style={{ marginRight: "2rem" }} />
                  <p>Update Questionnaire</p>
                </span>
                <span
                  className="acc__update-option"
                  onClick={() => setShowUploadFile(true)}
                >
                  <UploadFileIcon style={{ marginRight: "2rem" }} />
                  <p>Upload Document</p>
                </span>
                <span
                  className="acc__update-option"
                  onClick={() => setShowSnapPhoto(true)}
                >
                  <CameraIcon style={{ marginRight: "2rem" }} />
                  <p>Snap Picture</p>
                </span>
                <span
                  className="acc__update-option"
                  onClick={() => setShowWeightUpdate(true)}
                >
                  <UpdateQIcon style={{ marginRight: "2rem" }} />
                  <p>Update Child's Weight</p>
                </span>
                <span
                  className="acc__update-option"
                  onClick={() => setShowHeightUpdate(true)}
                >
                  <UpdateQIcon style={{ marginRight: "2rem" }} />
                  <p>Update Child's Height</p>
                </span>
                <span
                  className="acc__update-option"
                  onClick={() => setShowUpdateParent(true)}
                >
                  <UpdateQIcon style={{ marginRight: "2rem" }} />
                  <p>Update Parent</p>
                </span>
                {/* <span className="acc__update-option">
                  <p>Upload Document</p>
                </span> */}
              </span>
            ) : null}
          </span>
        )}
      </span>
      <div className="child__details">
        <div className="profile__pic-container" style={{ display: "none" }}>
          {childDetails?.picture ? (
            <img
              // src={`http://localhost:8000/documents/children_profile_pictures/${childDetails?.picture}`}
              src={`https://api.imt-tracker.hayokmedicare.ng/documents/children_profile_pictures/${childDetails?.picture}`}
              alt="profile_picture"
              className="profile__picture"
              onerror="this.src='../../assets/images/imtrackerlogo.jpeg';"
            />
          ) : (
            <img
              src={defaultImage}
              alt="profile_picture"
              className="profile__picture"
              onerror="this.src='../../assets/images/imtrackerlogo.jpeg';"
            />
          )}
        </div>
        <div className="child__bio--info">
          <h2 className="details__heading">Child's Bio Data</h2>
          <p className="detail">
            <span className="info__label">Card Number: </span>
            {childDetails?.child_vac_id}
          </p>
          <p className="detail">
            <span className="info__label">Child's Name: </span>
            {childDetails?.first_name} {childDetails?.last_name}
          </p>
          <p className="detail">
            <span className="info__label">Date Of Birth: </span>
            {childDetails?.date_of_birth}
          </p>
          <p className="detail">
            <span className="info__label">Birth Cert No: </span>
            {childDetails?.birth_cert_no}
          </p>
          <p className="detail">
            <span className="info__label">Mother's Name: </span>

            {childDetails?.user.map((user) =>
              user.user_type_id === "6"
                ? user.first_name + " " + user.last_name
                : null
            )}
          </p>
          <p className="detail">
            <span className="info__label">Father's Name: </span>

            {childDetails?.user.map((user) =>
              user.user_type_id === "5"
                ? user.first_name + " " + user.last_name
                : null
            )}
          </p>
          <p className="detail">
            <span className="info__label">Guardian's Name: </span>

            {childDetails?.user.map((user) =>
              user.user_type_id === "7"
                ? user.first_name + " " + user.last_name
                : null
            )}
          </p>
          <p className="detail">
            <span className="info__label">Caregiver's Name: </span>

            {childDetails?.user.map((user) =>
              user.primary_caregiver === 1
                ? user.first_name + " " + user.last_name
                : null
            )}
          </p>
          <p className="detail">
            <span className="info__label">Contact Number: </span>
            {childDetails?.user
              .filter((user) => user.primary_caregiver === 1)
              .map((user) => user.phone_number)}
          </p>
        </div>
        <div className="child__bio--info">
          <h2 className="details__heading">Extra Care Questionnaire</h2>
          <p className="detail">
            <span className="info__label">How many surviving children?: </span>
            {questionnaire?.no_surviving_childern}
          </p>
          <p className="detail">
            <span className="info__label">How many dead children?: </span>
            {questionnaire?.no_dead_children}
          </p>
          <p className="detail">
            <span className="info__label">
              What's the baby's weight at birth (Kg)?:{" "}
            </span>
            {
              childDetails?.child_weight[childDetails.child_weight.length - 1]
                ?.weight
            }
            kg
          </p>
          <p className="detail">
            <span className="info__label">
              Did the baby weigh less than 2.5kg at birth?:{" "}
            </span>
            {questionnaire?.child_weighed_lessthan_2 === 1 ? "Yes" : "No"}
          </p>
          <p className="detail">
            <span className="info__label">Is this baby a twin?: </span>
            {questionnaire?.baby_twin === 1 ? "Yes" : "No"}
          </p>
          <p className="detail">
            <span className="info__label">Is this baby bottle fed?: </span>
            {questionnaire?.bottle_feed === 1 ? "Yes" : "No"}
          </p>
          <p className="detail">
            <span className="info__label">
              Does the mother need family support?:{" "}
            </span>
            {questionnaire?.mother_need_family_support === 1 ? "Yes" : "No"}
          </p>
          <p className="detail">
            <span className="info__label">Any underweight siblings?: </span>{" "}
            {questionnaire?.siblings_underweight === 1 ? "Yes" : "No"}
          </p>
          <p className="detail">
            <span className="info__label">How many surviving children?: </span>{" "}
            {questionnaire?.no_surviving_childern}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChildBioData;
