import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Panel from "./components/Panel/Panel";
import ChildDetails from "./components/ChildDetails/ChildDetails";
import HealtcareWorker from "./components/HealtcareWorker/HealthCareWorker";
// import ParentDashboard from "./components/Panel/ParentDashboard/ParentDashboard";
// import AdminDashboard from "./components/Panel/Dashboard/SuperAdminDashboard/SuperAdminDashboard";
import Profile from "./components/Profile/Profile";
import AddChild from "./components/AddChild/AddChild";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import RegisterParent from "./components/RegisterParent/RegisterParent";
import PersonnelData from "./components/PersonnelData/PersonnelData";
import RegisterSuperAdmin from "./components/RegisterSuperAdmin/RegisterSuperAdmin";
import SuperAdminDashboard from "./components/SuperAdminDashboard/SuperAdminDashboard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import DataManagement from "./components/DataManagement/DataManagement";
import ImmunizationWorker from "./components/ImmunizationWorker/ImmunizationWorker";
import { Dexie } from "dexie";
import { useLiveQuery } from "dexie-react-hooks";
import DataSync from "./components/DataSync/DataSync";
import Privacy from "./components/Privacy/Privacy";
import Employees from "./components/Employees/Employees";
import ChildManagement from "./components/Children/Children-management";
import AddAdmin from "./components/AddAdmin/AddAdmin";
import ManagePatients from "./components/ManagePatiends/ManagePatients";
import FullVacSchedule from "./components/FullVacSchedule/FullVacSchedule";
import Vacandhealthtips from "./components/Vacsandhealthtips/Vacsandhealthtips";
import FullVacDetail from "./components/FullVacDetail/FullVacDetail";
import Babies from "./components/Babies/Babies";
import Subscription from "./components/Subscription/Subscription";
import ParentDashboard from "./components/ParentDashboard/ParentDashboard";
import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
import AddImWorker from "./components/AddImWorker/AddImWorker";
import AddFacilityWorker from "./components/AddFacilityWorker/AddFacilityWorker";
import AddFacility from "./components/AddFacility/AddFacility";
import MissedVacsList from "./components/MissedVacsList/MissedVacsList";
import SmsNotifications from "./components/SmsNotifications/SmsNotifications";
import SearchedBabies from "./components/SearchedBabies/SearchedBabies";
import FacilityDetails from "./components/FacilityDetails/FacilityDetails";
import PendingVaccines from "./components/PendingVaccines/PendingVaccines";
import FullChildrenList from "./components/FullChildrenList/FullChildrenList";
import UpdatePopulation from "./components/UpdatePopulation/UpdatePopulation";

export const db = new Dexie("childInfo");
db.version(2).stores({
  childData:
    "++id, firstName, lastName, birthCertNo, dateOfBirth, gender, firstNames, lastNames, emails, phoneNumbers,caregivers,genders,userTypeIds,address",
});

const { childData } = db;

function App() {
  const allItems = useLiveQuery(() => childData.toArray(), []);

  const location = useLocation();
  const navigate = useNavigate();

  const isLoggedIn = localStorage.getItem("token");

  const onConfirmRefresh = function (event) {
    event.preventDefault();
    return (event.returnValue = "Are you sure you want to leave the page?");
  };

  window.addEventListener("beforeunload", onConfirmRefresh, { capture: true });

  const closeForm = () => {
    navigate(-1);
  };

  return (
    <div className="App">
      <ToastContainer />
      <Routes location={location} key={location.key}>
        <Route path="/" element={<Login />} />
        <Route path="/register-user" element={<Register />} />
        <Route path="/register-super-admin" element={<RegisterSuperAdmin />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/register-parent" element={<RegisterParent />} />
        <Route path="/panel" element={<Panel />}>
          <Route path="/panel/child-details/:id" element={<ChildDetails />} />
          <Route
            path="/panel/facility-details/:id"
            element={<FacilityDetails />}
          />
          <Route path="/panel/data-management" element={<DataManagement />} />
          <Route path="/panel/employee-management" element={<Employees />} />
          <Route
            path="/panel/patient-management"
            element={<ManagePatients />}
          />
          <Route path="/panel/subscription" element={<Subscription />} />
          <Route
            path="/panel/children-management"
            element={<ChildManagement />}
          />
          <Route path="/panel/full-schedule" element={<FullVacSchedule />} />
          <Route path="/panel/vacs-tips" element={<Vacandhealthtips />} />
          <Route
            path="/panel/super-admin-dashboard"
            element={<SuperAdminDashboard />}
          />
          <Route path="/panel/admin-dashboard" element={<AdminDashboard />} />
          <Route
            path="/panel/facility-worker-dashboard"
            element={<HealtcareWorker />}
          />
          <Route
            path="/panel/immunization-worker-dashboard"
            element={<ImmunizationWorker />}
          />
          <Route path="/panel/parent-dashboard" element={<ParentDashboard />} />
          <Route
            path="/panel/add-facility-admin"
            element={<AddAdmin closeAdminForm={closeForm} />}
          />
          <Route
            path="/panel/add-facility"
            element={<AddFacility closeForm={closeForm} />}
          />
          <Route
            path="/panel/searched-babies"
            element={<SearchedBabies closeForm={closeForm} />}
          />
          <Route path="/panel/missed-vacs-list" element={<MissedVacsList />} />
          <Route path="/panel/pending-vacs" element={<PendingVaccines />} />
          <Route path="/panel/sent-sms-list" element={<SmsNotifications />} />
          <Route
            path="/panel/full-children-list"
            element={<FullChildrenList />}
          />
          <Route
            path="/panel/add-facility-worker"
            element={<AddFacilityWorker closeAdminForm={closeForm} />}
          />
          <Route
            path="/panel/add-immunization-worker"
            element={<AddImWorker closeAdminForm={closeForm} />}
          />
          <Route path="/panel/profile" element={<Profile />} />
          <Route path="/panel/data-sync" element={<DataSync />} />
          <Route
            path="/panel/population-update"
            element={<UpdatePopulation />}
          />
          <Route
            path="/panel/add-child"
            element={<AddChild closeForm={closeForm} />}
          />
          <Route
            path="/panel/full-vac-details/:id"
            element={<FullVacDetail />}
          />
          <Route path="/panel/babies" element={<Babies />} />

          <Route path="/panel/parent-dashboard" element={<ParentDashboard />} />
          <Route path="/panel/personnel-data/:id" element={<PersonnelData />} />
          <Route path="/panel/register-admin" element={<Register />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
