import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import axios from "axios";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import reportWebVitals from "./reportWebVitals";
import swDev from "./swDev";
import store from "./redux/store";
import { Provider } from "react-redux";
import "./index.css";

// const BASE_URL = "http://localhost:8000";
const BASE_URL = "https://api.imt-tracker.hayokmedicare.ng/";

// axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

export const api = axios.create({
  baseURL: BASE_URL,
  // baseURL: "http://localhost:5000",
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker.getRegistrations().then(function (registrations) {
//     //returns installed service workers
//     if (registrations.length) {
//       for (let registration of registrations) {
//         registration.unregister();
//       }
//     }
//   });
// }

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// swDev();
