import { useState, useMemo } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { ReactComponent as XIcon } from "../../assets/icons/close-icon.svg";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import { api } from "../..";

const WeightUpload = ({ showWeightUpdate, setShowWeightUpdate, id }) => {
  const [babyWeight, setBabyWeight] = useState();
  const [babyWeightTimeFrame, setBabyWeightTimeFrame] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const updateBabyWeight = () => {
    api
      .post(`api/child_weight`, {
        child_id: id,
        weight: babyWeight,
        time_frame: babyWeightTimeFrame,
      })
      .then((res) => {
        toast.success("Weight updated successfully");
        setBabyWeight("");
        setBabyWeightTimeFrame("");
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured. Please try again");
      });
  };

  return (
    <div className="addChild__container">
      {loading ? (
        <div
          className="loader__container"
          style={{ height: "100vh", position: "fixed" }}
        >
          <ClimbingBoxLoader color="#ffffff" loading={loading} size={15} />
        </div>
      ) : null}
      <div
        className="addUserForm__content--container"
        style={{ height: "100%", width: "100vw" }}
      >
        <div className="addState__form">
          <span className="form__heading-and-close--CTA">
            <p className="addUserForm__formheading">Baby Weight Update</p>
            <XIcon
              style={{ fill: "#F95959", cursor: "pointer" }}
              onClick={() => setShowWeightUpdate(!showWeightUpdate)}
            />
          </span>
          <label htmlFor="state" className="addUserForm__password--label">
            Baby Weight
          </label>
          <div className="addUserForm__password-and-icons">
            <input
              type="text"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              id="state"
              autoComplete="off"
              value={babyWeight}
              onChange={(e) => setBabyWeight(e.target.value)}
              style={{ width: "100%", margin: "1.5rem 0rem" }}
              required
              placeholder="Please Enter Baby's Weight (Number Only)"
              aria-describedby="uidnote"
            />

            <label htmlFor="state" className="addUserForm__password--label">
              Weight Time-Frame
            </label>
            {/* <input
              type="text"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              id="fileTitle"
              autoComplete="off"
              placeholder="Please Enter Weight Period (e.g 1Week or 2Months)"
              onChange={(e) => setBabyWeightTimeFrame(e.target.value)}
              value={babyWeightTimeFrame}
              style={{ width: "100%", marginTop: "1.5rem" }}
              required
            /> */}
            <select
              // name="countries"
              id="gender"
              className={
                errorMessage ? "email--input" : "addChild__email--input"
              }
              value={babyWeightTimeFrame}
              style={{ width: "100%", marginTop: "1.5rem" }}
              required
              onChange={(e) => setBabyWeightTimeFrame(e.target.value)}
            >
              <option value=""></option>
              <option value="birth">Birth</option>
              <option value="2weeks">2 Weeks</option>
              <option value="6weeks">6 Weeks</option>
              <option value="1month">1 Month</option>
              <option value="2months">2 Months</option>
              <option value="5months">5 Months</option>
              <option value="1year">1 Year</option>
              <option value="1year+">1 Year+</option>
              <option value="2years">2 Years</option>
              <option value="3years">3 Years</option>
              <option value="5years">5 Years</option>
            </select>
            {errorMessage && (
              <p
                style={{
                  color: "#F95959",
                  marginTop: "1rem",
                }}
              ></p>
            )}
          </div>
          <button
            className="addUserForm__submit--CTA"
            style={{ width: "100%" }}
            onClick={() => updateBabyWeight()}
            disabled={!babyWeight || !babyWeightTimeFrame ? true : false}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default WeightUpload;
