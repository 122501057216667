import Highcharts, { color } from "highcharts";
import { useEffect, useState } from "react";
import { api } from "../..";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter-icon.svg";
import drilldown from "highcharts/modules/drilldown";
import "./facilityChildrenGraph.css";

const FacilityChildrenGraph = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [drillDownData, setDrilldownData] = useState();

  drilldown(Highcharts);

  const getFilteredData = (e) => {
    e.preventDefault();
    api
      .get(
        `/api/graph_regchildren?start_date=${convertDate(
          startDate
        )}&end_date=${convertDate(endDate)}`
      )
      .then((res) => {
        setDrilldownData(res.data);
      });
  };

  function convertDate(dateString) {
    const [year, month, day] = dateString.split("-");

    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    const getInitialData = () => {
      api
        .get(`/api/graph_regchildren?start_date=01-01-2000&end_date=06-13-2024`)
        .then((res) => {
          setDrilldownData(res.data);
        });
    };

    getInitialData();
  }, []);

  useEffect(() => {
    if (drillDownData) {
      const seriesData = drillDownData?.series[0]?.data.map((item) => ({
        name: item.name,
        y: item.y,
        drilldown: item.drilldown,
        color: "rgb(102, 187, 106)",
      }));

      const drilldownSeries = drillDownData?.drilldown?.series.map((item) => ({
        name: item.name,
        id: item.id,
        data: item.data,
        colors: ["rgb(54, 162, 235)", "rgb(255, 99, 132)"],
      }));

      const options = {
        chart: {
          type: "pie",
        },
        title: {
          //   text: "Timeframe and Vaccination Status Data Per Facility",
          text: drillDownData?.title?.text,
        },
        xAxis: {
          type: "category",
        },

        legend: {
          enabled: false,
        },

        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: "{point.name}: {point.y:1f}",
            },
          },
        },

        series: [
          {
            name: "Facilities",
            colorByPoint: true,
            data: seriesData,
          },
        ],
        drilldown: {
          series: drilldownSeries,
        },
      };

      // Create the chart
      Highcharts.chart("chart-container-3", options);
    }
  }, [drillDownData]);

  return (
    <div className="facilities__filter__graph__container facilities__filter__graph__container-2">
      <div className="facilities__filter__graph__filters">
        <form
          className="filter__param-span filter__param-span-2"
          //   style={{ zIndex: "30" }}
          onSubmit={getFilteredData}
        >
          <div className="input__span-onn">
            <label htmlFor="date" className="filter__param-label">
              Start Date
            </label>
            <input
              type="date"
              id="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="filter__param-input filter__param-input-2"
            />
          </div>
          <div className="input__span-onn">
            <label htmlFor="date" className="filter__param-label">
              End Date
            </label>
            <input
              type="date"
              id="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="filter__param-input filter__param-input-2"
            />
          </div>
          {/* <div className="input__span-onn">
              <label htmlFor="date" className="filter__param-label">
                Status
              </label>
              <select
                className="filter__param-input filter__param-input-2"
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="pending">Pending</option>
                <option value="missed">Missed</option>
                <option value="taken">Taken</option>
              </select>
            </div> */}
          <button
            className="filter__CTA filter__CTA-2"
            type="submit"
            // onClick={() => getFilteredData()}
            disabled={!startDate || !endDate ? true : false}
          >
            Filter
            <FilterIcon style={{ marginLeft: "1rem" }} />
          </button>
        </form>
        {/* <span className="filter__CTA-span">
          </span> */}
      </div>
      {drillDownData && (
        <div
          id="chart-container-3"
          style={{ height: "400px", width: "100%", marginTop: "2rem" }}
        ></div>
      )}
    </div>
  );
};

export default FacilityChildrenGraph;
