import { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ReactComponent as BellIcon } from "../../assets/icons/notification-bell-icon.svg";
import Table from "../Table/Table";
import ChildBioData from "../ChildBioData/ChildBioData";
import axios from "axios";
import Calendar from "../ChildCalendar/Calendar";
import VacDisplay from "../VacDisplay/VacDisplay";
import ComplaintUpdate from "../ComplaintUpdate/ComplaintUpdate";
import UpdateQuestionnaire from "../UpdateQuestionnaire/UpdateQuestionnaire";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import "./childDetails.css";
import FileUpload from "../FileUpload/FileUpload";
import SnapPhoto from "../SnapPhoto/SnapPhoto";
import BabyWeightChart from "../BabyWeightChart/BabyWeightChart";
import defaultImage from "../../assets/images/imtrackerlogo.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { api } from "../..";
import { storeBabyDetails } from "../../redux/babies/babiesActions";
import babyPic from "../../assets/images/im-baby-img.png";
import Notification from "../Notification/Notification";
import WeightUpload from "../WeightUpdate/WeightUpdate";
import BabyHeightChart from "../BabyHeightChart/BabyHeightChart";
import HeightUpdate from "../HeightUpdate/HeightUpdate";
import UpdateBiodata from "../UpdateBiodata/UpdateBiodata";
import UpdateParent from "../UpdateParent/UpdateParent";

const ChildDetails = () => {
  const [childDetails, setChildDetails] = useState();
  const [showComplaintUpdate, setShowComplaintUpdate] = useState(false);
  const [showUpdateQuestionnaire, setShowUpdateQuestionnaire] = useState(false);
  const [showVaccineList, setShowVaccineList] = useState(false);
  const [showUploadFile, setShowUploadFile] = useState(false);
  const [showSnapPhoto, setShowSnapPhoto] = useState(false);
  const [pendingVacs, setPendingVacs] = useState();
  const [takenVacs, setTakenVacs] = useState();
  const [babyAgeInWeeks, setBabyAgeInWeeks] = useState(0);
  const [babyAgeInMonths, setBabyAgeInMonths] = useState(0);
  const [showWeightUpdate, setShowWeightUpdate] = useState(false);
  const [showHeightUpdate, setShowHeightUpdate] = useState(false);
  const [showEditChildBio, setShowEditChildBio] = useState(false);
  const [showUpdateParent, setShowUpdateParent] = useState(false);
  const [loading, setLoading] = useState(false);

  const baby = useSelector((state) => state.babies.baby);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const id = useParams();

  useEffect(() => {
    setLoading(true);
    api
      .get(`/api/child/${id.id}`)
      .then((res) => {
        setChildDetails(res.data?.data);
        dispatch(storeBabyDetails(res.data?.data));
        setLoading(false);

        const pendingVaccinations = res.data?.data.vac_date.filter(
          (vac) => vac.status === "pending"
        );
        const takenVaccinations = res.data?.data.vac_date.filter(
          (vac) => vac.status === "completed"
        );

        setPendingVacs(pendingVaccinations);
        setTakenVacs(takenVaccinations);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [
    showComplaintUpdate,
    showUpdateQuestionnaire,
    showSnapPhoto,
    showUploadFile,
    showWeightUpdate,
    showHeightUpdate,
    showEditChildBio,
    showUpdateParent,
  ]);

  // console.log(childDetails.complaint);

  const tableTitle = "AEFI (Adverse Event Following Immunization)";

  const tableData = {
    headings: ["Date of Onset", "Vaccine", "Complaint", "Severity"],
    rows: childDetails?.complaint?.map((complain) => {
      return {
        dataOne: complain?.date_of_onset,
        dataTwo: complain?.vac_name,
        dataThree: complain?.complaint,
        dataFour: complain?.status,
      };
    }),
  };

  return (
    <>
      {loading ? (
        <div className="loader__container">
          <ClimbingBoxLoader color="#ffffff" loading={loading} size={15} />
        </div>
      ) : (
        <div className="baby__details-container">
          <div className="notification-and-baby__data">
            <div className="baby__image-and-notification">
              <div className="baby__image-and-name">
                <div className="baby__image-container">
                  {childDetails?.picture ? (
                    <img
                      src={`https://api.imt-tracker.hayokmedicare.ng/documents/children_profile_pictures/${childDetails?.picture}`}
                      alt="profile_picture"
                      className="baby__image"
                      onerror="this.src='../../assets/images/imtrackerlogo.jpeg';"
                    />
                  ) : (
                    <img
                      src={defaultImage}
                      alt="profile_picture"
                      className="baby__image"
                      onerror="this.src='../../assets/images/imtrackerlogo.jpeg';"
                    />
                  )}

                  {/* <img src={babyPic} alt="a baby" className="baby__image" /> */}
                </div>
                <p className="baby__name-text">{baby?.first_name}</p>
              </div>
              <Notification />
            </div>
            <div className="baby__data-container-mobile">
              <div className="baby__data-boxes">
                <div className="baby__data-box">
                  <p className="baby__data-text">Vaccines Received</p>
                  <p className="baby__data-no">{takenVacs?.length}</p>
                </div>
                <div className="baby__data-box">
                  <p className="baby__data-text">Pending Vaccines</p>
                  <p className="baby__data-no">{pendingVacs?.length}</p>
                </div>
              </div>
              <div className="baby__data-boxes">
                <div className="baby__data-box baby__total-vaccines--box">
                  <p className="baby__data-text baby__total-vaccines--text">
                    Total Vaccines:
                  </p>
                  <p className="baby__data-no baby__total-vaccines--text">
                    {baby?.vac_date.length}
                  </p>
                </div>
                <div className="baby__data-box">
                  <p className="baby__data-text">Age:</p>
                  {babyAgeInMonths >= 1 ? (
                    <p className="baby__data-no">{babyAgeInMonths} Month(s)</p>
                  ) : (
                    <p className="baby__data-no">{babyAgeInWeeks} Weeks(s)</p>
                  )}
                </div>
              </div>
              <div className="baby__data-boxes">
                <div className="baby__data-box">
                  <p className="baby__data-text">DOB:</p>
                  <p className="baby__data-no">{baby?.date_of_birth}</p>
                </div>
                <div className="baby__data-box">
                  <p className="baby__data-text">Weight:</p>
                  <p className="baby__data-no">
                    {" "}
                    {baby?.child_weight[baby?.child_weight.length - 1]?.weight}
                    kg
                  </p>
                </div>
              </div>
            </div>
            <div className="baby__data-container">
              <div className="baby__data-total-and-others">
                <div className="baby__data-box baby__total-vaccines--box">
                  <p className="baby__data-text baby__total-vaccines--text">
                    <strong>Total Vaccines: </strong> {baby?.vac_date.length}
                  </p>
                </div>
                <div className="baby__data-other-vacs">
                  <div className="baby__data-box">
                    <p className="baby__data-text">
                      <strong>Pending Vaccines:</strong> {pendingVacs?.length}
                    </p>
                  </div>
                  <div className="baby__data-box">
                    <p className="baby__data-text">
                      <strong>Vaccines Received:</strong> {takenVacs?.length}
                    </p>
                  </div>
                </div>
              </div>
              <div className="baby__data-bio-info">
                <div className="baby__data-boxes">
                  <div
                    className="baby__data-box baby__data-bio-box"
                    style={{ marginRight: "3rem" }}
                  >
                    {/* <p className="baby__data-text">
                  <strong>Age:</strong>{" "}
                  {`${babyAgeInWeeks} Week(s), ${babyAgeInMonths} Month(s)`}
                </p> */}
                    <p className="baby__data-text">
                      <strong>Age:</strong> {baby?.child_age}
                    </p>
                    {/* {babyAgeInMonths > 1 ? (
                    ) : (
                      <p className="baby__data-text">
                        <strong>Age:</strong> {babyAgeInWeeks} Week(s)
                      </p>
                    )} */}
                  </div>
                  <div className="baby__data-box baby__data-bio-box">
                    <p className="baby__data-text">
                      <strong>DOB:</strong>
                      {baby?.date_of_birth}
                    </p>
                  </div>
                </div>
                <div className="baby__data-boxes">
                  <div
                    className="baby__data-box baby__data-bio-box"
                    style={{ marginRight: "3rem" }}
                  >
                    <p className="baby__data-text">
                      <strong>Weight: </strong>
                      {
                        baby?.child_weight[baby?.child_weight.length - 1]
                          ?.weight
                      }
                      Kg
                    </p>
                  </div>

                  <div className="baby__data-box baby__data-bio-box">
                    <p className="baby__data-text">
                      <strong>Genotype: </strong>
                      {baby?.genotype}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ChildBioData
            setShowVaccineList={setShowVaccineList}
            showVaccineList={showVaccineList}
            childDetails={baby}
            questionnaire={baby?.questionaire}
            setShowUpdateQuestionnaire={setShowUpdateQuestionnaire}
            setShowUpdateParent={setShowUpdateParent}
            setShowUploadFile={setShowUploadFile}
            setShowSnapPhoto={setShowSnapPhoto}
            setShowWeightUpdate={setShowWeightUpdate}
            setShowHeightUpdate={setShowHeightUpdate}
            showEditChildBio={showEditChildBio}
            setShowEditChildBio={setShowEditChildBio}
          />
          <div className="baby__data-vacs--history">
            <p className="baby__data-vacs--history--heading">
              Upcoming Vaccination
            </p>
            <div className="baby__data-vacs--boxes">
              {pendingVacs &&
                pendingVacs
                  .filter((vac, index) => index < 3)
                  .map((vac) => (
                    <div className="baby__data-vacs--box">
                      <p className="baby__data-vacs--text baby__data-vacs--text-one">
                        {vac.vac_name}
                      </p>
                      <p className="baby__data-vacs--text baby__data-vacs--text-two">
                        Administered {vac.date.substr(0, 10)}
                      </p>
                      <p className="baby__data-vacs--text baby__data-vacs--text-three">
                        It give your child immunity against tuberculosis
                        infection
                      </p>
                    </div>
                  ))}
              {/* <div className="baby__data-vacs--box">
            <p className="baby__data-vacs--text baby__data-vacs--text-one">
              BCG
            </p>
            <p className="baby__data-vacs--text baby__data-vacs--text-two">
              Administered 23rd June 2023
            </p>
            <p className="baby__data-vacs--text baby__data-vacs--text-three">
              It give your child immunity against tuberculosis infection
            </p>
          </div>
          <div className="baby__data-vacs--box">
            <p className="baby__data-vacs--text baby__data-vacs--text-one">
              BCG
            </p>
            <p className="baby__data-vacs--text baby__data-vacs--text-two">
              Administered 23rd June 2023
            </p>
            <p className="baby__data-vacs--text baby__data-vacs--text-three">
              It give your child immunity against tuberculosis infection
            </p>
          </div> */}
            </div>
            <div className="baby__data-vacs--CTA-box">
              <button
                className="baby__data-vacs--CTA"
                onClick={() => navigate("/panel/full-schedule")}
              >
                View Vaccination Schedule
              </button>
            </div>
          </div>

          <div className="baby__data-vacs--history">
            <p className="baby__data-vacs--history--heading">
              Vaccination History
            </p>
            <div className="baby__data-vacs--boxes">
              {baby?.vac_date
                .filter((vac, index) => index < 3)
                .map((vac) => (
                  <div className="baby__data-vacs--box">
                    <p className="baby__data-vacs--text baby__data-vacs--text-one">
                      {vac.vac_name}
                    </p>
                    <p className="baby__data-vacs--text baby__data-vacs--text-two">
                      Administered {vac.date.substr(0, 10)}
                    </p>
                    <p className="baby__data-vacs--text baby__data-vacs--text-three">
                      It give your child immunity against tuberculosis infection
                    </p>
                  </div>
                ))}
              {/* <div className="baby__data-vacs--box">
            <p className="baby__data-vacs--text baby__data-vacs--text-one">
              BCG
            </p>
            <p className="baby__data-vacs--text baby__data-vacs--text-two">
              Administered 23rd June 2023
            </p>
            <p className="baby__data-vacs--text baby__data-vacs--text-three">
              It give your child immunity against tuberculosis infection
            </p>
          </div>
          <div className="baby__data-vacs--box">
            <p className="baby__data-vacs--text baby__data-vacs--text-one">
              BCG
            </p>
            <p className="baby__data-vacs--text baby__data-vacs--text-two">
              Administered 23rd June 2023
            </p>
            <p className="baby__data-vacs--text baby__data-vacs--text-three">
              It give your child immunity against tuberculosis infection
            </p>
          </div> */}
            </div>
            <div className="baby__data-vacs--CTA-box">
              <button
                className="baby__data-vacs--CTA"
                onClick={() => navigate("/panel/full-schedule")}
              >
                View Vaccination Schedule
              </button>
            </div>
          </div>

          <div
            className="baby__weight-chart--container"
            style={{ marginBottom: "8rem" }}
          >
            <BabyWeightChart baby={baby} />
          </div>
          <div className="baby__weight-chart--container">
            <BabyHeightChart baby={baby} />
          </div>

          {/* {loading ? (
        <div className="loader__container">
          <ClimbingBoxLoader color="#ffffff" loading={loading} size={15} />
        </div>
      ) : null} */}
          {showVaccineList && childDetails && (
            <VacDisplay
              showVaccineList={showVaccineList}
              setShowVaccineList={setShowVaccineList}
              events={childDetails?.vac_date}
              childDetails={childDetails}
              setChildDetails={setChildDetails}
              child_id={childDetails.id}
              vaccinations={childDetails?.vac_date}
            />
          )}
          {showEditChildBio ? (
            <UpdateBiodata
              showEditChildBio={showEditChildBio}
              setShowEditChildBio={setShowEditChildBio}
            />
          ) : null}

          {showComplaintUpdate ? (
            <ComplaintUpdate
              setShowComplaintUpdate={setShowComplaintUpdate}
              child_id={childDetails.id}
            />
          ) : null}

          {showUpdateQuestionnaire ? (
            <UpdateQuestionnaire
              setShowUpdateQuestionnaire={setShowUpdateQuestionnaire}
              child_id={childDetails?.id}
            />
          ) : null}

          {showUploadFile ? (
            <FileUpload
              showUploadFile={showUploadFile}
              setShowUploadFile={setShowUploadFile}
              childDetails={childDetails}
            />
          ) : null}
          {showSnapPhoto ? (
            <SnapPhoto
              showSnapPhoto={showSnapPhoto}
              setShowSnapPhoto={setShowSnapPhoto}
              childDetails={childDetails}
            />
          ) : null}

          {showWeightUpdate ? (
            <WeightUpload
              showWeightUpdate={showWeightUpdate}
              setShowWeightUpdate={setShowWeightUpdate}
              id={baby?.id}
            />
          ) : null}
          {showHeightUpdate ? (
            <HeightUpdate
              showHeightUpdate={showHeightUpdate}
              setShowHeightUpdate={setShowHeightUpdate}
              id={baby?.id}
            />
          ) : null}

          {showUpdateParent ? (
            <UpdateParent
              showUpdateParent={showUpdateParent}
              setShowUpdateParent={setShowUpdateParent}
            />
          ) : null}

          <div className="childDetails__container">
            <div
              className="child__profile--header"
              style={{ backgroundImage: `url(../../assets/images/baby.jpg)` }}
            />

            <Table
              title={tableTitle}
              tableData={tableData}
              buttonTitle="Update"
              buttonUrl="/panel/register-admin"
              buttonFunctionValue={showComplaintUpdate}
              buttonFunction={setShowComplaintUpdate}
            />

            {childDetails && <Calendar vaccinations={childDetails?.vac_date} />}
          </div>
        </div>
      )}
    </>
  );
};

export default ChildDetails;
