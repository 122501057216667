import "./privacy.css";

const Privacy = () => {
  return (
    <div className="privacy__container">
      <div className="privacy__content">
        <h1 className="privacy__policy">Privacy Policy</h1>
        <p>
          Equily NG operates the one-farm.ng website, which provides the SERVICE
        </p>
        <br />
        <p>
          This page is used to inform website visitors regarding our policies
          with the collection, use, and disclosure of Personal Information if
          anyone decided to use our Service.
        </p>
        <br />
        <p>
          If you choose to use our Service, then you agree to the collection and
          use of information in relation with this policy. The Personal
          Information that we collect are used for providing and improving the
          Service. We will not use or share your information with anyone except
          as described in this Privacy Policy.
        </p>
        <br />
        <br />
        <p>
          The terms used in this Privacy Policy have the same meanings as in our
          Terms and Conditions, which is accessible at www.alsaluxury.com ,
          unless otherwise defined in this Privacy Policy.
        </p>
        <br />
        <br />

        <p>
          <strong> Information Collection and Use </strong>
          <br />
          <br /> For a better experience while using our Service, we may require
          you to provide us with certain personally identifiable information,
          including but not limited to your name, phone number, and postal
          address. The information that we collect will be used to contact or
          identify you.
        </p>
        <br />
        <br />

        <p>
          <strong> Log Data </strong>
          <br />
          <br />
          We want to inform you that whenever you visit our Service, we collect
          information that your browser sends to us that is called Log Data.
          This Log Data may include information such as your computer’s Internet
          Protocol (“IP”) address, browser version, pages of our Service that
          you visit, the time and date of your visit, the time spent on those
          pages, and other statistics.
        </p>
        <br />
        <br />
        <p>
          <strong> Service Providers </strong>
          <br />
          We may employ third-party companies and individuals due to the
          following reasons:
          <br />
          <br />
          <ul>
            <li>- To facilitate our Service;</li>
            <li>- To provide the Service on our behalf;</li>
            <li>- To perform Service-related services; or</li>
            <li>- To assist us in analyzing how our Service is used.</li>
          </ul>
        </p>
        <br />
        <br />
        <p>
          <strong> Security </strong>
          <br />
          We value your trust in providing us your Personal Information, thus we
          are striving to use commercially acceptable means of protecting it.
          But remember that no method of transmission over the internet, or
          method of electronic storage is 100% secure and reliable, and we
          cannot guarantee its absolute security.
        </p>
        <br />
        <br />
        <p>
          <strong> Links to Other Sites </strong>
          <br />
          <br /> Our Service may contain links to other sites. If you click on a
          third-party link, you will be directed to that site. Note that these
          external sites are not operated by us. Therefore, we strongly advise
          you to review the Privacy Policy of these websites, read more. We have
          no control over, and assume no responsibility for the content, privacy
          policies, or practices of any third-party sites or services.
        </p>
        <br />
        <br />
        <p>
          <strong>Children’s Privacy </strong>
          <br />
          <br />
          Our Services do not address anyone under the age of 18. We do not
          knowingly collect personal identifiable information from children
          under 18. In the case we discover that a child under 18 has provided
          us with personal information, we immediately delete this from our
          servers. If you are a parent or guardian and you are aware that your
          child has provided us with personal information, please contact us so
          that we will be able to do necessary actions.
        </p>
        <br />
        <br />
        <p>
          <strong> Changes to This Privacy Policy </strong>
          <br />
          <br /> We may update our Privacy Policy from time to time. Thus, we
          advise you to review this page periodically for any changes. We will
          notify you of any changes by posting the new Privacy Policy on this
          page. These changes are effective immediately, after they are posted
          on this page.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
