import { ReactComponent as HealthTipIcon } from "../../assets/icons/health-tip-tab-icon.svg";
import { ReactComponent as SleepIcon } from "../../assets/icons/baby-sleep-icon.svg";
import { ReactComponent as MeterIcon } from "../../assets/icons/baby-meter-icon.svg";
import { ReactComponent as HandIcon } from "../../assets/icons/hand-hygiene-icon.svg";
import { ReactComponent as HydrationIcon } from "../../assets/icons/hydration-icon.svg";
import "./healthTips.css";

const HealthTips = () => {
  return (
    <div className="health__tips-main--container">
      <div className="top__health-tips-and-health__info">
        <div className="health__info-box">
          <p className="health__tips-box--heading">Health Info</p>
          <p className="health__tips-body--text">
            Remember that these are general health tips, and each child is
            unique. It's important to consider your child's individual needs,
            medical history, and any specific recommendations from healthcare
            providers.
          </p>
        </div>
        <div className="top__health-tips--container">
          <p className="top__health-tips--heading">
            6 Top Health Tips For Your Child
          </p>
          <p className="top__health-tips-sub--heading">
            Here are some basic health tips to promote your child’s wellbeing
            and health
          </p>
          <div className="top__health-tips--tabs">
            <div className="top__health-tip--tab">
              <HealthTipIcon className="health__tip-icon" />
              <span className="health__tip-texts--span">
                <p className="health__tip-heading--text">Health Diet</p>
                <p className="health__tip-sub--text">
                  Encourage a balanced diet rich in fruits, vegetables, and
                  dairy products.
                </p>
              </span>
            </div>
            <div className="top__health-tip--tab">
              <SleepIcon className="health__tip-icon" />
              <span className="health__tip-texts--span">
                <p className="health__tip-heading--text">Adequate Sleep</p>
                <p className="health__tip-sub--text">
                  Encourage a balanced diet rich in fruits, vegetables, and
                  dairy products.
                </p>
              </span>
            </div>
            <div className="top__health-tip--tab">
              <MeterIcon className="health__tip-icon" />
              <span className="health__tip-texts--span">
                <p className="health__tip-heading--text">Physical Activity</p>
                <p className="health__tip-sub--text">
                  Encourage a balanced diet rich in fruits, vegetables, and
                  dairy products.
                </p>
              </span>
            </div>
            <div className="top__health-tip--tab">
              <HandIcon className="health__tip-icon" />
              <span className="health__tip-texts--span">
                <p className="health__tip-heading--text">Hand Hygiene</p>
                <p className="health__tip-sub--text">
                  Encourage a balanced diet rich in fruits, vegetables, and
                  dairy products.
                </p>
              </span>
            </div>
            <div className="top__health-tip--tab">
              <HealthTipIcon className="health__tip-icon" />
              <span className="health__tip-texts--span">
                <p className="health__tip-heading--text">Hydration</p>
                <p className="health__tip-sub--text">
                  Encourage a balanced diet rich in fruits, vegetables, and
                  dairy products.
                </p>
              </span>
            </div>
            <div className="top__health-tip--tab">
              <HydrationIcon className="health__tip-icon" />
              <span className="health__tip-texts--span">
                <p className="health__tip-heading--text">Hydration</p>
                <p className="health__tip-sub--text">
                  Encourage a balanced diet rich in fruits, vegetables, and
                  dairy products.
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="common__illnesses-container">
        <p className="common__illnesses-heading">
          Common childhood illnesses and their treatment
        </p>
        <div className="common__illnesses-tabs">
          <div className="common__illness-tab">
            <p className="common__illness-tab--heading">Diarrhoea</p>
            <p className="common__illness-tab--sub-text">
              Caused by having contaminated food or water
            </p>
          </div>
          <div className="common__illness-tab">
            <p className="common__illness-tab--heading">Stomach pain</p>
            <p className="common__illness-tab--sub-text">
              wind, constipation and gastroenteritis causes stomach pain in
              children
            </p>
          </div>
          <div className="common__illness-tab">
            <p className="common__illness-tab--heading">Cold</p>
            <p className="common__illness-tab--sub-text">
              a viral infection of your baby's nose and throat
            </p>
          </div>
          <div className="common__illness-tab">
            <p className="common__illness-tab--heading">Eczema</p>
            <p className="common__illness-tab--sub-text">
              red, dry, itchy patches on the skin that result from inflammation
            </p>
          </div>
          <div className="common__illness-tab">
            <p className="common__illness-tab--heading">Fever</p>
            <p className="common__illness-tab--sub-text">
              Some cases usually occurs after vaccination
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthTips;
