import { babiesActionTypes } from "./babiesActionTypes";

const INITIAL_STATE = {
  baby: null,
  babies: null,
  searchedBabies: null,
};

const babiesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case babiesActionTypes.STORE_BABY_DETAILS:
      return {
        ...state,
        baby: action.payload,
      };

    case babiesActionTypes.STORE_BABIES_DETAILS:
      return {
        ...state,
        babies: action.payload,
      };

    case babiesActionTypes.SEARCH_FOR_BABIES:
      return {
        ...state,
        searchedBabies: action.payload,
      };

    default:
      return state;
  }
};

export default babiesReducer;
