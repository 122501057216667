import { useState, useEffect, useMemo } from "react";
// import { useAuthUserContext } from "../../contexts/AuthContext";
// import { useForm } from "react-hook-form";
import axios from "axios";
import { api } from "../..";
import { ReactComponent as EyeLogo } from "../../assets/icons/eye-icon.svg";
import { ReactComponent as EyeOffLogo } from "../../assets/icons/eye-off.svg";
// import Loading from "../Loading/Loading.component";
import { toast } from "react-toastify";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import { useDispatch, useSelector } from "react-redux";
import "./profileDataForm.css";
import { storeUserDetails } from "../../redux/currentUser/currentUser.actions";

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const UPDATEPASSWORDURL = "/api/v1/user/changePassword";
const UPDATEDETAILSURL = "/api/v1/user/updateUser";

const ProfileDataForm = () => {
  const [type, setType] = useState("password");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userId, setUserId] = useState("");
  // const [userDetails, setUserDetails] = useState("");
  // const [getUserAgain, setGetUserAgain] = useState(false);
  // const [fullName, setFullName] = useState("");

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const userDetails = useSelector((state) => state.currentUser.currentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    const result = PWD_REGEX.test(newPassword);
    setValidPwd(result);
    const match = newPassword === matchPwd;
    setValidMatch(match);
  }, [newPassword, matchPwd]);

  // useEffect(() => {
  //   setErrMsg("");
  // }, [pwd, matchPwd]);

  const getUserData = () => {
    api
      .get(`/api/user/${userDetails?.id}`)
      .then((res) => {
        dispatch(storeUserDetails(res?.data?.data));
        // setUserData(res?.data);
        // dispatch(storeUserDetails(res?.data));
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };

  useEffect(() => {
    // setUserDetails(JSON.parse(localStorage.getItem("userDetails")));
    setFirstName(userDetails?.first_name);
    setLastName(userDetails?.last_name);
    setGender(userDetails?.gender);
    setAddress(userDetails?.address);
    setUserEmail(userDetails?.email);
    setUserId(userDetails?.id);

    setPhoneNumber(userDetails?.phone_number);
  }, []);

  const upDatePassword = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await api.post("/api/user_update-password", {
        // oldPassword: oldPassword,
        newPassword: newPassword,
      });
      setSuccess(true);
      localStorage.setItem("token", response.data?.token);
      toast.success("Password Updated Successfully!");
      // setOldPassword("");
      setNewPassword("");
      setMatchPwd("");
      setLoading(false);
    } catch (error) {
      if (!error?.response) {
        setErrMsg("No Server Response");
      } else if (error.response?.status === 400) {
        setErrMsg("Account Already Exists");
      } else {
        setErrMsg("Password Reset Failed");
      }

      toast.error("Error processing request. Please retry.");

      setLoading(false);
    }
  };

  const upDateDetails = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await api.post(`api/user/${userId}`, {
        first_name: firstName,
        last_name: lastName,
        email: userEmail,
        phone_number: phoneNumber,
        gender: gender,
        address: address,
      });
      setSuccess(true);
      getUserData();
      toast.success("Details Updated Successfully!");
      setLoading(false);
    } catch (error) {
      if (!error?.response) {
        setErrMsg("No Server Response");
      } else if (error.response?.status === 400) {
        setErrMsg("Account Already Exists");
      } else {
        setErrMsg("Password Reset Failed");
      }
      toast.error("Error processing request. Please retry.");
      setLoading(false);
    }
    // setGetUserAgain(!getUserAgain);
  };

  const togglePasswordType = () => {
    setShowPassword(!showPassword);
    setType(showPassword ? "text" : "password");
  };

  return (
    <>
      {loading ? (
        <div className="loader__container">
          <ClimbingBoxLoader color="#ffffff" loading={loading} size={15} />
        </div>
      ) : null}
      <p className="profile__page--heading">User Details</p>
      {/* {loading ? <Loading type="cylon" color="rgb(101, 93, 138)" /> : null} */}
      <form
        className="user__details--password-container"
        onSubmit={upDateDetails}
        style={{ height: "50rem" }}
      >
        <span className="user__details--input">
          <label htmlFor="email" className="userDetails--label">
            Email Address
          </label>
          <input
            type="text"
            value={userEmail}
            className="user__data"
            disabled
          />
        </span>
        <span className="user__details--input">
          <label htmlFor="firstName" className="userDetails--label">
            First Name
          </label>
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="user__data"
            required
          />
        </span>
        <span className="user__details--input">
          <label htmlFor="lastName" className="userDetails--label">
            Last Name
          </label>
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="user__data"
            required
          />
        </span>
        <span className="user__details--input">
          <label htmlFor="phoneNumber" className="userDetails--label">
            Phone Number
          </label>
          <input
            type="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="user__data"
          />
        </span>
        <button
          className="save__user--data-CTA"
          disabled={!firstName || !lastName}
        >
          Save Changes
        </button>
      </form>
      <p className="profile__page--heading" style={{ marginTop: "4rem" }}>
        Change Password
      </p>
      <form
        className="user__details--password-container"
        onSubmit={upDatePassword}
      >
        {/* <span className="user__details--input">
          <label htmlFor="oldPassword" className="userDetails--label">
            Enter Old Password
          </label>

          <input
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            className="user__data"
            required
          />
          {type === "password" ? (
            <EyeLogo
              className="profile__eye__icon"
              onClick={togglePasswordType}
            />
          ) : (
            <EyeOffLogo
              className="profile__eye__icon"
              onClick={togglePasswordType}
            />
          )}
          
          {errors.password && (
            <p
              style={{
                color: "#F95959",
                marginTop: "1rem",
              }}
            >
              Required Field
            </p>
          )}
        </span> */}
        <span className="user__details--input">
          <label htmlFor="newPassword" className="userDetails--label">
            Enter New Password
          </label>
          <div className="login__password-and-icons">
            <input
              type={type}
              className="user__data"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            {/* <p id="pwdnote" className={!validPwd ? "instruction" : "offScreen"}>
              8 to 24 characters.
              <br />
              Must include uppercase and lowercase letters, a number and a
              special character.
              <br />
              Allowed special characters:{" "}
              <span aria-label="exclamation mark">!</span>{" "}
              <span aria-label="at symbol">@</span>{" "}
              <span aria-label="hashtag">#</span>{" "}
              <span aria-label="dollar sign">$</span>{" "}
              <span aria-label="percent">%</span>
            </p> */}
            {type === "password" ? (
              <EyeLogo
                className="profile__eye__icon"
                onClick={togglePasswordType}
              />
            ) : (
              <EyeOffLogo
                className="profile__eye__icon"
                onClick={togglePasswordType}
              />
            )}
          </div>
        </span>
        <span className="user__details--input">
          <label htmlFor="retypePassword" className="userDetails--label">
            Re-type New Password
          </label>
          <div className="login__password-and-icons">
            <input
              type={type}
              value={matchPwd}
              onChange={(e) => setMatchPwd(e.target.value)}
              className="user__data"
            />
            {/* <p
              id="confirmnote"
              className={!validMatch ? "instruction" : "offScreen"}
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              Must match the first password input field.
            </p> */}
            {/* {type === "password" ? (
              <EyeLogo
                className="profile__eye__icon"
                onClick={togglePasswordType}
              />
            ) : (
              <EyeOffLogo
                className="profile__eye__icon"
                onClick={togglePasswordType}
              />
            )} */}
          </div>
        </span>
        {!validMatch ? (
          <p style={{ color: "red", fontSize: "1.2rem" }}>
            Must match the new password field
          </p>
        ) : null}

        <button
          className="save__user--data-CTA"
          disabled={!validMatch ? true : false}
        >
          Change Password
        </button>
      </form>
    </>
  );
};

export default ProfileDataForm;
