import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import AddState from "../AddState/AddState";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import AddLocalGovt from "../AddLocalGovt/AddLocalGovt";
import AddFacility from "../AddFacility/AddFacility";
import AddAdmin from "../AddAdmin/AddAdmin";
import "./dataManagement.css";
import AddUserType from "../AddUserType/AddUserType";
import AddModule from "../AddModule/AddModule";
import AddAgency from "../AddAgency/AddAgency";
import LinkModule from "../AddAgency/LinkModule";
import AddCadre from "../AddCadre/AddCadre";
import AddPricePlan from "../AddPricePlan/AddPricePlan";
import AddExchangeRate from "../AddExchangeRate/AddExchangeRate";
import UpdateRate from "../UpdateRate/UpdateRate";
import PaystackHook from "../PaystackHook/PaystackHook";
import { api } from "../..";
import AddWard from "../AddWard/AddWard";

const DataManagement = () => {
  const [showAddState, setShowAddState] = useState(false);
  const [showAddLga, setShowAddLga] = useState(false);
  const [showAddFacility, setShowAddFacility] = useState(false);
  const [showRegAdminForm, setShowRegAdminForm] = useState(false);
  const [showAddUserType, setShowAddUserType] = useState(false);
  const [showAddModule, setShowAddModule] = useState(false);
  const [showAddAgency, setShowAddAgency] = useState(false);
  const [showAddCadre, setShowAddCadre] = useState(false);
  const [showLinkModule, setShowLinkModule] = useState(false);
  const [showAddPricePlan, setShowAddPricePlan] = useState(false);
  const [showAddRate, setShowAddRate] = useState(false);
  const [showAddWard, setShowAddWard] = useState(false);
  const [showUpdateExchageRate, setShowUpdateExchageRate] = useState(false);
  const [states, setStates] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [stateId, setStateId] = useState("");
  const [agencyId, setAgencyId] = useState("");
  const [exchangeRate, setExchangeRate] = useState("");
  const [pricePlans, setPricePlans] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  useEffect(() => {
    getStates();
    getAgencies();
    getExchangeRate();
    getPlans();
  }, [showAddState, showAddAgency, showUpdateExchageRate]);

  const getStates = () => {
    setLoading(true);
    api
      .get("/api/states", config)
      .then((res) => {
        setLoading(false);
        setStates(res?.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const getAgencies = () => {
    setLoading(true);
    api
      .get("/api/agency", config)
      .then((res) => {
        setLoading(false);
        setAgencies(res.data?.data.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const getExchangeRate = () => {
    setLoading(true);
    api
      .get("/api/exchange_rate", config)
      .then((res) => {
        setLoading(false);
        setExchangeRate(res.data?.data.dollar_to_naira);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const getPlans = () => {
    setLoading(true);
    api
      .get("/api/price_plan", config)
      .then((res) => {
        setLoading(false);
        setPricePlans(res?.data.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const closeAdminForm = () => {
    setShowRegAdminForm(!showRegAdminForm);
  };

  const closeAddFacilityForm = () => {
    setShowAddFacility(!showAddFacility);
  };

  return (
    <div className="data__management--container">
      {loading ? (
        <div className="loader__container">
          <ClimbingBoxLoader color="#ffffff" loading={loading} size={15} />
        </div>
      ) : null}
      <p className="data__management--heading">Data Management </p>
      <span className="data__managment-heading-and-CTAs--span">
        <span className="management__CTAs-span">
          <button
            className="add__state-CTA"
            onClick={() => setShowAddModule(!showAddModule)}
          >
            + Add Module
          </button>
          <button
            className="add__state-CTA"
            onClick={() => setShowAddState(!showAddState)}
          >
            + Add State
          </button>
          <button
            className="add__state-CTA"
            onClick={() => setShowAddWard(!showAddWard)}
          >
            + Add Ward
          </button>
          <button
            className="add__state-CTA"
            onClick={() => setShowAddAgency(!showAddAgency)}
          >
            + Add Agency
          </button>
          <button
            className="add__state-CTA"
            onClick={() => setShowAddUserType(!showAddUserType)}
          >
            + Add User Types
          </button>
          <button
            className="add__state-CTA"
            onClick={() => setShowAddFacility(!showAddFacility)}
          >
            + Add Facility
          </button>
          <button
            className="add__state-CTA"
            onClick={() => setShowRegAdminForm(!showRegAdminForm)}
          >
            + Add Agency Admin
          </button>
          <button
            className="add__state-CTA"
            onClick={() => setShowAddCadre(!showAddCadre)}
          >
            + Add Cadre
          </button>
          <button
            className="add__state-CTA"
            onClick={() => setShowAddPricePlan(!showAddPricePlan)}
          >
            + Add Price Plan
          </button>
          {exchangeRate === null ? (
            <button
              className="add__state-CTA"
              onClick={() => setShowAddRate(!showAddRate)}
            >
              + Add Rate
            </button>
          ) : (
            <button
              className="add__state-CTA"
              onClick={() => setShowUpdateExchageRate(!showUpdateExchageRate)}
            >
              + Update Rate
            </button>
          )}
        </span>
      </span>

      {showAddAgency ? (
        <AddAgency
          showAddAgency={showAddAgency}
          setShowAddAgency={setShowAddAgency}
        />
      ) : null}
      {showLinkModule ? (
        <LinkModule
          showLinkModule={showLinkModule}
          setShowLinkModule={setShowLinkModule}
          id={agencyId}
        />
      ) : null}
      {showAddState ? (
        <AddState
          showAddState={showAddState}
          setShowAddState={setShowAddState}
        />
      ) : null}

      {showAddModule ? (
        <AddModule
          showAddModule={showAddModule}
          setShowAddModule={setShowAddModule}
        />
      ) : null}
      {showAddRate ? (
        <AddExchangeRate
          showAddRate={showAddRate}
          setShowAddRate={setShowAddRate}
        />
      ) : null}
      {showUpdateExchageRate ? (
        <UpdateRate
          showUpdateExchageRate={showUpdateExchageRate}
          setShowUpdateExchageRate={setShowUpdateExchageRate}
          oldRate={exchangeRate}
        />
      ) : null}

      {showAddFacility ? (
        <AddFacility closeForm={closeAddFacilityForm} />
      ) : null}
      {showAddCadre ? (
        <AddCadre
          showAddCadre={showAddCadre}
          setShowAddCadre={setShowAddCadre}
        />
      ) : null}

      {showRegAdminForm ? (
        <AddAdmin
          closeAdminForm={closeAdminForm}
          formTitle="Add Agency Admin"
          regUrl="/api/admin/register"
        />
      ) : null}

      {showAddLga ? (
        <AddLocalGovt
          id={stateId}
          showAddLga={showAddLga}
          setShowAddLga={setShowAddLga}
        />
      ) : null}
      {showAddUserType ? (
        <AddUserType
          showAddUserType={showAddUserType}
          setShowAddUserType={setShowAddUserType}
        />
      ) : null}
      {showAddPricePlan ? (
        <AddPricePlan
          showAddPricePlan={showAddPricePlan}
          setShowAddPricePlan={setShowAddPricePlan}
        />
      ) : null}
      {showAddWard ? (
        <AddWard showAddWard={showAddWard} setShowAddWard={setShowAddWard} />
      ) : null}

      {/* <div className="no__state--div">
        <p className="no__states-text">No Data To Manage. Add Some Data.</p>
      </div> */}
      <div className="data__containers">
        <div
          className="listed__states--container"
          style={{ marginRight: "2rem" }}
        >
          <p className="listed__states-heading">States</p>
          {states?.map((state, index) => {
            return (
              <span className="state__name-and-edit--CTA">
                <p className="state__name">
                  {index + 1}. {state.name}
                </p>
                <button
                  className="add__lga--CTA"
                  onClick={() => {
                    setStateId(state.id);
                    setShowAddLga(!showAddLga);
                  }}
                >
                  Add Lga
                </button>
              </span>
            );
          })}
        </div>
        <div className="listed__states--container">
          <p className="listed__states-heading">Agencies</p>
          {agencies?.map((agency, index) => {
            return (
              <span className="state__name-and-edit--CTA">
                <p className="state__name">
                  {index + 1}. {agency.name}
                </p>
                <button
                  className="add__lga--CTA"
                  onClick={() => {
                    setAgencyId(agency.id);
                    setShowLinkModule(!showLinkModule);
                  }}
                >
                  Link Module
                </button>
              </span>
            );
          })}
        </div>
        <div className="listed__states--container">
          <p className="listed__states-heading">Price Plans</p>
          {pricePlans?.map((plan, index) => {
            return (
              <span className="state__name-and-edit--CTA">
                <p className="state__name">
                  {index + 1}. {plan.id}
                </p>
                <PaystackHook
                  amount={plan.monthly_price}
                  pricePlanId={plan.id}
                />
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DataManagement;
