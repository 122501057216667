import { LineChart, Grid } from "@mui/x-charts/LineChart";
import "chart.js/auto";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import Highcharts from "highcharts";
import { useSelector } from "react-redux";
import {
  Chart,
  ArcElement,
  Tooltip,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
// import faker from "faker";
import AddFacilityWorker from "../AddFacilityWorker/AddFacilityWorker";
import AddImWorker from "../AddImWorker/AddImWorker";
// import "./healthCareWorker.css";
// import axios from "axios";
import { useEffect, useState } from "react";
import "./babyWeightChart.css";

Chart.register(ArcElement, Tooltip, BarElement, CategoryScale, LinearScale);

const BabyWeightChart = () => {
  const baby = useSelector((state) => state.babies.baby);

  const [weightData, setWeightData] = useState([]);

  useEffect(() => {
    const babyWeightData = baby?.child_weight.map((weight) => weight.weight);

    setWeightData(babyWeightData);
  }, [baby]);

  // console.log({ weightData });

  // const options = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: "top",
  //     },
  //     title: {
  //       display: true,
  //       // text: "Chart.js Line Chart",
  //     },
  //   },
  // };

  // const labels = [
  //   "January",
  //   "February",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  // ];

  // // const data = {
  // //   labels,
  // //   datasets: [
  // //     {
  // //       label: "Dataset 1",
  // //       // data: labels.map(() =>
  // //       //   faker.datatype.number({ min: -1000, max: 1000 })
  // //       // ),
  // //       borderColor: "rgb(255, 99, 132)",
  // //       backgroundColor: "rgba(255, 99, 132, 0.5)",
  // //     },
  // //     {
  // //       label: "Dataset 2",
  // //       // data: labels.map(() =>
  // //       //   faker.datatype.number({ min: -1000, max: 1000 })
  // //       // ),
  // //       borderColor: "rgb(53, 162, 235)",
  // //       backgroundColor: "rgba(53, 162, 235, 0.5)",
  // //     },
  // //   ],
  // // };

  // const data = {
  //   labels: [
  //     "Birth",
  //     "2w",
  //     "6w",
  //     "1m",
  //     "2m",
  //     "5m",
  //     "1yr",
  //     "1yr+",
  //     "2yr",
  //     "3yr",
  //     "5yr ",
  //   ],
  //   datasets: [
  //     // {
  //     //   label: "First dataset",
  //     //   data: [33, 53, 85, 41, 44, 65],
  //     //   fill: true,
  //     //   backgroundColor: "rgba(75,192,192,0.2)",
  //     //   borderColor: "rgba(75,192,192,1)",
  //     // },

  //     {
  //       backgroundColor: [
  //         "rgb(255, 99, 132)",
  //         "rgb(54, 162, 235)",
  //         "rgb(255, 205, 86)",
  //       ],

  //       label: "CHILD'S WEIGHT IN KG",
  //       data: [...weightData],
  //       // data: [1, 2, 4, 5, 6, 7, 8, 9, 10],
  //       fill: true,
  //       borderColor: "black",
  //     },
  //   ],
  // };

  // return weightData && <Bar options={options} data={data} />;
  useEffect(() => {
    if (weightData) {
      const options = {
        chart: {
          type: "column",
        },
        title: {
          text: "Child's Weight vs Ideal Weight Per Age",
          align: "center",
        },
        // subtitle: {
        //   text:
        //     'Source: <a target="_blank" ' +
        //     'href="https://www.indexmundi.com/agriculture/?commodity=corn">indexmundi</a>',
        //   align: "left",
        // },
        xAxis: {
          categories: [
            "Birth",
            "2w",
            "6w",
            "1m",
            "2m",
            "5m",
            "1yr",
            "1yr+",
            "2yr",
            "3yr",
            "5yr ",
          ],
          crosshair: true,
          accessibility: {
            description: "Countries",
          },
        },
        yAxis: {
          min: 1,
          title: {
            text: "Weight In Kg",
          },
        },
        tooltip: {
          valueSuffix: " (Kg)",
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        series: [
          {
            name: "Ideal Weight",
            data: [2, 2.3, 3.2, 4, 6, 7, 9, 12, 14, 17, 20],
            color: "#36a2eb",
          },
          {
            name: "Baby's Weight",
            data: [...weightData],
            color: "rgb(236, 64, 122)",
          },
        ],
      };

      // Create the chart
      Highcharts.chart("chart-container", options);
    }
  }, [baby, weightData]); // Empty dependency array ensures this effect runs once on mount

  return (
    weightData && <div id="chart-container" style={{ height: "400px" }}></div>
  );
};

export default BabyWeightChart;
